import styled from "styled-components";
import {InputText} from "primereact/inputtext";


export const TkInputText = styled(InputText)`
  color: var(--text);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1876em;
  border-radius: 4px;
  height: 30px;
`
