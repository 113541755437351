/**
 * @generated SignedSource<<1d7c7967c38ccc3c78ea257f66853783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectSkillAssessmentTemplates_AssessmentTemplatesQuery$variables = {
  accountId: string;
};
export type selectSkillAssessmentTemplates_AssessmentTemplatesQuery$data = {
  readonly Assessment: {
    readonly AssessmentTemplates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
};
export type selectSkillAssessmentTemplates_AssessmentTemplatesQuery = {
  response: selectSkillAssessmentTemplates_AssessmentTemplatesQuery$data;
  variables: selectSkillAssessmentTemplates_AssessmentTemplatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssessmentQueries",
    "kind": "LinkedField",
    "name": "Assessment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "accountId",
            "variableName": "accountId"
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          }
        ],
        "concreteType": "AssessmentTemplateConnection",
        "kind": "LinkedField",
        "name": "AssessmentTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssessmentTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssessmentTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectSkillAssessmentTemplates_AssessmentTemplatesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectSkillAssessmentTemplates_AssessmentTemplatesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cd02f5b175f56d2ee0b78fa1cd357a6e",
    "id": null,
    "metadata": {},
    "name": "selectSkillAssessmentTemplates_AssessmentTemplatesQuery",
    "operationKind": "query",
    "text": "query selectSkillAssessmentTemplates_AssessmentTemplatesQuery(\n  $accountId: ID!\n) {\n  Assessment {\n    AssessmentTemplates(accountId: $accountId, first: 20) {\n      edges {\n        node {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f200ea57f4aeed6acf5fbeddc2ed2e5";

export default node;
