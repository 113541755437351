/**
 * @generated SignedSource<<5671b289ffc2fb27e101eeb456f07e4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GapDaysDisplay_GapDaysFragment$data = {
  readonly gapDays: number;
  readonly gapSalary: number;
  readonly " $fragmentType": "GapDaysDisplay_GapDaysFragment";
};
export type GapDaysDisplay_GapDaysFragment$key = {
  readonly " $data"?: GapDaysDisplay_GapDaysFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GapDaysDisplay_GapDaysFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GapDaysDisplay_GapDaysFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gapDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gapSalary",
      "storageKey": null
    }
  ],
  "type": "ScenarioGapDays",
  "abstractKey": null
};

(node as any).hash = "e015ca1e5cfd0c97be82b1dce0120137";

export default node;
