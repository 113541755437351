/**
 * @generated SignedSource<<a115615624e363d538b6fbb9f9e09fbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditStaffingTemplateButton_StaffingTemplateFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditStaffingTemplateModal_StaffingTemplateFragment">;
  readonly " $fragmentType": "EditStaffingTemplateButton_StaffingTemplateFragment";
};
export type EditStaffingTemplateButton_StaffingTemplateFragment$key = {
  readonly " $data"?: EditStaffingTemplateButton_StaffingTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditStaffingTemplateButton_StaffingTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditStaffingTemplateButton_StaffingTemplateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditStaffingTemplateModal_StaffingTemplateFragment"
    }
  ],
  "type": "StaffingTemplate",
  "abstractKey": null
};

(node as any).hash = "0bf87ac5f275943e1d4d7bfebe6a2041";

export default node;
