/**
 * @generated SignedSource<<4ad24a96f24f04f2290877d9f24c608f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentRolesTable_Refetch$variables = {
  after?: string | null;
  filterByName?: string | null;
  first?: number | null;
};
export type assignmentRolesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignmentRolesTable_AssignmentRolesQueryFragment">;
};
export type assignmentRolesTable_Refetch = {
  response: assignmentRolesTable_Refetch$data;
  variables: assignmentRolesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignmentRolesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "assignmentRolesTable_AssignmentRolesQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignmentRolesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentsQueries",
        "kind": "LinkedField",
        "name": "Assignments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssignmentRolesConnection",
            "kind": "LinkedField",
            "name": "AssignmentRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumberOfProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utilizationProjectionCapInMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsFullyAllocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsOverallocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useEndDateOfLastAssignmentOverProjectionCap",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "assignmentRolesTable_AssignmentRoles",
            "kind": "LinkedHandle",
            "name": "AssignmentRoles"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19f80e9a46f3b12f6c4f824eb861c10a",
    "id": null,
    "metadata": {},
    "name": "assignmentRolesTable_Refetch",
    "operationKind": "query",
    "text": "query assignmentRolesTable_Refetch(\n  $after: String\n  $filterByName: String\n  $first: Int = 20\n) {\n  ...assignmentRolesTable_AssignmentRolesQueryFragment_3KLhx3\n}\n\nfragment AssignmentRoleSortOrderButtons_AssignmentRoleFragment on AssignmentRole {\n  id\n  sortOrder\n}\n\nfragment assignmentRolesTable_AssignmentRolesQueryFragment_3KLhx3 on Query {\n  Assignments {\n    AssignmentRoles(first: $first, after: $after, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          sortOrder\n          maxNumberOfProjects\n          utilizationProjectionCapInMonths\n          countAsFullyAllocatedAtPercentage\n          countAsOverallocatedAtPercentage\n          ...editAssignmentRoleButton_AssignmentRoleFragment\n          ...AssignmentRoleSortOrderButtons_AssignmentRoleFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment editAssignmentRoleButton_AssignmentRoleFragment on AssignmentRole {\n  id\n  name\n  sortOrder\n  maxNumberOfProjects\n  utilizationProjectionCapInMonths\n  countAsFullyAllocatedAtPercentage\n  countAsOverallocatedAtPercentage\n  useEndDateOfLastAssignmentOverProjectionCap\n}\n"
  }
};
})();

(node as any).hash = "c6bc28037c2272e72cc7a91f9705e22e";

export default node;
