/**
 * @generated SignedSource<<9bcaaf0a7726fd9a0af27cd04c8ed1fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeUserGroupsAdminInput = {
  accountId: string;
  clientMutationId?: string | null;
  groupRefs: ReadonlyArray<string>;
  userId: string;
};
export type ChangeUserGroupsAdminModal_ChangeMutation$variables = {
  input: ChangeUserGroupsAdminInput;
};
export type ChangeUserGroupsAdminModal_ChangeMutation$data = {
  readonly Admin: {
    readonly Management: {
      readonly changeUserGroupsAdmin: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"ChangeUserGroupsAdminModal_UserInAccountFragment">;
          };
        };
      } | null;
    };
  };
};
export type ChangeUserGroupsAdminModal_ChangeMutation = {
  response: ChangeUserGroupsAdminModal_ChangeMutation$data;
  variables: ChangeUserGroupsAdminModal_ChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeUserGroupsAdminModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeUserGroupsAdminPayload",
                "kind": "LinkedField",
                "name": "changeUserGroupsAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ChangeUserGroupsAdminModal_UserInAccountFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeUserGroupsAdminModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeUserGroupsAdminPayload",
                "kind": "LinkedField",
                "name": "changeUserGroupsAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountGroup",
                            "kind": "LinkedField",
                            "name": "groups",
                            "plural": true,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af9e11c6ef39da2f1810d416a14c4991",
    "id": null,
    "metadata": {},
    "name": "ChangeUserGroupsAdminModal_ChangeMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeUserGroupsAdminModal_ChangeMutation(\n  $input: ChangeUserGroupsAdminInput!\n) {\n  Admin {\n    Management {\n      changeUserGroupsAdmin(input: $input) {\n        edge {\n          node {\n            id\n            ...ChangeUserGroupsAdminModal_UserInAccountFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ChangeUserGroupsAdminModal_UserInAccountFragment on UserInAccount {\n  id\n  user {\n    id\n  }\n  groups {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae36901b8efc93f6ba4cb468011ceeee";

export default node;
