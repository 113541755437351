/**
 * @generated SignedSource<<a2102972056cd91088cbb0a831ef9dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImportScenariosInput = {
  clientMutationId?: string | null;
  fileId: string;
};
export type ImportScenariosButton_ImportMutation$variables = {
  input: ImportScenariosInput;
};
export type ImportScenariosButton_ImportMutation$data = {
  readonly Scenario: {
    readonly importScenarios: {
      readonly result: {
        readonly editedEntities: number;
        readonly issues: ReadonlyArray<{
          readonly issue: string;
          readonly row: number;
        }>;
        readonly newEntities: number;
      };
    } | null;
  };
};
export type ImportScenariosButton_ImportMutation = {
  response: ImportScenariosButton_ImportMutation$data;
  variables: ImportScenariosButton_ImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ScenarioMutations",
    "kind": "LinkedField",
    "name": "Scenario",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ImportScenariosPayload",
        "kind": "LinkedField",
        "name": "importScenarios",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportResult",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editedEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportIssue",
                "kind": "LinkedField",
                "name": "issues",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "row",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportScenariosButton_ImportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportScenariosButton_ImportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eb1b22ec89315ba9b77b1d00315e7706",
    "id": null,
    "metadata": {},
    "name": "ImportScenariosButton_ImportMutation",
    "operationKind": "mutation",
    "text": "mutation ImportScenariosButton_ImportMutation(\n  $input: ImportScenariosInput!\n) {\n  Scenario {\n    importScenarios(input: $input) {\n      result {\n        editedEntities\n        newEntities\n        issues {\n          row\n          issue\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb521feb3c2f659379d992cb606acbeb";

export default node;
