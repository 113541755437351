/**
 * @generated SignedSource<<0484fa02c75c51f60e3d7765aebdf223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAssignmentForm_ProjectFragment$data = {
  readonly endDate: any | null;
  readonly startDate: any | null;
  readonly " $fragmentType": "EditAssignmentForm_ProjectFragment";
};
export type EditAssignmentForm_ProjectFragment$key = {
  readonly " $data"?: EditAssignmentForm_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentForm_ProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAssignmentForm_ProjectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "1339bd36771f85b595de0dac94f614b2";

export default node;
