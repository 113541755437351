/**
 * @generated SignedSource<<0019a58bbe7c3838d93a14d92b45ce12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillMultiSelectV2_SkillFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly skillCategory: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "skillMultiSelectV2_SkillFragment";
};
export type skillMultiSelectV2_SkillFragment$key = {
  readonly " $data"?: skillMultiSelectV2_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillMultiSelectV2_SkillFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "skillMultiSelectV2_SkillFragment"
};

(node as any).hash = "1ac92c2f9a3123c90301744e1abeb2c1";

export default node;
