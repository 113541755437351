/**
 * @generated SignedSource<<579ca6fd2d7ff721d8ace96047630949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RegistrationInput = {
  accountName?: string | null;
  clientMutationId?: string | null;
  email: string;
  name: string;
  password: string;
};
export type RegistrationScreen_LoginMutation$variables = {
  input: RegistrationInput;
};
export type RegistrationScreen_LoginMutation$data = {
  readonly Auth: {
    readonly register: {
      readonly accountId: string;
    } | null;
  };
};
export type RegistrationScreen_LoginMutation = {
  response: RegistrationScreen_LoginMutation$data;
  variables: RegistrationScreen_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "RegistrationPayload",
        "kind": "LinkedField",
        "name": "register",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationScreen_LoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationScreen_LoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09e86a0d39808d9d044052a69f94ab3e",
    "id": null,
    "metadata": {},
    "name": "RegistrationScreen_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationScreen_LoginMutation(\n  $input: RegistrationInput!\n) {\n  Auth {\n    register(input: $input) {\n      accountId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4ef9042ae24ecfabd17bcce7dbe7b73";

export default node;
