/**
 * @generated SignedSource<<8420dcf7cd4e65fa32850863eb023139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStagesTable_Query$variables = {
  filterByName?: string | null;
  first?: number | null;
};
export type ProjectStagesTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStagesTable_ProjectStageListFragment">;
};
export type ProjectStagesTable_Query = {
  response: ProjectStagesTable_Query$data;
  variables: ProjectStagesTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectStagesTable_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProjectStagesTable_ProjectStageListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectStagesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectQueries",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ProjectStagesConnection",
            "kind": "LinkedField",
            "name": "ProjectStages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectStage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reverseProjectOrderInReports",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "ProjectStagesTable_ProjectStages",
            "kind": "LinkedHandle",
            "name": "ProjectStages"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68652b83419312cab6bcab8dd36e000d",
    "id": null,
    "metadata": {},
    "name": "ProjectStagesTable_Query",
    "operationKind": "query",
    "text": "query ProjectStagesTable_Query(\n  $first: Int\n  $filterByName: String\n) {\n  ...ProjectStagesTable_ProjectStageListFragment_2rD0Jv\n}\n\nfragment ProjectStageSortOrderButtons_ProjectStageFragment on ProjectStage {\n  id\n  sortOrder\n}\n\nfragment ProjectStagesTable_ProjectStageFragment on ProjectStage {\n  id\n  name\n  sortOrder\n  reverseProjectOrderInReports\n  color\n  ...ProjectStageSortOrderButtons_ProjectStageFragment\n  ...editProjectStageButton_ProjectStageFragment\n}\n\nfragment ProjectStagesTable_ProjectStageListFragment_2rD0Jv on Query {\n  Project {\n    ProjectStages(first: $first, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          ...ProjectStagesTable_ProjectStageFragment\n          id\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment editProjectStageButton_ProjectStageFragment on ProjectStage {\n  ...editProjectStageModal_ProjectStageFragment\n}\n\nfragment editProjectStageModal_ProjectStageFragment on ProjectStage {\n  id\n  name\n  reverseProjectOrderInReports\n  sortOrder\n  color\n}\n"
  }
};
})();

(node as any).hash = "d3a202e18609c0877d8ebdb7760d338e";

export default node;
