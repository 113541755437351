/**
 * @generated SignedSource<<74330684e1811cc6863cfd74592869ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAssignmentRoleButton_AssignmentRoleFragment$data = {
  readonly countAsFullyAllocatedAtPercentage: number | null;
  readonly countAsOverallocatedAtPercentage: number | null;
  readonly id: string;
  readonly maxNumberOfProjects: number | null;
  readonly name: string;
  readonly sortOrder: number;
  readonly useEndDateOfLastAssignmentOverProjectionCap: boolean | null;
  readonly utilizationProjectionCapInMonths: number | null;
  readonly " $fragmentType": "editAssignmentRoleButton_AssignmentRoleFragment";
};
export type editAssignmentRoleButton_AssignmentRoleFragment$key = {
  readonly " $data"?: editAssignmentRoleButton_AssignmentRoleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAssignmentRoleButton_AssignmentRoleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAssignmentRoleButton_AssignmentRoleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxNumberOfProjects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "utilizationProjectionCapInMonths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countAsFullyAllocatedAtPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countAsOverallocatedAtPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useEndDateOfLastAssignmentOverProjectionCap",
      "storageKey": null
    }
  ],
  "type": "AssignmentRole",
  "abstractKey": null
};

(node as any).hash = "f4fda75ba595ac85f821b9c23c25d11f";

export default node;
