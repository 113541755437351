/**
 * @generated SignedSource<<e8e993ac7defadb8ec9264863209767c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UtilizationStatus = "FullyAllocated" | "NotAllocated" | "Overallocated" | "Underallocated";
import { FragmentRefs } from "relay-runtime";
export type personCard_ScenarioUtilizationFragment$data = {
  readonly personUtilizations: ReadonlyArray<{
    readonly personRef: string;
    readonly status: UtilizationStatus;
    readonly utilizationPercentage: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_ScenarioUtilizationFragment">;
  readonly " $fragmentType": "personCard_ScenarioUtilizationFragment";
};
export type personCard_ScenarioUtilizationFragment$key = {
  readonly " $data"?: personCard_ScenarioUtilizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioUtilizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personCard_ScenarioUtilizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonUtilization",
      "kind": "LinkedField",
      "name": "personUtilizations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "utilizationPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personDetailsButton_ScenarioUtilizationFragment"
    }
  ],
  "type": "ScenarioUtilization",
  "abstractKey": null
};

(node as any).hash = "e009b3f8fb905ce3a0fe60310761c213";

export default node;
