/**
 * @generated SignedSource<<1c76d5342ae8c059637880ec80089db1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillAssessment_GetLastUpdatedDateQuery$data = {
  readonly Assessment: {
    readonly GetContinueFromDate: string | null;
  };
  readonly " $fragmentType": "skillAssessment_GetLastUpdatedDateQuery";
};
export type skillAssessment_GetLastUpdatedDateQuery$key = {
  readonly " $data"?: skillAssessment_GetLastUpdatedDateQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessment_GetLastUpdatedDateQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "password"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "templateId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./skillAssessment_Refetch.graphql')
    }
  },
  "name": "skillAssessment_GetLastUpdatedDateQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentQueries",
      "kind": "LinkedField",
      "name": "Assessment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "accountId",
              "variableName": "accountId"
            },
            {
              "kind": "Variable",
              "name": "password",
              "variableName": "password"
            },
            {
              "kind": "Variable",
              "name": "personId",
              "variableName": "personId"
            },
            {
              "kind": "Variable",
              "name": "templateId",
              "variableName": "templateId"
            }
          ],
          "kind": "ScalarField",
          "name": "GetContinueFromDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0d538eb03bf9e5b909bc48aee4fe04c4";

export default node;
