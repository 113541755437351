import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { ExportButton } from "@components/export-button";
import { type ExportAssignmentsButton_ExportMutation } from "../../__generated__/ExportAssignmentsButton_ExportMutation.graphql";

const MUTATION = graphql`
	mutation ExportAssignmentsButton_ExportMutation($input: ExportAssignmentsInput!) {
		Scenario {
			exportAssignments(input: $input) {
				file {
					url
				}
			}
		}
	}
`;

interface OwnProps {
	scenarioId: string;
}

export const ExportAssignmentsButton = ({ scenarioId }: OwnProps) => {
	const [doExport, isExporting] = useMutation<ExportAssignmentsButton_ExportMutation>(MUTATION);
	return (
		<ExportButton
			isExporting={isExporting}
			doExport={(success) => {
				doExport({
					variables: { input: { scenarioId } },
					onCompleted: (response) => {
						success(response.Scenario.exportAssignments?.file?.url!);
					},
				});
			}}
		/>
	);
};
