/**
 * @generated SignedSource<<8415229b2ee2d30858bb6b59a53a1a6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectUserInAccountField_PersonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectUserInAccountField_PersonFragment";
};
export type selectUserInAccountField_PersonFragment$key = {
  readonly " $data"?: selectUserInAccountField_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectUserInAccountField_PersonFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectUserInAccountField_PersonFragment"
};

(node as any).hash = "1d5df892209d17f10ac7f79af4ba8c98";

export default node;
