/**
 * @generated SignedSource<<e36705795ef48579397017600e862c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportProjectsButton_ExportMutation$variables = {};
export type ExportProjectsButton_ExportMutation$data = {
  readonly Project: {
    readonly exportProjects: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportProjectsButton_ExportMutation = {
  response: ExportProjectsButton_ExportMutation$data;
  variables: ExportProjectsButton_ExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportProjectsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportProjectsPayload",
            "kind": "LinkedField",
            "name": "exportProjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportProjects(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExportProjectsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportProjectsPayload",
            "kind": "LinkedField",
            "name": "exportProjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportProjects(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c8ac35c676c9a8ad476c5a2069f4a6a",
    "id": null,
    "metadata": {},
    "name": "ExportProjectsButton_ExportMutation",
    "operationKind": "mutation",
    "text": "mutation ExportProjectsButton_ExportMutation {\n  Project {\n    exportProjects(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd486e5e8a357dd6ab153e5066dd80f0";

export default node;
