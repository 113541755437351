/**
 * @generated SignedSource<<1f58fef328c53df6995af07854a8d820>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountExtensionKind = "AccountSettings" | "Assessment" | "AuthProvider" | "TwoFA";
export type AuthProviderKind = "AzureAd" | "FSAuthProvider";
import { FragmentRefs } from "relay-runtime";
export type editAzureAdSsoConfigurationForm_QueryFragment$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentAccount: {
        readonly extensions: ReadonlyArray<{
          readonly authProviders?: ReadonlyArray<{
            readonly applicationId?: string;
            readonly authorityUrl?: string;
            readonly domain?: string;
            readonly isActivated?: boolean;
            readonly kind?: AuthProviderKind;
            readonly secret?: string;
            readonly tenantId?: string;
          }>;
          readonly force2FA?: boolean;
          readonly kind: AccountExtensionKind;
        }>;
      } | null;
    };
  };
  readonly " $fragmentType": "editAzureAdSsoConfigurationForm_QueryFragment";
};
export type editAzureAdSsoConfigurationForm_QueryFragment$key = {
  readonly " $data"?: editAzureAdSsoConfigurationForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAzureAdSsoConfigurationForm_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAzureAdSsoConfigurationForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "currentAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extensions",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "force2FA",
                          "storageKey": null
                        }
                      ],
                      "type": "TwoFAAccountExtension",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "authProviders",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isActivated",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "applicationId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "authorityUrl",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "domain",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "tenantId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "secret",
                                  "storageKey": null
                                },
                                (v0/*: any*/)
                              ],
                              "type": "AzureAdAuthProvider",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "AuthProviderAccountExtension",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "234eaae54981e9bace0c04d0f280fe34";

export default node;
