/**
 * @generated SignedSource<<8bce2c6f804bc91c5f1788f54a8cd8c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountsAdminTable_AccountInlineFragment$data = {
  readonly groupAssociations: ReadonlyArray<{
    readonly group: {
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
  readonly id: string;
  readonly name: string;
  readonly registeredAt: string;
  readonly " $fragmentSpreads": FragmentRefs<"AnonymizeAccountButton_AccountFragment" | "ChangeAccountGroupsAdminButton_AccountFragment" | "EditAccountButton_AccountFragment" | "EditUsersInAccountAdminButton_AccountFragment">;
  readonly " $fragmentType": "AccountsAdminTable_AccountInlineFragment";
};
export type AccountsAdminTable_AccountInlineFragment$key = {
  readonly " $data"?: AccountsAdminTable_AccountInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountsAdminTable_AccountInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AccountsAdminTable_AccountInlineFragment"
};

(node as any).hash = "c9e47561beb0111a08ed791b0c7269c0";

export default node;
