import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Panel, type PanelHeaderTemplateOptions } from "primereact/panel";
import { classNames } from "primereact/utils";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { ExecutivesSelect } from "@components/executives-select";
import { DivisionsSelect } from "@components/relay/DivisionsSelect";
import { RegionsSelect } from "@components/relay/RegionsSelect";
import { DefaultCalendarComponent } from "@components/ui/DefaultTextInput";
import { FilterTag } from "@components/ui/filter-tag";
import { TkButton } from "@components/ui/TkButton";
import {
	selectScenarioProjectFilters,
	selectShowPast,
	setProjectViewProjectFilters,
	setShowPast,
	type Sorting,
	sortingOptions,
} from "@redux/ProjectViewSlice";
import type { projectViewFiltersPart_QueryFragment$key } from "@relay/projectViewFiltersPart_QueryFragment.graphql";
import { type projectViewFiltersPart_ScenarioFragment$key } from "@relay/projectViewFiltersPart_ScenarioFragment.graphql";
import {
	QUERY_FRAGMENT,
	SCENARIO_FRAGMENT,
} from "@screens/project-view/parts/project-view-filters-part/project-view-filters-part.graphql";
import { type ProjectViewFiltersPartProps } from "@screens/project-view/parts/project-view-filters-part/project-view-filters-part.types";

export const ProjectViewFiltersPart = ({
	queryRef,
	scenarioFragment,
	...props
}: ProjectViewFiltersPartProps) => {
	const showPast = useSelector(selectShowPast);
	const projectFilters = useSelector(selectScenarioProjectFilters);
	const scenario = useFragment<projectViewFiltersPart_ScenarioFragment$key>(
		SCENARIO_FRAGMENT,
		scenarioFragment,
	);
	const dispatch = useDispatch();
	const ref = useRef<OverlayPanel>(null);
	const {
		Project: {
			ProjectStages: { edges: stageEdges },
		},
		Region: {
			Regions: { edges: regionEdges },
		},
		Division: {
			Divisions: { edges: divisionEdges },
		},
	} = useFragment<projectViewFiltersPart_QueryFragment$key>(QUERY_FRAGMENT, queryRef);
	const stages = stageEdges!.map((e) => e!.node);
	const regions = regionEdges!.map((e) => e!.node);
	const divisions = divisionEdges!.map((e) => e!.node);

	const divisionNames = divisions
		.filter((d) => projectFilters.filterByDivisions?.includes(d.id))
		.map((d) => d.name)
		.join(", ");
	const regionNames = regions
		.filter((d) => projectFilters.filterByRegions?.includes(d.id))
		.map((d) => d.name)
		.join(", ");

	interface Response {
		id: string;
		name: string;
	}

	const getNameById = (arr: Response[], id: string) =>
		arr.find((s) => s.id === id)?.name ?? "Unknown";

	const handleToggleModalVisibility = (e: any) => {
		ref.current?.toggle(e);
	};

	const NameFilterComponent = (
		<div>
			<span className="p-input-icon-left w-12">
				<i className="pi pi-search" />
				<InputText
					name="name-filter"
					placeholder={"Search by Project Name"}
					value={projectFilters.filterByName}
					onChange={(e) =>
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByName: e.target.value,
							}),
						)
					}
				/>
			</span>
		</div>
	);
	const DivisionFilterComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<label htmlFor={"division-filter"}>Division</label>
			<br />
			<DivisionsSelect
				fieldName="division-filter"
				fieldValue={projectFilters.filterByDivisions}
				placeholder="Filter by division"
				updateField={(u) =>
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByDivisions: u,
						}),
					)
				}
			/>
		</div>
	);
	const RegionFilterComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<label htmlFor={"region-filter"}>Region</label>
			<br />
			<RegionsSelect
				placeholder={"Filter by regions"}
				fieldName="region-filter"
				fieldValue={projectFilters.filterByRegions}
				updateField={(u) =>
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByRegions: u,
						}),
					)
				}
			/>
		</div>
	);

	const StaffingComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<label htmlFor={"staffing-filter"}>Staffing</label>
			<br />
			<Dropdown
				name="staffing-filter"
				placeholder="Either staffing"
				options={[
					{ label: "Either staffing", value: null },
					{ label: "Not fully staffed", value: "Not Fully Staffed" },
					{ label: "Fully staffed", value: "Fully staffed" },
				]}
				value={projectFilters.filterByStaffing}
				onChange={(e) => {
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByStaffing: e.value,
						}),
					);
				}}
			/>
		</div>
	);

	const DateFromComponent = (
		<div className="field mr-2">
			<label htmlFor={"project-from-filter"}>Date from</label>
			<DefaultCalendarComponent
				fieldName="project-from-filter"
				fieldValue={projectFilters.filterByDateFrom}
				isValid={true}
				placeholder={"Date from..."}
				updateField={(e) =>
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByDateFrom: e?.length ? e : undefined,
						}),
					)
				}
			/>
		</div>
	);
	const FreeUntilComponent = (
		<div className="field mr-2">
			<label htmlFor={"project-until-filter"}>Date until</label>
			<DefaultCalendarComponent
				fieldName="free-until-filter"
				fieldValue={projectFilters.filterByDateTo}
				isValid={true}
				placeholder={"... date until"}
				updateField={(e) =>
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByDateTo: e?.length ? e : undefined,
						}),
					)
				}
			/>
		</div>
	);

	const SortingComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<label htmlFor={"staffing-filter"}>Sorting</label>
			<br />
			<Dropdown
				name="sorting-filter"
				options={
					[
						{ label: "By name - ascending", value: "ByNameAsc" },
						{ label: "By name - descending", value: "ByNameDesc" },
						{ label: "By start date - ascending", value: "ByStartDateAsc" },
						{
							label: "By start date - descending",
							value: "ByStartDateDesc",
						},
						{ label: "By end date - ascending", value: "ByEndDateAsc" },
						{ label: "By end date - descending", value: "ByEndDateDesc" },
					] as Array<{ label: string; value: Sorting | null }>
				}
				value={projectFilters.sorting}
				onChange={(e) => {
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							sorting: e.value,
						}),
					);
				}}
			/>
		</div>
	);

	const ShowPastComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<div>
				<label htmlFor={"show-past-filter"}>Show past</label>
			</div>
			<InputSwitch
				className="mt-2 ml-2"
				name="show-past-filter"
				checked={showPast}
				placeholder={"... salary to"}
				onChange={(e) => dispatch(setShowPast(e.value))}
			/>
		</div>
	);

	const ExecutiveComponent = (
		<div className="field mr-2" style={{ minWidth: 250 }}>
			<div>
				<label htmlFor={"executive"}>Executives</label>
			</div>
			<ExecutivesSelect
				placeholder="Filter by executives"
				fieldValue={projectFilters.filterByExecutives}
				scenarioId={scenario.id}
				updateField={(u) => {
					dispatch(
						setProjectViewProjectFilters({
							...projectFilters,
							filterByExecutives: u?.length ? u : undefined,
						}),
					);
				}}
			/>
		</div>
	);

	const ResetFiltersComponent = (
		<div className="">
			<TkButton
				disabled={
					Object.entries(projectFilters).length === 1 &&
					projectFilters.sorting === "ByNameAsc"
				}
				label="Reset Filters"
				onClick={() => {
					dispatch(
						setProjectViewProjectFilters({
							filterByName: "",
							sorting: "ByNameAsc",
						}),
					);
				}}
			/>
		</div>
	);
	const headerTemplate = (options: PanelHeaderTemplateOptions) => {
		const className = `${options.className} justify-content-between`;
		return (
			<div className={className}>
				<span>Filters</span>
				{ResetFiltersComponent}
			</div>
		);
	};

	return (
		<div
			className={classNames({
				[props.className ?? ""]: true,
				flex: true,
			})}
			style={{
				gap: "0.5rem",
				...props.style,
			}}
		>
			{NameFilterComponent}
			<TkButton
				onClick={handleToggleModalVisibility}
				label={"Show project filters"}
				icon={"pi pi-filter"}
				style={{ flexGrow: 0, flexShrink: 0, height: "min-content" }}
			/>
			<OverlayPanel ref={ref} showCloseIcon style={{ width: "30%" }}>
				<Panel headerTemplate={headerTemplate} header="Filters">
					<p className="m-0 flex flex-wrap">
						{DivisionFilterComponent}
						{RegionFilterComponent}
						{StaffingComponent}
						{DateFromComponent}
						{FreeUntilComponent}
						{SortingComponent}
						{ShowPastComponent}
						{ExecutiveComponent}
					</p>
				</Panel>
			</OverlayPanel>

			{[
				projectFilters.filterByName,
				projectFilters.filterByDateFrom,
				projectFilters.filterByDateTo,
				projectFilters.filterByDivisions,
				projectFilters.filterByStage,
				projectFilters.filterByRegions,
				projectFilters.filterByStaffing,
				projectFilters.sorting,
				projectFilters.filterByExecutives,
				showPast,
			].some((e) => {
				if (e === "ByNameAsc") return false;
				if (e === false) return false;
				return e !== undefined;
			}) && ResetFiltersComponent}

			{projectFilters.sorting && (
				<FilterTag
					header={"Sorting"}
					id={"filter-tag-sorting"}
					value={
						sortingOptions.find((e) => e.value === projectFilters.sorting)?.label ??
						"Sorting Option Not Found"
					}
					onClick={handleToggleModalVisibility}
				/>
			)}
			{projectFilters.filterByDateFrom && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Date from"}
					value={projectFilters.filterByDateFrom}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByDateFrom: undefined,
							}),
						);
					}}
				/>
			)}
			{projectFilters.filterByDateTo && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Date until"}
					value={projectFilters.filterByDateTo}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByDateTo: undefined,
							}),
						);
					}}
				/>
			)}

			{projectFilters.filterByDivisions && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Divisions"}
					tooltip={divisionNames}
					value={projectFilters.filterByDivisions.length + " selected"}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByDivisions: undefined,
							}),
						);
					}}
				/>
			)}
			{projectFilters.filterByStage && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Stage"}
					value={getNameById(stages, projectFilters.filterByStage)}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByStage: undefined,
							}),
						);
					}}
				/>
			)}
			{projectFilters.filterByRegions && (
				<FilterTag
					header={"Regions"}
					icon={"pi pi-times"}
					tooltip={regionNames}
					value={projectFilters.filterByRegions.length + " selected"}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByRegions: undefined,
							}),
						);
					}}
				/>
			)}
			{projectFilters.filterByStaffing && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Staffing"}
					value={projectFilters.filterByStaffing}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByStaffing: undefined,
							}),
						);
					}}
				/>
			)}

			{showPast && (
				<FilterTag
					icon={"pi pi-times"}
					header={"Past"}
					value={showPast ? "True" : "False"}
					onClick={() => {
						dispatch(setShowPast(false));
					}}
				/>
			)}
			{projectFilters.filterByExecutives && (
				<FilterTag
					header={"Executives"}
					icon={"pi pi-times"}
					value={projectFilters.filterByExecutives?.length + " selected"}
					onClick={() => {
						dispatch(
							setProjectViewProjectFilters({
								...projectFilters,
								filterByExecutives: undefined,
							}),
						);
					}}
				/>
			)}
		</div>
	);
};
