/**
 * @generated SignedSource<<05182f4441e620672ddbf695800f1ca5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAssignmentRoleInput = {
  assignmentRoleId: string;
  clientMutationId?: string | null;
  data: AssignmentRoleDataInput;
};
export type AssignmentRoleDataInput = {
  countAsFullyAllocatedAtPercentage?: number | null;
  countAsOverallocatedAtPercentage?: number | null;
  maxNumberOfProjects?: number | null;
  name: string;
  sortOrder: number;
  useEndDateOfLastAssignmentOverProjectionCap?: boolean | null;
  utilizationProjectionCapInMonths?: number | null;
};
export type editAssignmentRoleButton_EditAssignmentRoleMutation$variables = {
  input: EditAssignmentRoleInput;
};
export type editAssignmentRoleButton_EditAssignmentRoleMutation$data = {
  readonly Assignment: {
    readonly editAssignmentRole: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editAssignmentRoleButton_AssignmentRoleFragment">;
        };
      };
    } | null;
  };
};
export type editAssignmentRoleButton_EditAssignmentRoleMutation = {
  response: editAssignmentRoleButton_EditAssignmentRoleMutation$data;
  variables: editAssignmentRoleButton_EditAssignmentRoleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAssignmentRoleButton_EditAssignmentRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAssignmentRolePayload",
            "kind": "LinkedField",
            "name": "editAssignmentRole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editAssignmentRoleButton_AssignmentRoleFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAssignmentRoleButton_EditAssignmentRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAssignmentRolePayload",
            "kind": "LinkedField",
            "name": "editAssignmentRole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumberOfProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utilizationProjectionCapInMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsFullyAllocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsOverallocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useEndDateOfLastAssignmentOverProjectionCap",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4975e8bcdf611d009ea3352aae514bd",
    "id": null,
    "metadata": {},
    "name": "editAssignmentRoleButton_EditAssignmentRoleMutation",
    "operationKind": "mutation",
    "text": "mutation editAssignmentRoleButton_EditAssignmentRoleMutation(\n  $input: EditAssignmentRoleInput!\n) {\n  Assignment {\n    editAssignmentRole(input: $input) {\n      edge {\n        node {\n          id\n          ...editAssignmentRoleButton_AssignmentRoleFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editAssignmentRoleButton_AssignmentRoleFragment on AssignmentRole {\n  id\n  name\n  sortOrder\n  maxNumberOfProjects\n  utilizationProjectionCapInMonths\n  countAsFullyAllocatedAtPercentage\n  countAsOverallocatedAtPercentage\n  useEndDateOfLastAssignmentOverProjectionCap\n}\n"
  }
};
})();

(node as any).hash = "940f33bf4ae61e271c3f4edb570f4812";

export default node;
