/**
 * @generated SignedSource<<4488dec77ce80ff9bab53850f8026092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetForce2FAInput = {
  clientMutationId?: string | null;
  force2FA: boolean;
};
export type editAccountSettingsForm_SetAccountSettingsMutation$variables = {
  input: SetForce2FAInput;
};
export type editAccountSettingsForm_SetAccountSettingsMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setForce2FA: {
        readonly account: {
          readonly extensions: ReadonlyArray<{
            readonly force2FA?: boolean;
          }>;
        };
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type editAccountSettingsForm_SetAccountSettingsMutation = {
  response: editAccountSettingsForm_SetAccountSettingsMutation$data;
  variables: editAccountSettingsForm_SetAccountSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "force2FA",
      "storageKey": null
    }
  ],
  "type": "TwoFAAccountExtension",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountSettingsForm_SetAccountSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetForce2FAPayload",
                "kind": "LinkedField",
                "name": "setForce2FA",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountSettingsForm_SetAccountSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetForce2FAPayload",
                "kind": "LinkedField",
                "name": "setForce2FA",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7dce07e4fac5c152f33953680b22376",
    "id": null,
    "metadata": {},
    "name": "editAccountSettingsForm_SetAccountSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation editAccountSettingsForm_SetAccountSettingsMutation(\n  $input: SetForce2FAInput!\n) {\n  Admin {\n    Auth {\n      setForce2FA(input: $input) {\n        account {\n          extensions {\n            __typename\n            ... on TwoFAAccountExtension {\n              force2FA\n            }\n          }\n          id\n        }\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0a44207f0588b481cdb65cb334621ea";

export default node;
