/**
 * @generated SignedSource<<bf1a17bf91478d26bf4d299f0d7c20e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptInviteInput = {
  clientMutationId?: string | null;
  token: string;
};
export type AcceptInvitationScreen_AcceptInvitationMutation$variables = {
  input: AcceptInviteInput;
};
export type AcceptInvitationScreen_AcceptInvitationMutation$data = {
  readonly Management: {
    readonly acceptInvitation: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type AcceptInvitationScreen_AcceptInvitationMutation = {
  response: AcceptInvitationScreen_AcceptInvitationMutation$data;
  variables: AcceptInvitationScreen_AcceptInvitationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ManagementMutations",
    "kind": "LinkedField",
    "name": "Management",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "AcceptInvitePayload",
        "kind": "LinkedField",
        "name": "acceptInvitation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptInvitationScreen_AcceptInvitationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptInvitationScreen_AcceptInvitationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ade5044e872d30c7591db86ee27ffdb9",
    "id": null,
    "metadata": {},
    "name": "AcceptInvitationScreen_AcceptInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptInvitationScreen_AcceptInvitationMutation(\n  $input: AcceptInviteInput!\n) {\n  Management {\n    acceptInvitation(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f37527f1e16ed3b5ecbdb299d98c932";

export default node;
