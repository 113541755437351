/**
 * @generated SignedSource<<f2ede92f3112209f8dbdd9c7d6c788f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personCardDraggable_PersonFragment$data = {
  readonly assignmentRole: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"personCard_PersonFragment">;
  readonly " $fragmentType": "personCardDraggable_PersonFragment";
};
export type personCardDraggable_PersonFragment$key = {
  readonly " $data"?: personCardDraggable_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personCardDraggable_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "personCardDraggable_PersonFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "assignmentRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "scenarioId",
          "variableName": "scenarioId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "personCard_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "1dc164424a74ea41db656ed037cfe4d1";

export default node;
