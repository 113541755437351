/**
 * @generated SignedSource<<5b9ad2a8b03a0fd2f018462736576866>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnswerAssessmentInput = {
  accountId: string;
  assessmentId: string;
  clientMutationId?: string | null;
  password?: string | null;
  skillAssessment: SkillAssessmentInput;
};
export type SkillAssessmentInput = {
  skillId: string;
  value: SkillAssessmentValueInput;
};
export type SkillAssessmentValueInput = {
  binary?: BinaryAssessmentValueInput | null;
  numerical?: NumericalAssessmentValueInput | null;
};
export type BinaryAssessmentValueInput = {
  hasSkill: boolean;
  kind: string;
};
export type NumericalAssessmentValueInput = {
  kind: string;
  number: number;
};
export type skillAssessmentExecution_AnswerAssessmentMutation$variables = {
  input: AnswerAssessmentInput;
};
export type skillAssessmentExecution_AnswerAssessmentMutation$data = {
  readonly Assessment: {
    readonly answerAssessment: {
      readonly assessment: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentExecution_AssessmentFragment">;
      };
    } | null;
  };
};
export type skillAssessmentExecution_AnswerAssessmentMutation = {
  response: skillAssessmentExecution_AnswerAssessmentMutation$data;
  variables: skillAssessmentExecution_AnswerAssessmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessmentExecution_AnswerAssessmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AnswerAssessmentPayload",
            "kind": "LinkedField",
            "name": "answerAssessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Assessment",
                "kind": "LinkedField",
                "name": "assessment",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "skillAssessmentExecution_AssessmentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessmentExecution_AnswerAssessmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AnswerAssessmentPayload",
            "kind": "LinkedField",
            "name": "answerAssessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Assessment",
                "kind": "LinkedField",
                "name": "assessment",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTemplate",
                    "kind": "LinkedField",
                    "name": "template",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "assessedSkills",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "dimension",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v4/*: any*/)
                                ],
                                "type": "BinaryDimension",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dimensionCount",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "type": "NumericalDimension",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillAssessment",
                    "kind": "LinkedField",
                    "name": "skillAssessments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skill",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasSkill",
                                "storageKey": null
                              }
                            ],
                            "type": "BinaryAssessmentValue",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              }
                            ],
                            "type": "NumericalAssessmentValue",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27e60cb1eec066f9c479ee7ef7b18cb4",
    "id": null,
    "metadata": {},
    "name": "skillAssessmentExecution_AnswerAssessmentMutation",
    "operationKind": "mutation",
    "text": "mutation skillAssessmentExecution_AnswerAssessmentMutation(\n  $input: AnswerAssessmentInput!\n) {\n  Assessment {\n    answerAssessment(input: $input) {\n      assessment {\n        id\n        ...skillAssessmentExecution_AssessmentFragment\n      }\n    }\n  }\n}\n\nfragment skillAssessmentExecution_AssessmentFragment on Assessment {\n  id\n  status {\n    __typename\n    kind\n    ... on InProgress {\n      kind\n    }\n    ... on PdfGenerated {\n      kind\n    }\n    ... on Finished {\n      kind\n    }\n  }\n  template {\n    assessedSkills {\n      id\n      dimension {\n        __typename\n        ... on BinaryDimension {\n          kind\n        }\n        ... on NumericalDimension {\n          dimensionCount\n          kind\n        }\n      }\n    }\n    id\n  }\n  skillAssessments {\n    skill {\n      id\n    }\n    value {\n      __typename\n      kind\n      ... on BinaryAssessmentValue {\n        kind\n        hasSkill\n      }\n      ... on NumericalAssessmentValue {\n        kind\n        number\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7dc1e74d8ef6f12582b241572902006e";

export default node;
