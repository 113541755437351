/**
 * @generated SignedSource<<c82bc0e412f1a3cf7101f8a9794ed802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditScenarioButton_ScenarioFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditScenarioButton_ScenarioFragment";
};
export type EditScenarioButton_ScenarioFragment$key = {
  readonly " $data"?: EditScenarioButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditScenarioButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditScenarioButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "fdcd48b6b8ed651e292f9b69c18eda73";

export default node;
