/**
 * @generated SignedSource<<88efbbc0ef74129808278fe270c7d8bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenerateRecoveryCodeCredentialsInput = {
  clientMutationId?: string | null;
};
export type recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation$variables = {
  input: GenerateRecoveryCodeCredentialsInput;
};
export type recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation$data = {
  readonly Auth: {
    readonly generateRecoveryCodeCredentials: {
      readonly clientMutationId: string | null;
      readonly recoveryCodeCredentials: {
        readonly " $fragmentSpreads": FragmentRefs<"recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment">;
      };
    } | null;
  };
};
export type recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation = {
  response: recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation$data;
  variables: recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RecoveryCodeCredentialsData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "credentials",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateRecoveryCodeCredentialsPayload",
            "kind": "LinkedField",
            "name": "generateRecoveryCodeCredentials",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment",
                    "selections": (v3/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateRecoveryCodeCredentialsPayload",
            "kind": "LinkedField",
            "name": "generateRecoveryCodeCredentials",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9b7b80d7e3a0b9b38c8561bf3ada044",
    "id": null,
    "metadata": {},
    "name": "recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation",
    "operationKind": "mutation",
    "text": "mutation recoveryCodesSlot_GenerateRecoveryCodeCredentialsMutation(\n  $input: GenerateRecoveryCodeCredentialsInput!\n) {\n  Auth {\n    generateRecoveryCodeCredentials(input: $input) {\n      clientMutationId\n      recoveryCodeCredentials {\n        ...recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment\n        id\n      }\n    }\n  }\n}\n\nfragment recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment on RecoveryCodeCredentials {\n  id\n  data {\n    credentials\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9e06ea3671cf279a1c8826db6576732";

export default node;
