import styled from "styled-components";
import {TkCard} from "./TkCard";
import React, {ReactNode} from "react";
import HarkinsLogo from "../../assets/teambuilder-logo-vertical.png"
import HarkinsBackground from "../../assets/harkins-background.jpg"
import {NavLink} from "react-router-dom";

interface OwnProps {
    children: ReactNode
}

export const AuthScreenBase = ({children}: OwnProps) => {
    return <AuthBackground className="flex flex-grow-1 justify-content-center align-items-center p-sidebar-full">
        <AuthCardWrapper header={<div className="text-center pb-4 pt-4">
            <NavLink to={"/"}>
                <Logo alt={"Harkins"} src={HarkinsLogo}/>
            </NavLink>
        </div>}>
            {children}
        </AuthCardWrapper>
    </AuthBackground>
}

export const AuthCardWrapper = styled(TkCard)`
  max-width: 512px;
  width: 100%;

  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
`

const Logo = styled.img`
  max-width: 160px;
`


export const AuthBackground = styled.div`
  &:before {
    background: url(${HarkinsBackground}) no-repeat center;
    background-size: cover;
    z-index: 0;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 80%
  }

  &:after {
    z-index: 0;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: var(--dark);
    opacity: 50%;
  }
`
