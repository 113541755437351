/**
 * @generated SignedSource<<28882fecb62d17894915349f31b67441>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentRolesSelect_AssignmentRoleFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "AssignmentRolesSelect_AssignmentRoleFragment";
};
export type AssignmentRolesSelect_AssignmentRoleFragment$key = {
  readonly " $data"?: AssignmentRolesSelect_AssignmentRoleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentRolesSelect_AssignmentRoleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AssignmentRolesSelect_AssignmentRoleFragment"
};

(node as any).hash = "30488f5ee830bcf81b69ee2e1de72c08";

export default node;
