/**
 * @generated SignedSource<<16a27ef7c0dd346e5c5c00a4364a91c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDivisionInput = {
  clientMutationId?: string | null;
  name: string;
};
export type EditDivisionModal_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateDivisionInput;
};
export type EditDivisionModal_CreateMutation$data = {
  readonly Division: {
    readonly createDivision: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditDivisionButton_DivisionFragment">;
        };
      };
    } | null;
  };
};
export type EditDivisionModal_CreateMutation = {
  response: EditDivisionModal_CreateMutation$data;
  variables: EditDivisionModal_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditDivisionModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DivisionMutations",
        "kind": "LinkedField",
        "name": "Division",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateDivisionPayload",
            "kind": "LinkedField",
            "name": "createDivision",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DivisionsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Division",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditDivisionButton_DivisionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditDivisionModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DivisionMutations",
        "kind": "LinkedField",
        "name": "Division",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateDivisionPayload",
            "kind": "LinkedField",
            "name": "createDivision",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DivisionsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Division",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d87efff5044dced8e0def30687cc313e",
    "id": null,
    "metadata": {},
    "name": "EditDivisionModal_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditDivisionModal_CreateMutation(\n  $input: CreateDivisionInput!\n) {\n  Division {\n    createDivision(input: $input) {\n      edge {\n        node {\n          id\n          ...EditDivisionButton_DivisionFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditDivisionButton_DivisionFragment on Division {\n  ...EditDivisionModal_DivisionFragment\n}\n\nfragment EditDivisionModal_DivisionFragment on Division {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "437a59520f8972406b3da9f9b4b86c75";

export default node;
