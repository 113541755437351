/**
 * @generated SignedSource<<63d56bbbc560ed2bbe0e39bb8430867a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectDetailsControl_ProjectInScenarioFragment$data = {
  readonly id: string;
  readonly project: {
    readonly address: {
      readonly city: string;
      readonly country: string;
      readonly latitude: number | null;
      readonly lineOne: string;
      readonly longitude: number | null;
      readonly postalCode: string;
      readonly state: string;
    } | null;
    readonly avatar: {
      readonly url: string | null;
    } | null;
    readonly endDate: any | null;
    readonly id: string;
    readonly name: string;
    readonly skillMatrixByCategories: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"projectDetailsControl_CategoryWithMatrixTypeInlineFragment">;
    }>;
    readonly skills: ReadonlyArray<{
      readonly name: string;
    }> | null;
    readonly startDate: any | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDateTimeDisplay_ProjectFragment">;
  };
  readonly " $fragmentType": "projectDetailsControl_ProjectInScenarioFragment";
};
export type projectDetailsControl_ProjectInScenarioFragment$key = {
  readonly " $data"?: projectDetailsControl_ProjectInScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectDetailsControl_ProjectInScenarioFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectDetailsControl_ProjectInScenarioFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skills",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineOne",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryWithMatrixType",
          "kind": "LinkedField",
          "name": "skillMatrixByCategories",
          "plural": true,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "projectDetailsControl_CategoryWithMatrixTypeInlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillMatrix",
                  "kind": "LinkedField",
                  "name": "matrix",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillMatrixColumn",
                      "kind": "LinkedField",
                      "name": "columns",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillMatrixHeaderRow",
                      "kind": "LinkedField",
                      "name": "headerRow",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Skill",
                          "kind": "LinkedField",
                          "name": "entries",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SkillCategory",
                              "kind": "LinkedField",
                              "name": "skillCategory",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillMatrixBodyRow",
                      "kind": "LinkedField",
                      "name": "bodyRows",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BodyMatrix",
                          "kind": "LinkedField",
                          "name": "entries",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SkillAssociation",
                              "kind": "LinkedField",
                              "name": "value",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SkillAssociationData",
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": null,
                                      "kind": "LinkedField",
                                      "name": "value",
                                      "plural": false,
                                      "selections": [
                                        (v4/*: any*/),
                                        {
                                          "kind": "InlineFragment",
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "hasSkill",
                                              "storageKey": null
                                            }
                                          ],
                                          "type": "BinaryAssessmentValue",
                                          "abstractKey": null
                                        },
                                        {
                                          "kind": "InlineFragment",
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "number",
                                              "storageKey": null
                                            }
                                          ],
                                          "type": "NumericalAssessmentValue",
                                          "abstractKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Skill",
                                      "kind": "LinkedField",
                                      "name": "skill",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": null,
                                          "kind": "LinkedField",
                                          "name": "dimension",
                                          "plural": false,
                                          "selections": [
                                            (v4/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "dimensionCount",
                                                  "storageKey": null
                                                }
                                              ],
                                              "type": "NumericalDimension",
                                              "abstractKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectDateTimeDisplay_ProjectFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};
})();

(node as any).hash = "6aca461765c5ace6582de3142d99f394";

export default node;
