/**
 * @generated SignedSource<<9c5fac479ff6e21f3622889e55b6dcdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
export type SkillsSelect_Query$variables = {
  filterBySkillCategoryRef?: string | null;
};
export type SkillsSelect_Query$data = {
  readonly Skills: {
    readonly Skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly dimension: {
            readonly dimensionCount?: number;
            readonly kind: SkillDimensionTypeEnum;
          };
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
};
export type SkillsSelect_Query = {
  response: SkillsSelect_Query$data;
  variables: SkillsSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBySkillCategoryRef"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterBySkillCategoryRef",
    "variableName": "filterBySkillCategoryRef"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 250
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensionCount",
      "storageKey": null
    }
  ],
  "type": "NumericalDimension",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "dimension",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillsSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "dimension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea20d350884374db3bc9759899b7a854",
    "id": null,
    "metadata": {},
    "name": "SkillsSelect_Query",
    "operationKind": "query",
    "text": "query SkillsSelect_Query(\n  $filterBySkillCategoryRef: ID\n) {\n  Skills {\n    Skills(first: 250, filterBySkillCategoryRef: $filterBySkillCategoryRef) {\n      edges {\n        node {\n          id\n          name\n          dimension {\n            __typename\n            kind\n            ... on BinaryDimension {\n              kind\n            }\n            ... on NumericalDimension {\n              kind\n              dimensionCount\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9c61a2df3e266ff1872f34ea3624796";

export default node;
