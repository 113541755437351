/**
 * @generated SignedSource<<e6c7d0cce1eb63e37fd7637791269c58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type authenticatorAppSlot_TwoFactorAuthTokenInlineFragment$data = {
  readonly data: {
    readonly activatedAt: string | null;
    readonly isActivated: boolean;
  };
  readonly id: string;
  readonly " $fragmentType": "authenticatorAppSlot_TwoFactorAuthTokenInlineFragment";
};
export type authenticatorAppSlot_TwoFactorAuthTokenInlineFragment$key = {
  readonly " $data"?: authenticatorAppSlot_TwoFactorAuthTokenInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"authenticatorAppSlot_TwoFactorAuthTokenInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "authenticatorAppSlot_TwoFactorAuthTokenInlineFragment"
};

(node as any).hash = "2d68f4acc44cec2ddd46a7aa75cca4c6";

export default node;
