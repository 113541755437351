/**
 * @generated SignedSource<<4fc67d855251c710d7f64d21d7688599>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type categoryForm_SkillFragment$data = {
  readonly description: string | null;
  readonly dimension: {
    readonly dimensionCount?: number;
    readonly dimensionExplanations?: ReadonlyArray<string>;
    readonly kind?: SkillDimensionTypeEnum;
  };
  readonly id: string;
  readonly name: string;
  readonly skillCategory: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "categoryForm_SkillFragment";
};
export type categoryForm_SkillFragment$key = {
  readonly " $data"?: categoryForm_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryForm_SkillFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "categoryForm_SkillFragment"
};

(node as any).hash = "04d29a5a508b084ea53155d30294ccdc";

export default node;
