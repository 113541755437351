/**
 * @generated SignedSource<<f71b099666218bbdf9008f6bcce5ab24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillAssessment_Refetch$variables = {
  accountId: string;
  password?: string | null;
  personId?: string | null;
  templateId?: string | null;
};
export type skillAssessment_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessment_GetLastUpdatedDateQuery">;
};
export type skillAssessment_Refetch = {
  response: skillAssessment_Refetch$data;
  variables: skillAssessment_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "personId",
    "variableName": "personId"
  },
  {
    "kind": "Variable",
    "name": "templateId",
    "variableName": "templateId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessment_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "skillAssessment_GetLastUpdatedDateQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessment_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentQueries",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "GetContinueFromDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d142193ab88668fab5caac7f7f2a0327",
    "id": null,
    "metadata": {},
    "name": "skillAssessment_Refetch",
    "operationKind": "query",
    "text": "query skillAssessment_Refetch(\n  $accountId: ID!\n  $password: String\n  $personId: ID\n  $templateId: ID\n) {\n  ...skillAssessment_GetLastUpdatedDateQuery_4HKWD\n}\n\nfragment skillAssessment_GetLastUpdatedDateQuery_4HKWD on Query {\n  Assessment {\n    GetContinueFromDate(accountId: $accountId, password: $password, personId: $personId, templateId: $templateId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d538eb03bf9e5b909bc48aee4fe04c4";

export default node;
