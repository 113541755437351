/**
 * @generated SignedSource<<3d1cbacd29eda553597f5c3f85cba40b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoogleMapsClickout_AddressFragment$data = {
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly " $fragmentType": "GoogleMapsClickout_AddressFragment";
};
export type GoogleMapsClickout_AddressFragment$key = {
  readonly " $data"?: GoogleMapsClickout_AddressFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoogleMapsClickout_AddressFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoogleMapsClickout_AddressFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "type": "Address",
  "abstractKey": null
};

(node as any).hash = "1d70be74727edf2af9131d431c943da6";

export default node;
