import { graphql } from "babel-plugin-relay/macro";

export const STAFF_VIEW_QUERY = graphql`
	query staffViewPart_Query(
		$id: ID!
		$intervalType: IntervalType!
		$filterByPersonName: String
		$filterByRegions: [ID!]
		$filterByDivisions: [ID!]
		$filterByStages: [ID!]
		$filterByAssignmentRoles: [ID!]
		$filterByUtilizationStatus: [UtilizationStatus!]
		$filterByAllocatedDateMinimum: LocalDate
		$filterByAllocatedDateMaximum: LocalDate
		$filterBySalaryMinimum: BigDecimal
		$filterBySalaryMaximum: BigDecimal
		$filterByAssignmentDateMinimum: LocalDate
		$filterByAssignmentDateMaximum: LocalDate
		$filterByGapDaysMinimum: Int
		$filterByGapDaysMaximum: Int
		$filterByExecutives: [ID!]
		$showAll: Boolean
		$sort: StaffViewSort
		$utilizationWindow: UtilizationWindowInput
	) {
		node(id: $id) {
			... on Scenario {
				...staffViewPart_ScenarioFragment
					@arguments(
						scenarioId: $id
						intervalType: $intervalType
						filterByPersonName: $filterByPersonName
						filterByRegions: $filterByRegions
						filterByDivisions: $filterByDivisions
						filterByStages: $filterByStages
						filterByAssignmentRoles: $filterByAssignmentRoles
						filterByUtilizationStatus: $filterByUtilizationStatus
						filterByAllocatedDateMinimum: $filterByAllocatedDateMinimum
						filterByAllocatedDateMaximum: $filterByAllocatedDateMaximum
						filterBySalaryMinimum: $filterBySalaryMinimum
						filterBySalaryMaximum: $filterBySalaryMaximum
						filterByAssignmentDateMinimum: $filterByAssignmentDateMinimum
						filterByAssignmentDateMaximum: $filterByAssignmentDateMaximum
						filterByGapDaysMinimum: $filterByGapDaysMinimum
						filterByGapDaysMaximum: $filterByGapDaysMaximum
						filterByExecutives: $filterByExecutives
						showAll: $showAll
						sort: $sort
						utilizationWindow: $utilizationWindow
					)
			}
		}
	}
`;

export const SCENARIO_FRAGMENT = graphql`
	fragment staffViewPart_ScenarioFragment on Scenario
	@argumentDefinitions(
		scenarioId: { type: "ID!" }
		intervalType: { type: "IntervalType!" }
		filterByPersonName: { type: "String" }
		filterByRegions: { type: "[ID!]" }
		filterByDivisions: { type: "[ID!]" }
		filterByStages: { type: "[ID!]" }
		filterByAssignmentRoles: { type: "[ID!]" }
		filterByCurrentlyAssignedAssignmentRoles: { type: "[ID!]" }
		filterByUtilizationStatus: { type: "[UtilizationStatus!]" }
		filterByAllocatedDateMinimum: { type: "LocalDate" }
		filterByAllocatedDateMaximum: { type: "LocalDate" }
		filterBySalaryMinimum: { type: "BigDecimal" }
		filterBySalaryMaximum: { type: "BigDecimal" }
		filterByAssignmentDateMinimum: { type: "LocalDate" }
		filterByAssignmentDateMaximum: { type: "LocalDate" }
		filterByGapDaysMinimum: { type: "Int" }
		filterByGapDaysMaximum: { type: "Int" }
		showAll: { type: "Boolean" }
		sort: { type: "StaffViewSort" }
		filterByExecutives: { type: "[ID!]" }
		utilizationWindow: { type: "UtilizationWindowInput" }
		filterByStaff: { type: "[ID!]" }
	)
	@refetchable(queryName: "staffViewPart_RefetchQuery") {
		staffView(
			intervalType: $intervalType
			filterByPersonName: $filterByPersonName
			filterByRegions: $filterByRegions
			filterByDivisions: $filterByDivisions
			filterByStages: $filterByStages
			filterByAssignmentRoles: $filterByAssignmentRoles
			filterByCurrentlyAssignedAssignmentRoles: $filterByCurrentlyAssignedAssignmentRoles
			filterByUtilizationStatus: $filterByUtilizationStatus
			filterByAllocatedDateMinimum: $filterByAllocatedDateMinimum
			filterByAllocatedDateMaximum: $filterByAllocatedDateMaximum
			filterBySalaryMinimum: $filterBySalaryMinimum
			filterBySalaryMaximum: $filterBySalaryMaximum
			filterByAssignmentDateMinimum: $filterByAssignmentDateMinimum
			filterByAssignmentDateMaximum: $filterByAssignmentDateMaximum
			filterByGapDaysMinimum: $filterByGapDaysMinimum
			filterByGapDaysMaximum: $filterByGapDaysMaximum
			filterByExecutives: $filterByExecutives
			showAll: $showAll
			sort: $sort
			utilizationWindow: $utilizationWindow
			filterByStaff: $filterByStaff
		) {
			intervalType
			intervals {
				index
				...IntervalHeaderComponent_IntervalFragment
				...allocationBarProvider_IntervalFragment
			}
			allocationGroups {
				groupType
				allocations {
					person {
						id
						assignmentRole {
							id
							name
						}
						name
						sumOfProjectVolume(scenarioRef: $scenarioId)
						...personCard_PersonFragment @arguments(scenarioId: $scenarioId)
					}
					lanes {
						allocations {
							id
							assignment {
								person {
									id
									name
								}
								id
							}
							startDate
							endDate

							...allocationBarProvider_AllocationFragment
						}
					}
					gapDays
				}
				... on ProjectGroup {
					project {
						name
					}
				}
				... on AssignmentRoleGroup {
					assignmentRole {
						name
					}
				}
			}
		}
		utilizationWithStandAndEndDate(utilizationWindow: $utilizationWindow) {
			...personCard_ScenarioUtilizationFragment
		}
		...personCard_ScenarioFragment
		...allocationBarProvider_ScenarioFragment
	}
`;
