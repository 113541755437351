/**
 * @generated SignedSource<<b16e6c3256a5c1f4b91f5edcac54fef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegionsSelect_RegionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "RegionsSelect_RegionFragment";
};
export type RegionsSelect_RegionFragment$key = {
  readonly " $data"?: RegionsSelect_RegionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegionsSelect_RegionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RegionsSelect_RegionFragment"
};

(node as any).hash = "88d1164f3bbe0d5663717dcb17f7e960";

export default node;
