/**
 * @generated SignedSource<<06dccbc6fd795322fc64550c55eeb73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditUserButton_UserFragment$data = {
  readonly activated: boolean;
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditUserButton_UserFragment";
};
export type EditUserButton_UserFragment$key = {
  readonly " $data"?: EditUserButton_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditUserButton_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditUserButton_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0c6e17de58956f8a85fb7bda5835891a";

export default node;
