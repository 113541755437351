/**
 * @generated SignedSource<<a9f1fbbeb2158605082b8a08696a0030>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personDetailsButton_ScenarioFragment$data = {
  readonly gapDays: {
    readonly personGapDays: ReadonlyArray<{
      readonly gapDays: number;
      readonly personRef: string;
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "personDetailsButton_ScenarioFragment";
};
export type personDetailsButton_ScenarioFragment$key = {
  readonly " $data"?: personDetailsButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personDetailsButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioGapDays",
      "kind": "LinkedField",
      "name": "gapDays",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonGapDays",
          "kind": "LinkedField",
          "name": "personGapDays",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "personRef",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gapDays",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "528e2ba4f4a32ec65e5d99e431261b6c";

export default node;
