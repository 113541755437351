/**
 * @generated SignedSource<<706f70200174e0e3e247d8a7974b2d50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personDetailsControl_PersonFragment$data = {
  readonly address: {
    readonly city: string;
    readonly country: string;
    readonly latitude: number | null;
    readonly lineOne: string;
    readonly longitude: number | null;
    readonly postalCode: string;
    readonly state: string;
  } | null;
  readonly assignmentRole: {
    readonly name: string;
  } | null;
  readonly associatedWithDivisions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly associatedWithRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly avatar: {
    readonly url: string | null;
  } | null;
  readonly comment: string | null;
  readonly email: string | null;
  readonly id: string;
  readonly name: string;
  readonly phone: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"UtilizationOverTimeGraph_PersonFragment" | "skillsDisplay_PersonFragment">;
  readonly " $fragmentType": "personDetailsControl_PersonFragment";
};
export type personDetailsControl_PersonFragment$key = {
  readonly " $data"?: personDetailsControl_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsControl_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "personDetailsControl_PersonFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "assignmentRole",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lineOne",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "associatedWithRegions",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Division",
      "kind": "LinkedField",
      "name": "associatedWithDivisions",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "skillsDisplay_PersonFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "scenarioId",
          "variableName": "scenarioId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "UtilizationOverTimeGraph_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "8481e20f34d8556af0d26f4b46d93515";

export default node;
