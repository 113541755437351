/**
 * @generated SignedSource<<76119e655b1a2fa13b6212dce3a2c6cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangePersonActivationButton_PersonFragment$data = {
  readonly id: string;
  readonly isDeactivated: boolean;
  readonly " $fragmentType": "ChangePersonActivationButton_PersonFragment";
};
export type ChangePersonActivationButton_PersonFragment$key = {
  readonly " $data"?: ChangePersonActivationButton_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangePersonActivationButton_PersonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangePersonActivationButton_PersonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeactivated",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "f827321fca3b249e646ec870951798bc";

export default node;
