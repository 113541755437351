/**
 * @generated SignedSource<<e946587b9f7e16b667d6875e0d6cde96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type numericalSkillTooltipIcon_SkillFragment$data = {
  readonly dimension: {
    readonly dimensionCount?: number;
    readonly dimensionExplanations?: ReadonlyArray<string>;
    readonly kind: SkillDimensionTypeEnum;
  };
  readonly " $fragmentType": "numericalSkillTooltipIcon_SkillFragment";
};
export type numericalSkillTooltipIcon_SkillFragment$key = {
  readonly " $data"?: numericalSkillTooltipIcon_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"numericalSkillTooltipIcon_SkillFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "numericalSkillTooltipIcon_SkillFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dimension",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dimensionExplanations",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dimensionCount",
              "storageKey": null
            }
          ],
          "type": "NumericalDimension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};

(node as any).hash = "d41f5d852925e296364db83972c3b72e";

export default node;
