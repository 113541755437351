import {
	clearPeopleFilters,
	selectPeopleFilters,
	setPeopleFilters,
} from "../../../redux/PeopleSlice";
import { useDispatch, useSelector } from "react-redux";
import { DefaultSettingsFilters } from "@components/settings-filters";

export const PeopleFiltersComponent = () => {
	const filters = useSelector(selectPeopleFilters);
	const dispatch = useDispatch();

	const handleOnChange = (e?: string) => {
		dispatch(
			setPeopleFilters({
				...filters,
				filterByName: e,
			}),
		);
	};
	const handleOnReset = () => {
		dispatch(clearPeopleFilters());
	};
	return (
		<DefaultSettingsFilters
			onChange={handleOnChange}
			onReset={handleOnReset}
			value={filters.filterByName}
		/>
	);
};
