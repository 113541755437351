/**
 * @generated SignedSource<<aeeb9db32c0d22383bc422e8b503bbac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type changeUserInAccountGroupsModal_UserInAccountFragment$data = {
  readonly groups: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly user: {
    readonly id: string;
  };
  readonly " $fragmentType": "changeUserInAccountGroupsModal_UserInAccountFragment";
};
export type changeUserInAccountGroupsModal_UserInAccountFragment$key = {
  readonly " $data"?: changeUserInAccountGroupsModal_UserInAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"changeUserInAccountGroupsModal_UserInAccountFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "changeUserInAccountGroupsModal_UserInAccountFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "groups",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserInAccount",
  "abstractKey": null
};
})();

(node as any).hash = "9317574c0519b67ab8a7eb5ed631c8a3";

export default node;
