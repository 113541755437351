/**
 * @generated SignedSource<<2a210c7ffe187dc4c14b0e26877ab2c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "AccountPermission_Auth_DriveTimesEnabled" | "AccountPermission_Auth_Field" | "AccountPermission_Auth_GapDaysEnabled" | "AccountPermission_Auth_PreConIntegration" | "AccountPermission_Auth_Reports" | "AccountPermission_Rand_DataWarehouseIntegration" | "AccountPermission_System_Root" | "UserInAccountPermission_Assessment_Edit" | "UserInAccountPermission_AssignmentRole_Edit" | "UserInAccountPermission_Division_Edit" | "UserInAccountPermission_Files_Delete" | "UserInAccountPermission_Management_Management" | "UserInAccountPermission_Maps_Read" | "UserInAccountPermission_Precon_Edit" | "UserInAccountPermission_PrivateData_Read" | "UserInAccountPermission_ProjectStages_Edit" | "UserInAccountPermission_Project_Edit" | "UserInAccountPermission_Region_Edit" | "UserInAccountPermission_Salary_Read" | "UserInAccountPermission_Scenario_Budgets" | "UserInAccountPermission_Scenario_Edit" | "UserInAccountPermission_Scenario_Masterplan" | "UserInAccountPermission_Skills_Edit" | "UserInAccountPermission_Skills_Read" | "UserInAccountPermission_Staff_Edit" | "UserInAccountPermission_System_Owner" | "UserInAccountPermission_Tasks_Tasks" | "UserInAccountPermission_Templates_Edit" | "UserInAccountPermission_Utilization_Read";
export type PermissionsField_Query$variables = {};
export type PermissionsField_Query$data = {
  readonly Management: {
    readonly AvailablePermissions: ReadonlyArray<Permission>;
  };
};
export type PermissionsField_Query = {
  response: PermissionsField_Query$data;
  variables: PermissionsField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ManagementQueries",
    "kind": "LinkedField",
    "name": "Management",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AvailablePermissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermissionsField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PermissionsField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "db8e9491050891d63529fe93fb8f7f37",
    "id": null,
    "metadata": {},
    "name": "PermissionsField_Query",
    "operationKind": "query",
    "text": "query PermissionsField_Query {\n  Management {\n    AvailablePermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff542df62b45565b88145d11c5e4ea51";

export default node;
