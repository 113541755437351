/**
 * @generated SignedSource<<1b71db9becf15d9a2a61f7d995f79a4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentsInProjectList_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_ScenarioFragment" | "CheckScenarioPermissions_ScenarioFragment">;
  readonly " $fragmentType": "AssignmentsInProjectList_ScenarioFragment";
};
export type AssignmentsInProjectList_ScenarioFragment$key = {
  readonly " $data"?: AssignmentsInProjectList_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentsInProjectList_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentsInProjectList_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignmentCard_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckScenarioPermissions_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "a268f16a810f177593d7a3ac77fa41d6";

export default node;
