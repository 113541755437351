/**
 * @generated SignedSource<<09962b7764816af06caf59268e56e270>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenarioStatistics_ScenarioFragment$data = {
  readonly gapDays: {
    readonly " $fragmentSpreads": FragmentRefs<"GapDaysDisplay_GapDaysFragment">;
  };
  readonly utilization: {
    readonly " $fragmentSpreads": FragmentRefs<"UtilizationDisplay_UtilizationFragment">;
  };
  readonly " $fragmentType": "ScenarioStatistics_ScenarioFragment";
};
export type ScenarioStatistics_ScenarioFragment$key = {
  readonly " $data"?: ScenarioStatistics_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScenarioStatistics_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioStatistics_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioGapDays",
      "kind": "LinkedField",
      "name": "gapDays",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GapDaysDisplay_GapDaysFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioUtilization",
      "kind": "LinkedField",
      "name": "utilization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UtilizationDisplay_UtilizationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "b9db1eb1747970d4393882cd7ba2e3bb";

export default node;
