/**
 * @generated SignedSource<<326b06a05d6c8bba01111eb2d41ca872>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WriteAssignmentsToDynamicsInput = {
  clientMutationId?: string | null;
  projectIds: ReadonlyArray<string>;
};
export type writeAssignmentsToDynamicsButton_WriteMutation$variables = {
  input: WriteAssignmentsToDynamicsInput;
};
export type writeAssignmentsToDynamicsButton_WriteMutation$data = {
  readonly Dynamics: {
    readonly writeAssignmentsToDynamics: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type writeAssignmentsToDynamicsButton_WriteMutation = {
  response: writeAssignmentsToDynamicsButton_WriteMutation$data;
  variables: writeAssignmentsToDynamicsButton_WriteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DynamicsMutations",
    "kind": "LinkedField",
    "name": "Dynamics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "WriteAssignmentsToDynamicsPayload",
        "kind": "LinkedField",
        "name": "writeAssignmentsToDynamics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "writeAssignmentsToDynamicsButton_WriteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "writeAssignmentsToDynamicsButton_WriteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8993ac0f4324eb2922c3b11af0713e4c",
    "id": null,
    "metadata": {},
    "name": "writeAssignmentsToDynamicsButton_WriteMutation",
    "operationKind": "mutation",
    "text": "mutation writeAssignmentsToDynamicsButton_WriteMutation(\n  $input: WriteAssignmentsToDynamicsInput!\n) {\n  Dynamics {\n    writeAssignmentsToDynamics(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a996ae8870cbc3437813f5c89d3a44ec";

export default node;
