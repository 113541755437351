/**
 * @generated SignedSource<<130a6d996e6cd4f76694b208cc1d0169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editUserInAccountGroupButton_GroupFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editUserInAccountGroupModal_GroupFragment">;
  readonly " $fragmentType": "editUserInAccountGroupButton_GroupFragment";
};
export type editUserInAccountGroupButton_GroupFragment$key = {
  readonly " $data"?: editUserInAccountGroupButton_GroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editUserInAccountGroupButton_GroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editUserInAccountGroupButton_GroupFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editUserInAccountGroupModal_GroupFragment"
    }
  ],
  "type": "UserInAccountGroup",
  "abstractKey": null
};

(node as any).hash = "3b302352c994bb38961db816e688684e";

export default node;
