import React, { MutableRefObject, ReactNode, Suspense, useRef } from "react";
import { TkButtonLink } from "./TkButtonLink";
import { TkDialog } from "./TkDialog";
import { FormikProps } from "formik/dist/types";

interface OwnProps<FormState> {
	title: string;
	isVisible: boolean;
	onHide: () => void;
	affirmativeText?: string;

	formComponent: (
		state: MutableRefObject<FormikProps<FormState> | null>,
		onHide: () => void,
	) => ReactNode;
}

export function SuspenseDialogWithState<FormState>({
	title,
	isVisible,
	onHide,
	affirmativeText,
	formComponent,
}: OwnProps<FormState>) {
	const formik = useRef<FormikProps<FormState>>();

	return (
		<TkDialog
			dismissableMask={true}
			header={<h1>{title}</h1>}
			visible={isVisible}
			onHide={() => onHide()}
			footer={
				<div className="flex">
					<TkButtonLink
						disabled={formik.current?.isSubmitting}
						type="button"
						onClick={() => onHide()}
						label={"Cancel"}
						className="m-auto w-auto"
					/>
					<TkButtonLink
						disabled={formik.current?.isSubmitting}
						onClick={() => formik.current?.handleSubmit()}
						label={
							formik.current?.isSubmitting ? "Working..." : affirmativeText || "Save"
						}
						className="m-auto w-auto"
					/>
				</div>
			}
		>
			<Suspense fallback={<div>Loading...</div>}>
				{formComponent(formik as any, onHide)}
			</Suspense>
		</TkDialog>
	);
}
