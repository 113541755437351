/**
 * @generated SignedSource<<237065243f692710e9fa23888664fdd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthProviderKind = "AzureAd" | "FSAuthProvider";
export type SetAzureAdSsoConfigurationInput = {
  azureAdAuthProvider?: AzureAdAuthProviderConfigInput | null;
  clientMutationId?: string | null;
};
export type AzureAdAuthProviderConfigInput = {
  applicationId: string;
  authorityUrl: string;
  domain: string;
  isActivated: boolean;
  secret: string;
  tenantId: string;
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$variables = {
  input: SetAzureAdSsoConfigurationInput;
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$data = {
  readonly Auth: {
    readonly setAzureAdSsoConfiguration: {
      readonly account: {
        readonly extensions: ReadonlyArray<{
          readonly authProviders?: ReadonlyArray<{
            readonly applicationId?: string;
            readonly authorityUrl?: string;
            readonly domain?: string;
            readonly isActivated?: boolean;
            readonly kind?: AuthProviderKind;
            readonly secret?: string;
            readonly tenantId?: string;
          }>;
        }>;
      };
    } | null;
  };
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation = {
  response: editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$data;
  variables: editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActivated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorityUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secret",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "AzureAdAuthProvider",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAzureAdSsoConfigurationPayload",
            "kind": "LinkedField",
            "name": "setAzureAdSsoConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "authProviders",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AuthProviderAccountExtension",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAzureAdSsoConfigurationPayload",
            "kind": "LinkedField",
            "name": "setAzureAdSsoConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "authProviders",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AuthProviderAccountExtension",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4f0844a1eb3896eb955dbf44a70621d",
    "id": null,
    "metadata": {},
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "operationKind": "mutation",
    "text": "mutation editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation(\n  $input: SetAzureAdSsoConfigurationInput!\n) {\n  Auth {\n    setAzureAdSsoConfiguration(input: $input) {\n      account {\n        extensions {\n          __typename\n          ... on AuthProviderAccountExtension {\n            authProviders {\n              __typename\n              ... on AzureAdAuthProvider {\n                isActivated\n                applicationId\n                authorityUrl\n                domain\n                tenantId\n                secret\n                kind\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a95da27220b997283c6060d7a871062";

export default node;
