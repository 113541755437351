/**
 * @generated SignedSource<<86f52117eab167b095c6a77bfbd8a359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personCard_PersonFragment$data = {
  readonly assignmentRole: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly comment: string | null;
  readonly id: string;
  readonly name: string;
  readonly sumOfProjectVolume: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_PersonFragment">;
  readonly " $fragmentType": "personCard_PersonFragment";
};
export type personCard_PersonFragment$key = {
  readonly " $data"?: personCard_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personCard_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "personCard_PersonFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "assignmentRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "scenarioRef",
          "variableName": "scenarioId"
        }
      ],
      "kind": "ScalarField",
      "name": "sumOfProjectVolume",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personDetailsButton_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "61b0de7f097547afdc37b25d3a47bfa8";

export default node;
