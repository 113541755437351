/**
 * @generated SignedSource<<21bb67b6f0d2968155900837a35c79de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnonymizeUserButton_UserFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "AnonymizeUserButton_UserFragment";
};
export type AnonymizeUserButton_UserFragment$key = {
  readonly " $data"?: AnonymizeUserButton_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnonymizeUserButton_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnonymizeUserButton_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "69aaa3d5481cbd3099f0d77a7236a2c7";

export default node;
