/**
 * @generated SignedSource<<1f511d5a79ff645f10a06ab663ba53ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAssignmentButton_AssignmentFragment$data = {
  readonly comment: string | null;
  readonly endDate: any;
  readonly id: string;
  readonly importId: string | null;
  readonly isExecutive: boolean;
  readonly person: {
    readonly id: string;
  } | null;
  readonly startDate: any;
  readonly validAssignmentRoles: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly weight: number | null;
  readonly " $fragmentType": "EditAssignmentButton_AssignmentFragment";
};
export type EditAssignmentButton_AssignmentFragment$key = {
  readonly " $data"?: EditAssignmentButton_AssignmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentButton_AssignmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAssignmentButton_AssignmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "validAssignmentRoles",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "importId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExecutive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "6f5c6df450d1b120e113596869b6dc77";

export default node;
