/**
 * @generated SignedSource<<9f00030fc489efed18028b21d618032f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportProjectStagesButton_ExportMutation$variables = {};
export type ExportProjectStagesButton_ExportMutation$data = {
  readonly Project: {
    readonly exportProjectStages: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportProjectStagesButton_ExportMutation = {
  response: ExportProjectStagesButton_ExportMutation$data;
  variables: ExportProjectStagesButton_ExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportProjectStagesButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportProjectStagesPayload",
            "kind": "LinkedField",
            "name": "exportProjectStages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportProjectStages(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExportProjectStagesButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportProjectStagesPayload",
            "kind": "LinkedField",
            "name": "exportProjectStages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportProjectStages(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a42e276457b8cdf8492db6d6e9c68f50",
    "id": null,
    "metadata": {},
    "name": "ExportProjectStagesButton_ExportMutation",
    "operationKind": "mutation",
    "text": "mutation ExportProjectStagesButton_ExportMutation {\n  Project {\n    exportProjectStages(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5b2d1cb65c0078cd8ad5b5480c3f22f";

export default node;
