/**
 * @generated SignedSource<<044618e4264d4dc0044fa77f1c31c3e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImportSkillsInput = {
  clientMutationId?: string | null;
  fileId: string;
};
export type ImportSkillsButton_ImportMutation$variables = {
  input: ImportSkillsInput;
};
export type ImportSkillsButton_ImportMutation$data = {
  readonly Skills: {
    readonly importSkills: {
      readonly result: {
        readonly editedEntities: number;
        readonly issues: ReadonlyArray<{
          readonly issue: string;
          readonly row: number;
        }>;
        readonly newEntities: number;
      };
    } | null;
  };
};
export type ImportSkillsButton_ImportMutation = {
  response: ImportSkillsButton_ImportMutation$data;
  variables: ImportSkillsButton_ImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SkillsMutations",
    "kind": "LinkedField",
    "name": "Skills",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ImportSkillsPayload",
        "kind": "LinkedField",
        "name": "importSkills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportResult",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editedEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportIssue",
                "kind": "LinkedField",
                "name": "issues",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "row",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportSkillsButton_ImportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportSkillsButton_ImportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "45f1855f0555a06f3a1f7918554cb505",
    "id": null,
    "metadata": {},
    "name": "ImportSkillsButton_ImportMutation",
    "operationKind": "mutation",
    "text": "mutation ImportSkillsButton_ImportMutation(\n  $input: ImportSkillsInput!\n) {\n  Skills {\n    importSkills(input: $input) {\n      result {\n        editedEntities\n        newEntities\n        issues {\n          row\n          issue\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "810513edb18dde788c634f2ff1d95f83";

export default node;
