/**
 * @generated SignedSource<<6c54b3ce81ad855ec3809db433d454ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type scenarioSelectField_ScenarioInlineFragment$data = {
  readonly id: string;
  readonly isMasterPlan: boolean;
  readonly isPublic: boolean;
  readonly name: string;
  readonly " $fragmentType": "scenarioSelectField_ScenarioInlineFragment";
};
export type scenarioSelectField_ScenarioInlineFragment$key = {
  readonly " $data"?: scenarioSelectField_ScenarioInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"scenarioSelectField_ScenarioInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "scenarioSelectField_ScenarioInlineFragment"
};

(node as any).hash = "9d00c7c7468f43e2f8d19201a5dd45bb";

export default node;
