/**
 * @generated SignedSource<<0141ed5bb3f245e191168965a7d2db76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editPersonSkillAssociationsModalContent_SkillInlineFragment$data = {
  readonly description: string | null;
  readonly dimension: {
    readonly dimensionCount?: number;
    readonly dimensionExplanations?: ReadonlyArray<string>;
    readonly kind: SkillDimensionTypeEnum;
  };
  readonly id: string;
  readonly name: string;
  readonly skillCategory: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"editSkillAssociationModal_SkillFragment" | "numericalSkillTooltipIcon_SkillFragment">;
  readonly " $fragmentType": "editPersonSkillAssociationsModalContent_SkillInlineFragment";
};
export type editPersonSkillAssociationsModalContent_SkillInlineFragment$key = {
  readonly " $data"?: editPersonSkillAssociationsModalContent_SkillInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editPersonSkillAssociationsModalContent_SkillInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "editPersonSkillAssociationsModalContent_SkillInlineFragment"
};

(node as any).hash = "50cbf6528bdafdc520e2afafd944ef0c";

export default node;
