/**
 * @generated SignedSource<<fecb84caec71263241eb0ff36ff923a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoadPursuitProjectsFromDWHInput = {
  clientMutationId?: string | null;
};
export type loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation$variables = {
  input: LoadPursuitProjectsFromDWHInput;
};
export type loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation$data = {
  readonly Rand: {
    readonly loadPursuitProjectsFromDWH: {
      readonly clientMutationId: string | null;
      readonly syncResult: {
        readonly editedEntities: number;
        readonly issues: ReadonlyArray<{
          readonly id: string;
          readonly issue: string;
        }>;
      };
    } | null;
  };
};
export type loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation = {
  response: loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation$data;
  variables: loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RandMutations",
    "kind": "LinkedField",
    "name": "Rand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LoadPursuitProjectsFromDWHPayload",
        "kind": "LinkedField",
        "name": "loadPursuitProjectsFromDWH",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SyncResult",
            "kind": "LinkedField",
            "name": "syncResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editedEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncIssue",
                "kind": "LinkedField",
                "name": "issues",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "60cb7d7c77aa064e472d5a5b58552a68",
    "id": null,
    "metadata": {},
    "name": "loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation",
    "operationKind": "mutation",
    "text": "mutation loadPursuitProjectsFromRandDwhButton_loadPursuitProjectsFromDWHMutation(\n  $input: LoadPursuitProjectsFromDWHInput!\n) {\n  Rand {\n    loadPursuitProjectsFromDWH(input: $input) {\n      syncResult {\n        editedEntities\n        issues {\n          id\n          issue\n        }\n      }\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c93245073a3260dbd62d3b34e83bc49";

export default node;
