/**
 * @generated SignedSource<<f47653129ae96d5bd6de140659bb1e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editNumericalSkillAssociationModal_PersonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "editNumericalSkillAssociationModal_PersonFragment";
};
export type editNumericalSkillAssociationModal_PersonFragment$key = {
  readonly " $data"?: editNumericalSkillAssociationModal_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editNumericalSkillAssociationModal_PersonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editNumericalSkillAssociationModal_PersonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "a4f60312bba84991df60bd61bd3141a4";

export default node;
