/**
 * @generated SignedSource<<519be145c9737e2818e48ca3e350bcc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillCategoriesTable_Refetch$variables = {
  after?: string | null;
  filterByName?: string | null;
  first?: number | null;
};
export type skillCategoriesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"skillCategoriesTable_QueryFragment">;
};
export type skillCategoriesTable_Refetch = {
  response: skillCategoriesTable_Refetch$data;
  variables: skillCategoriesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillCategoriesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "skillCategoriesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillCategoriesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SkillCategoriesConnection",
            "kind": "LinkedField",
            "name": "SkillCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillCategoriesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillCategory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "skillCategoriesTable_SkillCategories",
            "kind": "LinkedHandle",
            "name": "SkillCategories"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6392a15a0f3506572ced42b80afce1fe",
    "id": null,
    "metadata": {},
    "name": "skillCategoriesTable_Refetch",
    "operationKind": "query",
    "text": "query skillCategoriesTable_Refetch(\n  $after: String\n  $filterByName: String\n  $first: Int = 20\n) {\n  ...skillCategoriesTable_QueryFragment_3KLhx3\n}\n\nfragment SkillCategorySortOrderButtons_AssignmentRoleFragment on SkillCategory {\n  id\n  name\n  sortOrder\n}\n\nfragment editSkillCategoryButton_SkillCategoryFragment on SkillCategory {\n  id\n  name\n}\n\nfragment skillCategoriesTable_QueryFragment_3KLhx3 on Query {\n  Skills {\n    SkillCategories(first: $first, after: $after, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          sortOrder\n          ...editSkillCategoryButton_SkillCategoryFragment\n          ...SkillCategorySortOrderButtons_AssignmentRoleFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24d793295ec9bf8d3295b2ab9ea21d1c";

export default node;
