import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { AssignmentsInProjectList_ProjectFragment$key } from "../../__generated__/AssignmentsInProjectList_ProjectFragment.graphql";
import { AssignmentCard } from "./AssignmentCard";
import { CreateAssignmentButton } from "./CreateAssignmentButton";
import React from "react";
import { AssignmentsInProjectList_ScenarioFragment$key } from "../../__generated__/AssignmentsInProjectList_ScenarioFragment.graphql";
import { CheckScenarioPermissions } from "./CheckScenarioPermissions";
import { useSelector } from "react-redux";
import { selectShowPast } from "../../redux/ProjectViewSlice";
import moment from "moment-timezone";
import { CreateAssignmentsFromTemplateButton } from "./CreateAssignmentsFromTemplateButton";

const SCENARIO_FRAGMENT = graphql`
	fragment AssignmentsInProjectList_ScenarioFragment on Scenario {
		id
		...AssignmentCard_ScenarioFragment
		...CheckScenarioPermissions_ScenarioFragment
	}
`;

const PROJECT_FRAGMENT = graphql`
	fragment AssignmentsInProjectList_ProjectFragment on ProjectInScenario {
		id

		project {
			...CreateAssignmentButton_ProjectFragment
			...CreateAssignmentsFromTemplateButton_ProjectFragment
		}
		assignments {
			__id
			edges {
				node {
					id
					endDate
					...AssignmentCard_AssignmentFragment
					validAssignmentRoles {
						sortOrder
					}
				}
			}
		}
	}
`;

interface OwnProps {
	scenarioFragmentRef: AssignmentsInProjectList_ScenarioFragment$key;
	projectFragmentRef: AssignmentsInProjectList_ProjectFragment$key;
}

export const AssignmentsInProjectList = React.memo(
	({ scenarioFragmentRef, projectFragmentRef }: OwnProps) => {
		const scenario = useFragment<AssignmentsInProjectList_ScenarioFragment$key>(
			SCENARIO_FRAGMENT,
			scenarioFragmentRef,
		);
		const projectInScenario = useFragment<AssignmentsInProjectList_ProjectFragment$key>(
			PROJECT_FRAGMENT,
			projectFragmentRef,
		);
		const showPast = useSelector(selectShowPast);

		const assignments = projectInScenario.assignments.edges?.map((e) => e!.node!) || [];

		const assignmentsToShow = assignments
			.filter((a) => {
				if (showPast) {
					return true;
				}
				return moment(a.endDate?.replace("[UTC]", "")).isAfter(moment());
			})
			.sort((x, y) => {
				const minAssignmentX = x.validAssignmentRoles.map((r) => r.sortOrder).min();
				const minAssignmentY = y.validAssignmentRoles.map((r) => r.sortOrder).min();

				if (minAssignmentY && minAssignmentX) {
					return minAssignmentX > minAssignmentY ? 1 : -1;
				}
				return minAssignmentX ? 1 : -1;
			});

		return (
			<div>
				{assignmentsToShow.map((assignment) => {
					return (
						<AssignmentCard
							key={assignment.id}
							scenarioFragmentRef={scenario}
							assignmentFragmentRef={assignment}
						/>
					);
				})}

				<CheckScenarioPermissions
					scenarioFragmentRef={scenario}
					requiredPermission={"UserInAccountPermission_Scenario_Edit"}
				>
					<CreateAssignmentButton
						className="mb-2 hide-print"
						scenarioId={scenario.id}
						projectFragmentRef={projectInScenario.project}
					/>

					<CreateAssignmentsFromTemplateButton
						className="hide-print"
						scenarioId={scenario.id}
						projectFragmentRef={projectInScenario.project}
					/>
				</CheckScenarioPermissions>
			</div>
		);
	},
);
