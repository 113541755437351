/**
 * @generated SignedSource<<51161f20db85730f14059c89b503363b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type projectDetailsControl_CategoryWithMatrixTypeInlineFragment$data = {
  readonly category: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly matrix: {
    readonly bodyRows: ReadonlyArray<{
      readonly entries: ReadonlyArray<{
        readonly value: {
          readonly data: {
            readonly skill: {
              readonly dimension: {
                readonly dimensionCount?: number;
                readonly kind: SkillDimensionTypeEnum;
              };
              readonly id: string;
              readonly name: string;
            } | null;
            readonly value: {
              readonly hasSkill?: boolean;
              readonly kind: SkillDimensionTypeEnum;
              readonly number?: number;
            };
          };
          readonly id: string;
        } | null;
      }>;
      readonly key: string;
    }>;
    readonly columns: ReadonlyArray<{
      readonly key: string;
    }>;
    readonly headerRow: {
      readonly entries: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly skillCategory: {
          readonly id: string;
          readonly name: string;
        } | null;
      }>;
      readonly key: string;
    };
  };
  readonly " $fragmentType": "projectDetailsControl_CategoryWithMatrixTypeInlineFragment";
};
export type projectDetailsControl_CategoryWithMatrixTypeInlineFragment$key = {
  readonly " $data"?: projectDetailsControl_CategoryWithMatrixTypeInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectDetailsControl_CategoryWithMatrixTypeInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "projectDetailsControl_CategoryWithMatrixTypeInlineFragment"
};

(node as any).hash = "bd3d4eb441ff1452b74fb9d6ee27926c";

export default node;
