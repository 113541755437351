import { type DefaultFormProps, Form } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { type EditAssignmentRoleFormState } from "@components/edit-assignment-role-button/parts/edit-assignment-role-form/edit-assignment-role-form.types";
import {
	DefaultNumberFieldComponent,
	DefaultPercentageFieldComponent,
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "@components/ui/DefaultTextInput";
import { ValidatedField } from "@components/ui/ValidatedField";

export const EditAssignmentRoleModal = React.forwardRef<
	FormikProps<EditAssignmentRoleFormState>,
	DefaultFormProps<EditAssignmentRoleFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditAssignmentRoleFormState>({
		initialValues: initialState ?? {
			name: "",
			sortOrder: 0,
			maxNumberOfProjects: undefined,
			utilizationProjectionCapInMonths: undefined,
			countAsFullyAllocatedAtPercentage: undefined,
			countAsOverallocatedAtPercentage: undefined,
			useEndDateOfLastAssignmentOverProjectionCap: undefined,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name is a required field."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<EditAssignmentRoleFormState, string>
				className="mb-4"
				name={"name"}
				label={"Name"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>

			<ValidatedField<EditAssignmentRoleFormState, number>
				className="mb-4"
				name={"maxNumberOfProjects"}
				label={"Maximum number of projects"}
				helpText={
					"A person with this role can be in this many projects at the same time to be 100% allocated. If left blank then default to 1 max project."
				}
				required={false}
				formikConfig={formik}
				min={1}
				step={1}
				component={DefaultNumberFieldComponent}
			/>

			<ValidatedField
				name={"useEndDateOfLastAssignmentOverProjectionCap"}
				label={"Use end date of longest assignment instead of months below"}
				formikConfig={formik}
				required={false}
				component={DefaultSwitchComponent}
			/>

			<ValidatedField<EditAssignmentRoleFormState, number>
				className="mb-4"
				name={"utilizationProjectionCapInMonths"}
				label={"Number of months for utilization projection"}
				placeholder={"Up to last assignment"}
				helpText={
					"By default utilization is calculated up to the end date of the last assignment. If you want to limit the scope of the calculation set a lower month value here "
				}
				required={false}
				formikConfig={formik}
				min={1}
				step={1}
				component={DefaultNumberFieldComponent}
			/>

			<ValidatedField<EditAssignmentRoleFormState, number>
				className="mb-4"
				name={"countAsFullyAllocatedAtPercentage"}
				label={"Utilization Percentage for full allocation"}
				placeholder={"Default: 75%"}
				iconClass="pi pi-percent"
				required={false}
				formikConfig={formik}
				component={DefaultPercentageFieldComponent}
			/>

			<ValidatedField<EditAssignmentRoleFormState, number>
				className="mb-4"
				name={"countAsOverallocatedAtPercentage"}
				label={"Utilization Percentage for overallocation"}
				placeholder={"Default: 125%"}
				iconClass="pi pi-percent"
				required={false}
				formikConfig={formik}
				component={DefaultPercentageFieldComponent}
			/>
		</Form>
	);
});
