/**
 * @generated SignedSource<<07094079ca7505ca4a6bcf6c266e0ebd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StartAssessmentInput = {
  accountId: string;
  clientMutationId?: string | null;
  personId: string;
  supervisorId?: string | null;
  templateId: string;
};
export type skillAssessment_StartAssessmentMutation$variables = {
  input: StartAssessmentInput;
};
export type skillAssessment_StartAssessmentMutation$data = {
  readonly Assessment: {
    readonly startAssessment: {
      readonly assessment: {
        readonly id: string;
      };
    } | null;
  };
};
export type skillAssessment_StartAssessmentMutation = {
  response: skillAssessment_StartAssessmentMutation$data;
  variables: skillAssessment_StartAssessmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssessmentMutations",
    "kind": "LinkedField",
    "name": "Assessment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "StartAssessmentPayload",
        "kind": "LinkedField",
        "name": "startAssessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Assessment",
            "kind": "LinkedField",
            "name": "assessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessment_StartAssessmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessment_StartAssessmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "18eb9af4ac987b80a95fe2a7cf3a8fe3",
    "id": null,
    "metadata": {},
    "name": "skillAssessment_StartAssessmentMutation",
    "operationKind": "mutation",
    "text": "mutation skillAssessment_StartAssessmentMutation(\n  $input: StartAssessmentInput!\n) {\n  Assessment {\n    startAssessment(input: $input) {\n      assessment {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e38cfa29f182bc85fd0aa903c8a6eb3c";

export default node;
