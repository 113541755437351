/**
 * @generated SignedSource<<8338b527e5e934349f48f83cb195b380>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsSelectField_ProjectFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ProjectsSelectField_ProjectFragment";
};
export type ProjectsSelectField_ProjectFragment$key = {
  readonly " $data"?: ProjectsSelectField_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsSelectField_ProjectFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ProjectsSelectField_ProjectFragment"
};

(node as any).hash = "409ae772ced138b12e672036c9093192";

export default node;
