/**
 * @generated SignedSource<<f712df7fb012ec5e47753f3a18a2bef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editSkillButton_SkillFragment$data = {
  readonly description: string | null;
  readonly dimension: {
    readonly dimensionCount?: number;
    readonly dimensionExplanations?: ReadonlyArray<string>;
    readonly kind: SkillDimensionTypeEnum;
  };
  readonly id: string;
  readonly name: string;
  readonly skillCategory: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "editSkillButton_SkillFragment";
};
export type editSkillButton_SkillFragment$key = {
  readonly " $data"?: editSkillButton_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editSkillButton_SkillFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editSkillButton_SkillFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillCategory",
      "kind": "LinkedField",
      "name": "skillCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dimension",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dimensionCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dimensionExplanations",
              "storageKey": null
            }
          ],
          "type": "NumericalDimension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};
})();

(node as any).hash = "f062cb10d1f6ee2154148b4b86a9cccc";

export default node;
