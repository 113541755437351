/**
 * @generated SignedSource<<7049c1be6b3ffcc1d76ff72b03fc9d57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Role = "Admin" | "AssessedPerson" | "Supervisor";
import { FragmentRefs } from "relay-runtime";
export type editTemplateButton_AssessmentTemplateFragment$data = {
  readonly assessedSkills: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly skillCategory: {
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
  readonly associatedRoles: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly distributionList: {
    readonly emails: ReadonlyArray<string>;
    readonly role: ReadonlyArray<Role>;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "editTemplateButton_AssessmentTemplateFragment";
};
export type editTemplateButton_AssessmentTemplateFragment$key = {
  readonly " $data"?: editTemplateButton_AssessmentTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editTemplateButton_AssessmentTemplateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editTemplateButton_AssessmentTemplateFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "assessedSkills",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillCategory",
          "kind": "LinkedField",
          "name": "skillCategory",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "associatedRoles",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistributionList",
      "kind": "LinkedField",
      "name": "distributionList",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emails",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssessmentTemplate",
  "abstractKey": null
};
})();

(node as any).hash = "762ceab05605b670bf722036e57df113";

export default node;
