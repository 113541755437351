/**
 * @generated SignedSource<<1a92fcb23b50a27d51b917dedffc76dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editBinarySkillAssociationModal_SkillFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "editBinarySkillAssociationModal_SkillFragment";
};
export type editBinarySkillAssociationModal_SkillFragment$key = {
  readonly " $data"?: editBinarySkillAssociationModal_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editBinarySkillAssociationModal_SkillFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBinarySkillAssociationModal_SkillFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};

(node as any).hash = "414c190a482fa84de9a7ab9a17aac0fc";

export default node;
