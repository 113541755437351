/**
 * @generated SignedSource<<950b4f590c4721b44793379c03cf5807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStagesSelect_ProjectStageFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ProjectStagesSelect_ProjectStageFragment";
};
export type ProjectStagesSelect_ProjectStageFragment$key = {
  readonly " $data"?: ProjectStagesSelect_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStagesSelect_ProjectStageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ProjectStagesSelect_ProjectStageFragment"
};

(node as any).hash = "f04ce1eece92bc54744e9a71632c4db2";

export default node;
