/**
 * @generated SignedSource<<f6d3b4b5c0b06223210cb12d5a8f9c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectViewFiltersPart_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "projectViewFiltersPart_ScenarioFragment";
};
export type projectViewFiltersPart_ScenarioFragment$key = {
  readonly " $data"?: projectViewFiltersPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectViewFiltersPart_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectViewFiltersPart_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "66ef768ce719534e8f8b00ba34d829e0";

export default node;
