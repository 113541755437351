/**
 * @generated SignedSource<<58d19e7df85d88ed9b52eae3a24b1339>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editPersonSkillAssociationsButton_PersonFragment$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"editPersonSkillAssociationsModalContent_PersonFragment">;
  readonly " $fragmentType": "editPersonSkillAssociationsButton_PersonFragment";
};
export type editPersonSkillAssociationsButton_PersonFragment$key = {
  readonly " $data"?: editPersonSkillAssociationsButton_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editPersonSkillAssociationsButton_PersonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editPersonSkillAssociationsButton_PersonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editPersonSkillAssociationsModalContent_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "df805d7b32790c799658d39f63140f7a";

export default node;
