/**
 * @generated SignedSource<<b0988bb86585949f457fa5cf6b12c8a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetupTwoFactorAuthInput = {
  clientMutationId?: string | null;
};
export type authenticatorAppSlot_SetupTwoFactorAuthMutation$variables = {
  input: SetupTwoFactorAuthInput;
};
export type authenticatorAppSlot_SetupTwoFactorAuthMutation$data = {
  readonly Auth: {
    readonly setupTwoFactorAuth: {
      readonly twoFactorAuthToken: {
        readonly " $fragmentSpreads": FragmentRefs<"authenticatorAppSlot_TwoFactorAuthTokenInlineFragment">;
      };
    } | null;
  };
};
export type authenticatorAppSlot_SetupTwoFactorAuthMutation = {
  response: authenticatorAppSlot_SetupTwoFactorAuthMutation$data;
  variables: authenticatorAppSlot_SetupTwoFactorAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TwoFactorAuthTokenData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActivated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authenticatorAppSlot_SetupTwoFactorAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetupTwoFactorAuthPayload",
            "kind": "LinkedField",
            "name": "setupTwoFactorAuth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorAuthToken",
                "kind": "LinkedField",
                "name": "twoFactorAuthToken",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "authenticatorAppSlot_TwoFactorAuthTokenInlineFragment",
                    "selections": (v2/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authenticatorAppSlot_SetupTwoFactorAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetupTwoFactorAuthPayload",
            "kind": "LinkedField",
            "name": "setupTwoFactorAuth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorAuthToken",
                "kind": "LinkedField",
                "name": "twoFactorAuthToken",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2478c11924428dbb654cdf4bd8382c8c",
    "id": null,
    "metadata": {},
    "name": "authenticatorAppSlot_SetupTwoFactorAuthMutation",
    "operationKind": "mutation",
    "text": "mutation authenticatorAppSlot_SetupTwoFactorAuthMutation(\n  $input: SetupTwoFactorAuthInput!\n) {\n  Auth {\n    setupTwoFactorAuth(input: $input) {\n      twoFactorAuthToken {\n        ...authenticatorAppSlot_TwoFactorAuthTokenInlineFragment\n        id\n      }\n    }\n  }\n}\n\nfragment authenticatorAppSlot_TwoFactorAuthTokenInlineFragment on TwoFactorAuthToken {\n  id\n  data {\n    isActivated\n    activatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9aadce471326338401b389fd28606f8";

export default node;
