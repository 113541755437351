/**
 * @generated SignedSource<<4df30e3081fd41464a3159ae45033f0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editPersonSkillAssociationsModalContent_SkillsQuery$variables = {};
export type editPersonSkillAssociationsModalContent_SkillsQuery$data = {
  readonly Skills: {
    readonly Skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"editPersonSkillAssociationsModalContent_SkillInlineFragment">;
        };
      } | null> | null;
    };
  };
};
export type editPersonSkillAssociationsModalContent_SkillsQuery = {
  response: editPersonSkillAssociationsModalContent_SkillsQuery$data;
  variables: editPersonSkillAssociationsModalContent_SkillsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCategory",
  "kind": "LinkedField",
  "name": "skillCategory",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensionCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensionExplanations",
      "storageKey": null
    }
  ],
  "type": "NumericalDimension",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "editPersonSkillAssociationsModalContent_SkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "editPersonSkillAssociationsModalContent_SkillInlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "dimension",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "editSkillAssociationModal_SkillFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "numericalSkillTooltipIcon_SkillFragment"
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "Skills(first:200)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "editPersonSkillAssociationsModalContent_SkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "dimension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isSkillDimensionInterface"
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "Skills(first:200)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fae182d889d261c7d2fd903ba992494b",
    "id": null,
    "metadata": {},
    "name": "editPersonSkillAssociationsModalContent_SkillsQuery",
    "operationKind": "query",
    "text": "query editPersonSkillAssociationsModalContent_SkillsQuery {\n  Skills {\n    Skills(first: 200) {\n      edges {\n        node {\n          ...editPersonSkillAssociationsModalContent_SkillInlineFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editBinarySkillAssociationModal_SkillFragment on Skill {\n  id\n  name\n}\n\nfragment editNumericalSkillAssociationModal_SkillFragment on Skill {\n  id\n  name\n}\n\nfragment editPersonSkillAssociationsModalContent_SkillInlineFragment on Skill {\n  id\n  name\n  description\n  skillCategory {\n    id\n    name\n  }\n  dimension {\n    __typename\n    kind\n    ... on NumericalDimension {\n      kind\n      dimensionCount\n      dimensionExplanations\n    }\n    ... on BinaryDimension {\n      kind\n    }\n  }\n  ...editSkillAssociationModal_SkillFragment\n  ...numericalSkillTooltipIcon_SkillFragment\n}\n\nfragment editSkillAssociationModal_SkillFragment on Skill {\n  name\n  dimension {\n    __typename\n    kind\n    ... on NumericalDimension {\n      kind\n    }\n    __isSkillDimensionInterface: __typename\n  }\n  ...editBinarySkillAssociationModal_SkillFragment\n  ...editNumericalSkillAssociationModal_SkillFragment\n}\n\nfragment numericalSkillTooltipIcon_SkillFragment on Skill {\n  dimension {\n    __typename\n    kind\n    ... on NumericalDimension {\n      dimensionExplanations\n      dimensionCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fefc71cd866d0d8417d9a7c0811da439";

export default node;
