/**
 * @generated SignedSource<<161f6715fefac31478e13825873fc1bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangePasswordInput = {
  clientMutationId?: string | null;
  newPassword: string;
};
export type ChangePasswordForm_ChangePasswordMutation$variables = {
  input: ChangePasswordInput;
};
export type ChangePasswordForm_ChangePasswordMutation$data = {
  readonly Auth: {
    readonly changePassword: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ChangePasswordForm_ChangePasswordMutation = {
  response: ChangePasswordForm_ChangePasswordMutation$data;
  variables: ChangePasswordForm_ChangePasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ChangePasswordPayload",
        "kind": "LinkedField",
        "name": "changePassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordForm_ChangePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePasswordForm_ChangePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7db33fe6e2675f1871d5f3fe5ba5fda2",
    "id": null,
    "metadata": {},
    "name": "ChangePasswordForm_ChangePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePasswordForm_ChangePasswordMutation(\n  $input: ChangePasswordInput!\n) {\n  Auth {\n    changePassword(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a3a404b0b8e062c01cc584b9e14f2fa";

export default node;
