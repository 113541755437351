/**
 * @generated SignedSource<<1d52c9493bbe2969ce71bb3b5ba10c87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetAssignmentRoleSortOrdersInput = {
  assignmentRoleIds: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type assignmentRolesTable_SetAssignmentRoleSortOrderMutation$variables = {
  input: SetAssignmentRoleSortOrdersInput;
};
export type assignmentRolesTable_SetAssignmentRoleSortOrderMutation$data = {
  readonly Assignment: {
    readonly setAssignmentRoleSortOrders: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"editAssignmentRoleButton_AssignmentRoleFragment">;
        };
      }>;
    } | null;
  };
};
export type assignmentRolesTable_SetAssignmentRoleSortOrderMutation = {
  response: assignmentRolesTable_SetAssignmentRoleSortOrderMutation$data;
  variables: assignmentRolesTable_SetAssignmentRoleSortOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignmentRolesTable_SetAssignmentRoleSortOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAssignmentRoleSortOrdersPayload",
            "kind": "LinkedField",
            "name": "setAssignmentRoleSortOrders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editAssignmentRoleButton_AssignmentRoleFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignmentRolesTable_SetAssignmentRoleSortOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAssignmentRoleSortOrdersPayload",
            "kind": "LinkedField",
            "name": "setAssignmentRoleSortOrders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumberOfProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utilizationProjectionCapInMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsFullyAllocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsOverallocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useEndDateOfLastAssignmentOverProjectionCap",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edab7d8d85904c9d64fd899fdbe82ee7",
    "id": null,
    "metadata": {},
    "name": "assignmentRolesTable_SetAssignmentRoleSortOrderMutation",
    "operationKind": "mutation",
    "text": "mutation assignmentRolesTable_SetAssignmentRoleSortOrderMutation(\n  $input: SetAssignmentRoleSortOrdersInput!\n) {\n  Assignment {\n    setAssignmentRoleSortOrders(input: $input) {\n      edges {\n        node {\n          ...editAssignmentRoleButton_AssignmentRoleFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editAssignmentRoleButton_AssignmentRoleFragment on AssignmentRole {\n  id\n  name\n  sortOrder\n  maxNumberOfProjects\n  utilizationProjectionCapInMonths\n  countAsFullyAllocatedAtPercentage\n  countAsOverallocatedAtPercentage\n  useEndDateOfLastAssignmentOverProjectionCap\n}\n"
  }
};
})();

(node as any).hash = "d707bc1c64f56467adf3573b94b6e270";

export default node;
