/**
 * @generated SignedSource<<ffaec7f7114c99f8c094e11992e83981>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeNameInput = {
  clientMutationId?: string | null;
  newName: string;
};
export type changeNameForm_ChangeNameMutation$variables = {
  input: ChangeNameInput;
};
export type changeNameForm_ChangeNameMutation$data = {
  readonly Auth: {
    readonly changeName: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type changeNameForm_ChangeNameMutation = {
  response: changeNameForm_ChangeNameMutation$data;
  variables: changeNameForm_ChangeNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ChangeNamePayload",
        "kind": "LinkedField",
        "name": "changeName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "changeNameForm_ChangeNameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "changeNameForm_ChangeNameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4975cb6b9322e38e59069cc6eee16eae",
    "id": null,
    "metadata": {},
    "name": "changeNameForm_ChangeNameMutation",
    "operationKind": "mutation",
    "text": "mutation changeNameForm_ChangeNameMutation(\n  $input: ChangeNameInput!\n) {\n  Auth {\n    changeName(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1698fd739fa78c157a5d2330da64124";

export default node;
