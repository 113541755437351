import { Form } from "@thekeytechnology/framework-react-components";
import React, { useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AuthScreenBase } from "@components/ui/AuthScreenBase";
import { DefaultOTPFieldComponent } from "@components/ui/DefaultTextInput";
import { type activate2fa_Activate2FAMutation } from "@relay/activate2fa_Activate2FAMutation.graphql";
import { type activate2fa_Query } from "@relay/activate2fa_Query.graphql";
import { ACTIVATE_2FA_MUTATION, QUERY } from "@screens/activate-2fa/activate-2fa.graphql";
import { type Activate2FAProps } from "@screens/activate-2fa/activate-2fa.types";
import { PERSONAL_DATA_SCREEN_PATH } from "@screens/personal-data";

export const ACTIVATE_2FA_SCREEN = "/settings/personal-data/activate-2fa";
export const Activate2FAScreen = ({}: Activate2FAProps) => {
	const query = useLazyLoadQuery<activate2fa_Query>(QUERY, {});
	const twoFactorAuthFragment = query.Viewer.Auth.twoFactorAuthToken;
	const navigate = useNavigate();
	const handleOnContinue = () => {
		navigate(PERSONAL_DATA_SCREEN_PATH);
	};
	const [activate] = useMutation<activate2fa_Activate2FAMutation>(ACTIVATE_2FA_MUTATION);
	const [otp, setOtp] = useState("");

	const handleOnComplete = (otp: string) => {
		activate({
			variables: {
				input: {
					otp,
				},
			},
			onCompleted: () => {
				handleOnContinue();
			},
		});
	};
	return (
		<AuthScreenBase>
			<Form className={"px-6 mb-6"}>
				<h1 className="text-center mb-6 text">Setup 2FA</h1>
				<span className={"text-center text"}>
					Please scan the QR code with your authenticator app of choice.
				</span>
				<img
					alt={"qr code"}
					src={twoFactorAuthFragment?.data.qrCodeUri}
					className={"w-full center"}
				/>
				<span className={"text-center text"}>
					Activate 2FA by providing the one time password generated by your authenticator
					app.
				</span>
				<br />
				<DefaultOTPFieldComponent
					onComplete={handleOnComplete}
					fieldValue={otp}
					updateField={(e) => {
						setOtp(e ?? "");
					}}
					length={6}
				/>
			</Form>
		</AuthScreenBase>
	);
};
