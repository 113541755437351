/**
 * @generated SignedSource<<af869d838f5893c62ea93c09a7de2057>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSkillInput = {
  clientMutationId?: string | null;
  data: SkillDataInput;
};
export type SkillDataInput = {
  description?: string | null;
  dimension: SkillDimension;
  name: string;
  skillCategoryId: string;
};
export type SkillDimension = {
  binary?: BinaryDimensionInput | null;
  numerical?: NumericalDimensionInput | null;
};
export type NumericalDimensionInput = {
  dimensionCount: number;
  dimensionExplanations: ReadonlyArray<string>;
  kind: string;
};
export type BinaryDimensionInput = {
  kind: string;
};
export type editSkillButton_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateSkillInput;
};
export type editSkillButton_CreateMutation$data = {
  readonly Skills: {
    readonly createSkill: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editSkillButton_SkillFragment">;
        };
      };
    } | null;
  };
};
export type editSkillButton_CreateMutation = {
  response: editSkillButton_CreateMutation$data;
  variables: editSkillButton_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editSkillButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateSkillPayload",
            "kind": "LinkedField",
            "name": "createSkill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editSkillButton_SkillFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editSkillButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateSkillPayload",
            "kind": "LinkedField",
            "name": "createSkill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillCategory",
                        "kind": "LinkedField",
                        "name": "skillCategory",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "dimension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dimensionCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dimensionExplanations",
                                "storageKey": null
                              }
                            ],
                            "type": "NumericalDimension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee9cc132e7be7cff7839d45618796a9e",
    "id": null,
    "metadata": {},
    "name": "editSkillButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation editSkillButton_CreateMutation(\n  $input: CreateSkillInput!\n) {\n  Skills {\n    createSkill(input: $input) {\n      edge {\n        node {\n          id\n          ...editSkillButton_SkillFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editSkillButton_SkillFragment on Skill {\n  id\n  name\n  skillCategory {\n    id\n  }\n  description\n  dimension {\n    __typename\n    kind\n    ... on NumericalDimension {\n      kind\n      dimensionCount\n      dimensionExplanations\n    }\n    ... on BinaryDimension {\n      kind\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ac07ff89cd69b9d456a004278c6b5f9";

export default node;
