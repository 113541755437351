/**
 * @generated SignedSource<<0a8c11d92f3b2f38b865a11046129585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckScenarioPermissions_ScenarioFragment$data = {
  readonly isMasterPlan: boolean;
  readonly " $fragmentType": "CheckScenarioPermissions_ScenarioFragment";
};
export type CheckScenarioPermissions_ScenarioFragment$key = {
  readonly " $data"?: CheckScenarioPermissions_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckScenarioPermissions_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckScenarioPermissions_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMasterPlan",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "68605b68e3cb82ae3949cccee0bbc22c";

export default node;
