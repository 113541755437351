/**
 * @generated SignedSource<<3972961f5a7bf2988a8c5c0e013d6068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillMultiSelect_Query$variables = {
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
};
export type skillMultiSelect_Query$data = {
  readonly Skills: {
    readonly Skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"skillMultiSelect_SkillFragment">;
        };
      } | null> | null;
    };
  };
};
export type skillMultiSelect_Query = {
  response: skillMultiSelect_Query$data;
  variables: skillMultiSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v2 = [
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 250
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "skillMultiSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "skillMultiSelect_SkillFragment",
                        "selections": (v3/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "skillMultiSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsQueries",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "Skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88a429ea6a7bad64909b742b33365ac4",
    "id": null,
    "metadata": {},
    "name": "skillMultiSelect_Query",
    "operationKind": "query",
    "text": "query skillMultiSelect_Query(\n  $filterByName: String\n  $alwaysIncludeIds: [ID!]\n) {\n  Skills {\n    Skills(first: 250, filterByName: $filterByName, alwaysIncludeIds: $alwaysIncludeIds) {\n      edges {\n        node {\n          ...skillMultiSelect_SkillFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment skillMultiSelect_SkillFragment on Skill {\n  name\n  id\n}\n"
  }
};
})();

(node as any).hash = "bf534776f8237b8031c464a0961334c1";

export default node;
