/**
 * @generated SignedSource<<b3315e3e05043f9a50a8a546ad93efa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountsAdminTable_Query$variables = {
  first?: number | null;
};
export type AccountsAdminTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountsAdminTable_AccountsListFragment">;
};
export type AccountsAdminTable_Query = {
  response: AccountsAdminTable_Query$data;
  variables: AccountsAdminTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsAdminTable_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountsAdminTable_AccountsListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountsAdminTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "AccountsAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroupAssociation",
                            "kind": "LinkedField",
                            "name": "groupAssociations",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccountGroup",
                                "kind": "LinkedField",
                                "name": "group",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "registeredAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "AccountsTable_AccountsAdmin",
                "kind": "LinkedHandle",
                "name": "AccountsAdmin"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3040595e21cf6e6479cbc66ec9da6304",
    "id": null,
    "metadata": {},
    "name": "AccountsAdminTable_Query",
    "operationKind": "query",
    "text": "query AccountsAdminTable_Query(\n  $first: Int\n) {\n  ...AccountsAdminTable_AccountsListFragment_3ASum4\n}\n\nfragment AccountsAdminTable_AccountInlineFragment on Account {\n  id\n  name\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n  registeredAt\n  ...EditAccountButton_AccountFragment\n  ...EditUsersInAccountAdminButton_AccountFragment\n  ...AnonymizeAccountButton_AccountFragment\n  ...ChangeAccountGroupsAdminButton_AccountFragment\n}\n\nfragment AccountsAdminTable_AccountsListFragment_3ASum4 on Query {\n  Admin {\n    Management {\n      AccountsAdmin(first: $first) {\n        edges {\n          node {\n            ...AccountsAdminTable_AccountInlineFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment AnonymizeAccountButton_AccountFragment on Account {\n  id\n  name\n}\n\nfragment ChangeAccountGroupsAdminButton_AccountFragment on Account {\n  ...ChangeAccountGroupsAdminModal_AccountFragment\n}\n\nfragment ChangeAccountGroupsAdminModal_AccountFragment on Account {\n  id\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n}\n\nfragment EditAccountButton_AccountFragment on Account {\n  ...EditAccountModal_AccountFragment\n}\n\nfragment EditAccountModal_AccountFragment on Account {\n  id\n  name\n  registeredAt\n}\n\nfragment EditUsersInAccountAdminButton_AccountFragment on Account {\n  ...EditUsersInAccountAdminModal_AccountFragment\n}\n\nfragment EditUsersInAccountAdminModal_AccountFragment on Account {\n  id\n  name\n  registeredAt\n}\n"
  }
};
})();

(node as any).hash = "c9bbac572f963d9f1381cd0bb810e505";

export default node;
