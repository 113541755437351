import { TabPanel } from "primereact/tabview";
import { useDispatch, useSelector } from "react-redux";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { TkTabView } from "@components/ui/TkTabView";
import { type ProjectStagesTabs_ProjectStages$key } from "@relay/ProjectStagesTabs_ProjectStages.graphql";
import { type ProjectStagesTabProps } from "@screens/project-view/parts/project-stages-tab/project-stages-tab.types";
import { QUERY_FRAGMENT } from "./project-stages-tab.graphql";
import {
	selectScenarioProjectFilters,
	setPage,
	setProjectViewProjectFilters,
} from "../../../../redux/ProjectViewSlice";

export const ProjectStagesTabs = ({ projectStagesFragmentRef }: ProjectStagesTabProps) => {
	const projectStages =
		useFragment<ProjectStagesTabs_ProjectStages$key>(
			QUERY_FRAGMENT,
			projectStagesFragmentRef,
		).Project.ProjectStages.edges?.map((e) => e!.node!) || [];
	const projectFilters = useSelector(selectScenarioProjectFilters);

	const dispatch = useDispatch();

	return (
		<ProjectListTabView
			className="mr-2 overflow-x-auto flex-1"
			activeIndex={
				projectStages?.findIndex((s) => projectFilters.filterByStage === s.id) + 1 || 0
			}
			onTabChange={(e) => {
				dispatch(
					setProjectViewProjectFilters({
						...projectFilters,
						filterByStage: e.index === 0 ? undefined : projectStages[e.index - 1]?.id,
					}),
				);
				dispatch(setPage(0));
			}}
		>
			<TabPanel header="All projects"></TabPanel>

			{projectStages.map((stage) => {
				return <TabPanel key={stage.id} header={stage.name}></TabPanel>;
			})}
		</ProjectListTabView>
	);
};

const ProjectListTabView = styled(TkTabView)`
	max-width: 40vw;
	@media screen and (min-width: 1920px) {
		max-width: 60vw;
	}
	.p-tabview-nav {
		background-color: transparent;
	}

	.p-unselectable-text:not(.p-tabview-selected) a {
		background-color: transparent;
	}

	.p-tabview-panels {
		background-color: transparent;
		display: none;
	}
`;
