/**
 * @generated SignedSource<<629740d102993d18fd2aeb356461f2cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment$data = {
  readonly data: {
    readonly credentials: ReadonlyArray<string>;
  };
  readonly id: string;
  readonly " $fragmentType": "recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment";
};
export type recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment$key = {
  readonly " $data"?: recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "recoveryCodesSlot_RecoveryCodeCredentialsInlineFragment"
};

(node as any).hash = "bfb8a93550b1dbd639414aea7a4f05fb";

export default node;
