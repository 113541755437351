/**
 * @generated SignedSource<<7d4ba6b382daa170f39a118852ece22e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StaffingTemplatesTable_Refetch$variables = {
  after?: string | null;
  filterByName?: string | null;
  first?: number | null;
};
export type StaffingTemplatesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StaffingTemplatesTable_StaffingTemplateListFragment">;
};
export type StaffingTemplatesTable_Refetch = {
  response: StaffingTemplatesTable_Refetch$data;
  variables: StaffingTemplatesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffingTemplatesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "StaffingTemplatesTable_StaffingTemplateListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StaffingTemplatesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateQueries",
        "kind": "LinkedField",
        "name": "Template",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StaffingTemplateConnection",
            "kind": "LinkedField",
            "name": "StaffingTemplates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffingTemplateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StaffingTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRoleAssociation",
                        "kind": "LinkedField",
                        "name": "assignmentRoleAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentRole",
                            "kind": "LinkedField",
                            "name": "assignmentRole",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isExecutive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assignmentRoleRef",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "StaffingTemplatesTable_StaffingTemplates",
            "kind": "LinkedHandle",
            "name": "StaffingTemplates"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d90630a72bfb8ee61dd36ebbee39d0b4",
    "id": null,
    "metadata": {},
    "name": "StaffingTemplatesTable_Refetch",
    "operationKind": "query",
    "text": "query StaffingTemplatesTable_Refetch(\n  $after: String\n  $filterByName: String\n  $first: Int = 20\n) {\n  ...StaffingTemplatesTable_StaffingTemplateListFragment_3KLhx3\n}\n\nfragment EditStaffingTemplateButton_StaffingTemplateFragment on StaffingTemplate {\n  ...EditStaffingTemplateModal_StaffingTemplateFragment\n}\n\nfragment EditStaffingTemplateModal_StaffingTemplateFragment on StaffingTemplate {\n  id\n  name\n  assignmentRoleAssociations {\n    assignmentRoleRef\n    isExecutive\n  }\n}\n\nfragment StaffingTemplatesTable_StaffingTemplateListFragment_3KLhx3 on Query {\n  Template {\n    StaffingTemplates(first: $first, after: $after, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          assignmentRoleAssociations {\n            assignmentRole {\n              id\n              name\n            }\n            isExecutive\n          }\n          ...EditStaffingTemplateButton_StaffingTemplateFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "745ca2c1ee1eb23f1843609a4d5410bc";

export default node;
