/**
 * @generated SignedSource<<22af105036bda965a80d5754dcf30896>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditStaffingTemplateInput = {
  clientMutationId?: string | null;
  data: StaffingTemplateDataInput;
  staffingTemplateId: string;
};
export type StaffingTemplateDataInput = {
  assignmentRoleAssociations: ReadonlyArray<AssignmentRoleAssociationInput>;
  name: string;
};
export type AssignmentRoleAssociationInput = {
  assignmentRoleRef: string;
  isExecutive: boolean;
};
export type EditStaffingTemplateModal_EditMutation$variables = {
  input: EditStaffingTemplateInput;
};
export type EditStaffingTemplateModal_EditMutation$data = {
  readonly Template: {
    readonly editStaffingTemplate: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditStaffingTemplateButton_StaffingTemplateFragment">;
        };
      };
    } | null;
  };
};
export type EditStaffingTemplateModal_EditMutation = {
  response: EditStaffingTemplateModal_EditMutation$data;
  variables: EditStaffingTemplateModal_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditStaffingTemplateModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateMutations",
        "kind": "LinkedField",
        "name": "Template",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditStaffingTemplatePayload",
            "kind": "LinkedField",
            "name": "editStaffingTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffingTemplateEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StaffingTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditStaffingTemplateButton_StaffingTemplateFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditStaffingTemplateModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateMutations",
        "kind": "LinkedField",
        "name": "Template",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditStaffingTemplatePayload",
            "kind": "LinkedField",
            "name": "editStaffingTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffingTemplateEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StaffingTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRoleAssociation",
                        "kind": "LinkedField",
                        "name": "assignmentRoleAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assignmentRoleRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isExecutive",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdf241db80c2447b65dbf0a040b3bd41",
    "id": null,
    "metadata": {},
    "name": "EditStaffingTemplateModal_EditMutation",
    "operationKind": "mutation",
    "text": "mutation EditStaffingTemplateModal_EditMutation(\n  $input: EditStaffingTemplateInput!\n) {\n  Template {\n    editStaffingTemplate(input: $input) {\n      edge {\n        node {\n          id\n          ...EditStaffingTemplateButton_StaffingTemplateFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditStaffingTemplateButton_StaffingTemplateFragment on StaffingTemplate {\n  ...EditStaffingTemplateModal_StaffingTemplateFragment\n}\n\nfragment EditStaffingTemplateModal_StaffingTemplateFragment on StaffingTemplate {\n  id\n  name\n  assignmentRoleAssociations {\n    assignmentRoleRef\n    isExecutive\n  }\n}\n"
  }
};
})();

(node as any).hash = "59fef0a42bf9e4d08bc88a95daa1fb83";

export default node;
