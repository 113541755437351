/**
 * @generated SignedSource<<f135994742c13b01a094cb5c7ab69525>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TestAssessmentFinalizationInput = {
  assessmentId: string;
  clientMutationId?: string | null;
};
export type skillAssessmentsTable_TestAssessmentFinalizationMutation$variables = {
  input: TestAssessmentFinalizationInput;
};
export type skillAssessmentsTable_TestAssessmentFinalizationMutation$data = {
  readonly Admin: {
    readonly Assessment: {
      readonly testAssessmentFinalization: {
        readonly assessment: {
          readonly id: string;
          readonly status: {
            readonly file?: {
              readonly url: string | null;
            } | null;
          };
        };
      } | null;
    };
  };
};
export type skillAssessmentsTable_TestAssessmentFinalizationMutation = {
  response: skillAssessmentsTable_TestAssessmentFinalizationMutation$data;
  variables: skillAssessmentsTable_TestAssessmentFinalizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessmentsTable_TestAssessmentFinalizationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssessmentAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Assessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "TestAssessmentFinalizationPayload",
                "kind": "LinkedField",
                "name": "testAssessmentFinalization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assessment",
                    "kind": "LinkedField",
                    "name": "assessment",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "PdfGenerated",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessmentsTable_TestAssessmentFinalizationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssessmentAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Assessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "TestAssessmentFinalizationPayload",
                "kind": "LinkedField",
                "name": "testAssessmentFinalization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assessment",
                    "kind": "LinkedField",
                    "name": "assessment",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "PdfGenerated",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d4d9915349257052bc8dd90df510191",
    "id": null,
    "metadata": {},
    "name": "skillAssessmentsTable_TestAssessmentFinalizationMutation",
    "operationKind": "mutation",
    "text": "mutation skillAssessmentsTable_TestAssessmentFinalizationMutation(\n  $input: TestAssessmentFinalizationInput!\n) {\n  Admin {\n    Assessment {\n      testAssessmentFinalization(input: $input) {\n        assessment {\n          id\n          status {\n            __typename\n            ... on PdfGenerated {\n              file {\n                url\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4c34210b08947cc9467dbb52c805723";

export default node;
