/**
 * @generated SignedSource<<ead2d7fb4558ab36ea7449ddc6e10e50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DivisionSelect_DivisionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "DivisionSelect_DivisionFragment";
};
export type DivisionSelect_DivisionFragment$key = {
  readonly " $data"?: DivisionSelect_DivisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DivisionSelect_DivisionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DivisionSelect_DivisionFragment"
};

(node as any).hash = "22e714ea424f8a35fb5c9bf26d0a35ff";

export default node;
