/**
 * @generated SignedSource<<0e56c8a7c97b467ab7dfbbc9ca5d1951>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "AccountPermission_Auth_DriveTimesEnabled" | "AccountPermission_Auth_Field" | "AccountPermission_Auth_GapDaysEnabled" | "AccountPermission_Auth_PreConIntegration" | "AccountPermission_Auth_Reports" | "AccountPermission_Rand_DataWarehouseIntegration" | "AccountPermission_System_Root" | "UserInAccountPermission_Assessment_Edit" | "UserInAccountPermission_AssignmentRole_Edit" | "UserInAccountPermission_Division_Edit" | "UserInAccountPermission_Files_Delete" | "UserInAccountPermission_Management_Management" | "UserInAccountPermission_Maps_Read" | "UserInAccountPermission_Precon_Edit" | "UserInAccountPermission_PrivateData_Read" | "UserInAccountPermission_ProjectStages_Edit" | "UserInAccountPermission_Project_Edit" | "UserInAccountPermission_Region_Edit" | "UserInAccountPermission_Salary_Read" | "UserInAccountPermission_Scenario_Budgets" | "UserInAccountPermission_Scenario_Edit" | "UserInAccountPermission_Scenario_Masterplan" | "UserInAccountPermission_Skills_Edit" | "UserInAccountPermission_Skills_Read" | "UserInAccountPermission_Staff_Edit" | "UserInAccountPermission_System_Owner" | "UserInAccountPermission_Tasks_Tasks" | "UserInAccountPermission_Templates_Edit" | "UserInAccountPermission_Utilization_Read";
import { FragmentRefs } from "relay-runtime";
export type editUserInAccountGroupModal_GroupFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly permissions: ReadonlyArray<Permission>;
  readonly " $fragmentType": "editUserInAccountGroupModal_GroupFragment";
};
export type editUserInAccountGroupModal_GroupFragment$key = {
  readonly " $data"?: editUserInAccountGroupModal_GroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editUserInAccountGroupModal_GroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editUserInAccountGroupModal_GroupFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "UserInAccountGroup",
  "abstractKey": null
};

(node as any).hash = "ccae6c5bf1ca5264f9cb4b2ce36af772";

export default node;
