/**
 * @generated SignedSource<<01816f163ad50110baeca37afcd82f05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type scenarioSelectField_Query$variables = {
  after?: string | null;
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  before?: string | null;
  excludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
  last?: number | null;
  onlyMaster?: boolean | null;
  onlyMine?: boolean | null;
};
export type scenarioSelectField_Query$data = {
  readonly Scenario: {
    readonly Scenarios: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"scenarioSelectField_ScenarioInlineFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
};
export type scenarioSelectField_Query = {
  response: scenarioSelectField_Query$data;
  variables: scenarioSelectField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyMaster"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyMine"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "excludeIds",
    "variableName": "excludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "onlyMaster",
    "variableName": "onlyMaster"
  },
  {
    "kind": "Variable",
    "name": "onlyShowMine",
    "variableName": "onlyMine"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isMasterPlan",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPublic",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "scenarioSelectField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioQueries",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "ScenariosConnection",
            "kind": "LinkedField",
            "name": "Scenarios",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "scenarioSelectField_ScenarioInlineFragment",
                        "selections": (v12/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "scenarioSelectField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioQueries",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "ScenariosConnection",
            "kind": "LinkedField",
            "name": "Scenarios",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67fac22f822aa2090c92aa1d433ad3ae",
    "id": null,
    "metadata": {},
    "name": "scenarioSelectField_Query",
    "operationKind": "query",
    "text": "query scenarioSelectField_Query(\n  $last: Int\n  $after: String\n  $before: String\n  $filterByName: String\n  $onlyMaster: Boolean\n  $onlyMine: Boolean\n  $alwaysIncludeIds: [ID!]\n  $excludeIds: [ID!]\n) {\n  Scenario {\n    Scenarios(first: 20, last: $last, after: $after, before: $before, alwaysIncludeIds: $alwaysIncludeIds, excludeIds: $excludeIds, filterByName: $filterByName, onlyMaster: $onlyMaster, onlyShowMine: $onlyMine) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        cursor\n        node {\n          id\n          ...scenarioSelectField_ScenarioInlineFragment\n        }\n      }\n    }\n  }\n}\n\nfragment scenarioSelectField_ScenarioInlineFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  isPublic\n}\n"
  }
};
})();

(node as any).hash = "8a46b96bab39125bd1d9c7c04fcef04b";

export default node;
