import {Form} from "@thekeytechnology/framework-react-components";
import {graphql} from "babel-plugin-relay/macro";
import {useFormik} from "formik";
import React from "react";
import {useDispatch} from "react-redux";
import {useFragment, useMutation} from "react-relay";
import * as Yup from "yup";
import {updateExtension} from "@redux/CurrentUserSlice";
import {
	type ChangeShowBudgetForm_ChangeLogoMutation
} from "../../__generated__/ChangeShowBudgetForm_ChangeLogoMutation.graphql";
import {type ChangeShowBudgetForm_CurrentUser$key} from "../../__generated__/ChangeShowBudgetForm_CurrentUser.graphql";
import {DefaultSwitchComponent} from "../ui/DefaultTextInput";
import {TkButton} from "../ui/TkButton";
import {ValidatedField} from "../ui/ValidatedField";

const FRAGMENT = graphql`
	fragment ChangeShowBudgetForm_CurrentUser on Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on HarkinsUserExtensionAndId {
								showBudget
							}
						}
					}
				}
			}
		}
	}
`;

const MUTATION = graphql`
	mutation ChangeShowBudgetForm_ChangeLogoMutation($input: SetShowBudgetInput!) {
		Admin {
			Auth {
				setShowBudget(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface FormState {
	showBudget?: boolean;
}

interface OwnProps {
	queryFragmentRef: ChangeShowBudgetForm_CurrentUser$key;
}

export const ChangeShowBudgetForm = ({ queryFragmentRef }: OwnProps) => {
	const query = useFragment<ChangeShowBudgetForm_CurrentUser$key>(FRAGMENT, queryFragmentRef);
	const [changeLogo, isChanging] = useMutation<ChangeShowBudgetForm_ChangeLogoMutation>(MUTATION);
	const dispatch = useDispatch();
	const formik = useFormik<FormState>({
		initialValues: {
			showBudget: query.Viewer.Auth.currentUser?.user.extension.showBudget,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({}),
		onSubmit: (data) => {
			changeLogo({
				variables: {
					input: {
						showBudget: data.showBudget ?? false,
					},
				},
				onCompleted: () => {
					dispatch(updateExtension({ showBudget: data.showBudget ?? false }));
					formik.setSubmitting(false);
					window.location.reload();
				},
			});
		},
	});

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<FormState, boolean>
				className="mb-4"
				name={"showBudget"}
				label={"Show budget"}
				required={true}
				formikConfig={formik}
				component={DefaultSwitchComponent}
			/>

			<TkButton
				disabled={isChanging || formik.isSubmitting}
				type="submit"
				label={"Save"}
				className="p-mt-2"
			/>
		</Form>
	);
};
