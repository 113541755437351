/**
 * @generated SignedSource<<ff804cd9a13b4e1a776f380f42be2012>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentCard_ScenarioFragment$data = {
  readonly budget: {
    readonly projectBudgets: ReadonlyArray<{
      readonly assignmentBudgets: ReadonlyArray<{
        readonly assignmentRef: string;
        readonly budgetedCost: number;
        readonly months: number;
        readonly utilizedCost: number;
      }>;
      readonly projectRef: string;
    }>;
  };
  readonly utilization: {
    readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_ScenarioUtilizationFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CheckScenarioPermissions_ScenarioFragment" | "personDetailsButton_ScenarioFragment">;
  readonly " $fragmentType": "AssignmentCard_ScenarioFragment";
};
export type AssignmentCard_ScenarioFragment$key = {
  readonly " $data"?: AssignmentCard_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentCard_ScenarioFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckScenarioPermissions_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personDetailsButton_ScenarioFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioBudget",
      "kind": "LinkedField",
      "name": "budget",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectBudget",
          "kind": "LinkedField",
          "name": "projectBudgets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectRef",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentBudget",
              "kind": "LinkedField",
              "name": "assignmentBudgets",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignmentRef",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "budgetedCost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "utilizedCost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "months",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioUtilization",
      "kind": "LinkedField",
      "name": "utilization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "personDetailsButton_ScenarioUtilizationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "2c790c35e9ec0f8f09b8df2c42c8d919";

export default node;
