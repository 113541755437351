/**
 * @generated SignedSource<<50de485e88c6317cf015a91b0698124f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editProjectStageModal_ProjectStageFragment$data = {
  readonly color: string | null;
  readonly id: string;
  readonly name: string;
  readonly reverseProjectOrderInReports: boolean;
  readonly sortOrder: number | null;
  readonly " $fragmentType": "editProjectStageModal_ProjectStageFragment";
};
export type editProjectStageModal_ProjectStageFragment$key = {
  readonly " $data"?: editProjectStageModal_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editProjectStageModal_ProjectStageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editProjectStageModal_ProjectStageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reverseProjectOrderInReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "type": "ProjectStage",
  "abstractKey": null
};

(node as any).hash = "7320199a770bebf1d80eab4d4deaa24f";

export default node;
