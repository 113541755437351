/**
 * @generated SignedSource<<a7b67489a51837ba8d6e69c4248d528f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectDetailsButton_ProjectInScenario$data = {
  readonly project: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "projectDetailsButton_ProjectInScenario";
};
export type projectDetailsButton_ProjectInScenario$key = {
  readonly " $data"?: projectDetailsButton_ProjectInScenario$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectDetailsButton_ProjectInScenario">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectDetailsButton_ProjectInScenario",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};

(node as any).hash = "817993d97f7648aa738a0170c3682fed";

export default node;
