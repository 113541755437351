/**
 * @generated SignedSource<<db4eaba0484668b72adf08717764b0cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneScenarioButton_ScenarioFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "CloneScenarioButton_ScenarioFragment";
};
export type CloneScenarioButton_ScenarioFragment$key = {
  readonly " $data"?: CloneScenarioButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneScenarioButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneScenarioButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "a2b66625163c5e061611552e8eca3c49";

export default node;
