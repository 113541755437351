/**
 * @generated SignedSource<<f76826fa23531d65d4023bd98c6c02b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditProjectStageInput = {
  clientMutationId?: string | null;
  data: ProjectStageDataInput;
  id: string;
};
export type ProjectStageDataInput = {
  color?: string | null;
  name: string;
  reverseProjectOrderInReports: boolean;
  sortOrder?: number | null;
};
export type editProjectStageModal_EditMutation$variables = {
  input: EditProjectStageInput;
};
export type editProjectStageModal_EditMutation$data = {
  readonly Project: {
    readonly editProjectStage: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editProjectStageButton_ProjectStageFragment">;
        };
      };
    } | null;
  };
};
export type editProjectStageModal_EditMutation = {
  response: editProjectStageModal_EditMutation$data;
  variables: editProjectStageModal_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editProjectStageModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditProjectStagePayload",
            "kind": "LinkedField",
            "name": "editProjectStage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStagesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectStage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editProjectStageButton_ProjectStageFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editProjectStageModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditProjectStagePayload",
            "kind": "LinkedField",
            "name": "editProjectStage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStagesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectStage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reverseProjectOrderInReports",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "daa127a34d288e101ba36136474bbd07",
    "id": null,
    "metadata": {},
    "name": "editProjectStageModal_EditMutation",
    "operationKind": "mutation",
    "text": "mutation editProjectStageModal_EditMutation(\n  $input: EditProjectStageInput!\n) {\n  Project {\n    editProjectStage(input: $input) {\n      edge {\n        node {\n          id\n          ...editProjectStageButton_ProjectStageFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editProjectStageButton_ProjectStageFragment on ProjectStage {\n  ...editProjectStageModal_ProjectStageFragment\n}\n\nfragment editProjectStageModal_ProjectStageFragment on ProjectStage {\n  id\n  name\n  reverseProjectOrderInReports\n  sortOrder\n  color\n}\n"
  }
};
})();

(node as any).hash = "42999a2cc90a5a6bced05b846440b57d";

export default node;
