/**
 * @generated SignedSource<<af852cbfb5ef176383820deccae5d3d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ViewType = "ProjectView" | "StaffView";
import { FragmentRefs } from "relay-runtime";
export type FilterViewSelector_QueryFragment$data = {
  readonly Views: {
    readonly ViewOptions: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isDefault: boolean;
          readonly name: string;
          readonly url: string;
          readonly viewType: ViewType;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "FilterViewSelector_QueryFragment";
};
export type FilterViewSelector_QueryFragment$key = {
  readonly " $data"?: FilterViewSelector_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterViewSelector_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByViewType"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "Views",
          "ViewOptions"
        ]
      }
    ]
  },
  "name": "FilterViewSelector_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewsQueries",
      "kind": "LinkedField",
      "name": "Views",
      "plural": false,
      "selections": [
        {
          "alias": "ViewOptions",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByViewType",
              "variableName": "filterByViewType"
            }
          ],
          "concreteType": "ViewOptionsConnection",
          "kind": "LinkedField",
          "name": "__FilterViewSelector_ViewOptions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ViewOptionsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ViewOptions",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isDefault",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e3681f7349ea1faee4529a629bbee880";

export default node;
