/**
 * @generated SignedSource<<11b206a4dae0e153edc8ad99eef12e79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type skillsDisplay_SkillAssociationInlineFragment$data = {
  readonly data: {
    readonly skill: {
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly kind: SkillDimensionTypeEnum;
      };
      readonly id: string;
      readonly name: string;
      readonly skillCategory: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind?: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "skillsDisplay_SkillAssociationInlineFragment";
};
export type skillsDisplay_SkillAssociationInlineFragment$key = {
  readonly " $data"?: skillsDisplay_SkillAssociationInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillsDisplay_SkillAssociationInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "skillsDisplay_SkillAssociationInlineFragment"
};

(node as any).hash = "c103162fe2fc8b2e3e30656b52d302c9";

export default node;
