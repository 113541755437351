/**
 * @generated SignedSource<<07ed038c292c2a5b8c497cd61c0bf030>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAccountAdminInput = {
  clientMutationId?: string | null;
  id: string;
  name: string;
};
export type EditAccountModal_EditMutation$variables = {
  input: EditAccountAdminInput;
};
export type EditAccountModal_EditMutation$data = {
  readonly Admin: {
    readonly Management: {
      readonly editAccountAdmin: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"EditAccountButton_AccountFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditAccountModal_EditMutation = {
  response: EditAccountModal_EditMutation$data;
  variables: EditAccountModal_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAccountModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditAccountAdminPayload",
                "kind": "LinkedField",
                "name": "editAccountAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditAccountButton_AccountFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAccountModal_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditAccountAdminPayload",
                "kind": "LinkedField",
                "name": "editAccountAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "registeredAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ea85cddc1a310c5e88174e514a6ac4d",
    "id": null,
    "metadata": {},
    "name": "EditAccountModal_EditMutation",
    "operationKind": "mutation",
    "text": "mutation EditAccountModal_EditMutation(\n  $input: EditAccountAdminInput!\n) {\n  Admin {\n    Management {\n      editAccountAdmin(input: $input) {\n        edge {\n          node {\n            id\n            ...EditAccountButton_AccountFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditAccountButton_AccountFragment on Account {\n  ...EditAccountModal_AccountFragment\n}\n\nfragment EditAccountModal_AccountFragment on Account {\n  id\n  name\n  registeredAt\n}\n"
  }
};
})();

(node as any).hash = "c9b4cd14ff7bf7c1cd8e77db6adc38a7";

export default node;
