/**
 * @generated SignedSource<<b390ae5fd1da0adeb8df5bc9beb84a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportSkillsButton_ExportMutation$variables = {};
export type ExportSkillsButton_ExportMutation$data = {
  readonly Skills: {
    readonly exportSkills: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportSkillsButton_ExportMutation = {
  response: ExportSkillsButton_ExportMutation$data;
  variables: ExportSkillsButton_ExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportSkillsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportSkillsPayload",
            "kind": "LinkedField",
            "name": "exportSkills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportSkills(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExportSkillsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportSkillsPayload",
            "kind": "LinkedField",
            "name": "exportSkills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportSkills(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0a1e4c25c0847ff978ddb33dd4a03f3",
    "id": null,
    "metadata": {},
    "name": "ExportSkillsButton_ExportMutation",
    "operationKind": "mutation",
    "text": "mutation ExportSkillsButton_ExportMutation {\n  Skills {\n    exportSkills(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "880267485b186990a391ad547c9ed09c";

export default node;
