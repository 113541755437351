/**
 * @generated SignedSource<<c8764f50a510fc85225a5362592076c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsTable_ProjectsListFragment$data = {
  readonly Project: {
    readonly Projects: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProjectsTable_ProjectFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly " $fragmentType": "ProjectsTable_ProjectsListFragment";
};
export type ProjectsTable_ProjectsListFragment$key = {
  readonly " $data"?: ProjectsTable_ProjectsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsTable_ProjectsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Project",
  "Projects"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDivisions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByRegions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByStages"
    },
    {
      "defaultValue": 200,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProjectsTable_Refetch.graphql')
    }
  },
  "name": "ProjectsTable_ProjectsListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectQueries",
      "kind": "LinkedField",
      "name": "Project",
      "plural": false,
      "selections": [
        {
          "alias": "Projects",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByDivisions",
              "variableName": "filterByDivisions"
            },
            {
              "kind": "Variable",
              "name": "filterByName",
              "variableName": "filterByName"
            },
            {
              "kind": "Variable",
              "name": "filterByRegions",
              "variableName": "filterByRegions"
            },
            {
              "kind": "Variable",
              "name": "filterByStages",
              "variableName": "filterByStages"
            },
            {
              "kind": "Literal",
              "name": "showDeactivated",
              "value": true
            }
          ],
          "concreteType": "ProjectsConnection",
          "kind": "LinkedField",
          "name": "__ProjectsTable_Projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "ProjectsTable_ProjectFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDeactivated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "source",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Division",
                          "kind": "LinkedField",
                          "name": "division",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Region",
                          "kind": "LinkedField",
                          "name": "region",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectStage",
                          "kind": "LinkedField",
                          "name": "stage",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Address",
                          "kind": "LinkedField",
                          "name": "address",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "latitude",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "longitude",
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "GoogleMapsClickout_AddressFragment"
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "File",
                          "kind": "LinkedField",
                          "name": "avatar",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "editProjectButton_ProjectFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ChangeProjectActivationButton_ProjectFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "syncProjectFromDynamicsButton_ProjectFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "syncProjectFromRandButton_ProjectFragment"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1afcb4923674dd4ab18e2c77800c8968";

export default node;
