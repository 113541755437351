/**
 * @generated SignedSource<<245b2dcb7a87b1dfbbf525dd0aea3fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editBinarySkillAssociationModal_SkillAssociationFragment$data = {
  readonly data: {
    readonly skill: {
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly kind?: SkillDimensionTypeEnum;
      };
      readonly id: string;
      readonly name: string;
      readonly skillCategory: {
        readonly name: string;
      } | null;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind?: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "editBinarySkillAssociationModal_SkillAssociationFragment";
};
export type editBinarySkillAssociationModal_SkillAssociationFragment$key = {
  readonly " $data"?: editBinarySkillAssociationModal_SkillAssociationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editBinarySkillAssociationModal_SkillAssociationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBinarySkillAssociationModal_SkillAssociationFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssociationData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                }
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategory",
              "kind": "LinkedField",
              "name": "skillCategory",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "type": "BinaryDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionCount",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "type": "NumericalDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SkillAssociation",
  "abstractKey": null
};
})();

(node as any).hash = "87047340d11ea2ca61f3bc96f1bef66b";

export default node;
