/**
 * @generated SignedSource<<08685c70d61c9d9270fcc445a69ddb75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDivisionModal_DivisionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditDivisionModal_DivisionFragment";
};
export type EditDivisionModal_DivisionFragment$key = {
  readonly " $data"?: EditDivisionModal_DivisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDivisionModal_DivisionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDivisionModal_DivisionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Division",
  "abstractKey": null
};

(node as any).hash = "ff725d9f0feb5db6cddc51584b04336c";

export default node;
