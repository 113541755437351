/**
 * @generated SignedSource<<670379340cf87f1687529a072327ffa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type activate2fa_Query$variables = {};
export type activate2fa_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly twoFactorAuthToken: {
        readonly createdAt: string;
        readonly data: {
          readonly activatedAt: string | null;
          readonly isActivated: boolean;
          readonly qrCodeUri: string;
          readonly secretKey: string;
        };
        readonly id: string;
      } | null;
    };
  };
};
export type activate2fa_Query = {
  response: activate2fa_Query$data;
  variables: activate2fa_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthViewerSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TwoFactorAuthToken",
            "kind": "LinkedField",
            "name": "twoFactorAuthToken",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorAuthTokenData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qrCodeUri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActivated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secretKey",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "activate2fa_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "activate2fa_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f00c38cb656ea38f1243cd6eca0a940e",
    "id": null,
    "metadata": {},
    "name": "activate2fa_Query",
    "operationKind": "query",
    "text": "query activate2fa_Query {\n  Viewer {\n    Auth {\n      twoFactorAuthToken {\n        id\n        createdAt\n        data {\n          qrCodeUri\n          isActivated\n          activatedAt\n          secretKey\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "339c18059319ef3402f4bb09d42892a4";

export default node;
