/**
 * @generated SignedSource<<5f1a7b8a02527a86ac9d3a6036750df3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type allocationBarProvider_IntervalFragment$data = {
  readonly endDate: any | null;
  readonly fallsIntoCustomUtilizationWindow: boolean | null;
  readonly index: number;
  readonly startDate: any | null;
  readonly " $fragmentType": "allocationBarProvider_IntervalFragment";
};
export type allocationBarProvider_IntervalFragment$key = {
  readonly " $data"?: allocationBarProvider_IntervalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"allocationBarProvider_IntervalFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "allocationBarProvider_IntervalFragment"
};

(node as any).hash = "751f94989f57a13ceed88c4352d58f03";

export default node;
