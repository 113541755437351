/**
 * @generated SignedSource<<f23dc89f2efcb9b9ef232cdd8f29dd7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddUserToAccountAdminInput = {
  accountId: string;
  clientMutationId?: string | null;
  groupIds: ReadonlyArray<string>;
  userId: string;
};
export type createUserInAccountModal_AddMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddUserToAccountAdminInput;
};
export type createUserInAccountModal_AddMutation$data = {
  readonly Admin: {
    readonly Management: {
      readonly addUserToAccountAdmin: {
        readonly edge: {
          readonly node: {
            readonly groups: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly id: string;
            readonly user: {
              readonly email: string;
              readonly id: string;
              readonly name: string;
            };
            readonly " $fragmentSpreads": FragmentRefs<"ChangeUserGroupsAdminButton_UserInAccountFragment">;
          };
        };
      } | null;
    };
  };
};
export type createUserInAccountModal_AddMutation = {
  response: createUserInAccountModal_AddMutation$data;
  variables: createUserInAccountModal_AddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserInAccountGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createUserInAccountModal_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AddUserToAccountAdminPayload",
                "kind": "LinkedField",
                "name": "addUserToAccountAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ChangeUserGroupsAdminButton_UserInAccountFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createUserInAccountModal_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AddUserToAccountAdminPayload",
                "kind": "LinkedField",
                "name": "addUserToAccountAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb304032c2d236f6250bfe78320020a7",
    "id": null,
    "metadata": {},
    "name": "createUserInAccountModal_AddMutation",
    "operationKind": "mutation",
    "text": "mutation createUserInAccountModal_AddMutation(\n  $input: AddUserToAccountAdminInput!\n) {\n  Admin {\n    Management {\n      addUserToAccountAdmin(input: $input) {\n        edge {\n          node {\n            id\n            user {\n              id\n              email\n              name\n            }\n            groups {\n              id\n              name\n            }\n            ...ChangeUserGroupsAdminButton_UserInAccountFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ChangeUserGroupsAdminButton_UserInAccountFragment on UserInAccount {\n  ...ChangeUserGroupsAdminModal_UserInAccountFragment\n}\n\nfragment ChangeUserGroupsAdminModal_UserInAccountFragment on UserInAccount {\n  id\n  user {\n    id\n  }\n  groups {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "57577ee2d15647f2422a9eb79375031f";

export default node;
