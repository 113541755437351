/**
 * @generated SignedSource<<4dfa0a8f3a7d1e59fc851c513a77a93d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeMasterPlanInput = {
  clientMutationId?: string | null;
  scenarioId: string;
};
export type MakeMasterPlanButton_MakeMasterPlanMutation$variables = {
  input: ChangeMasterPlanInput;
};
export type MakeMasterPlanButton_MakeMasterPlanMutation$data = {
  readonly Scenario: {
    readonly changeMasterPlan: {
      readonly edge: {
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"MakeMasterPlanButton_ScenarioFragment">;
        };
      };
    } | null;
  };
};
export type MakeMasterPlanButton_MakeMasterPlanMutation = {
  response: MakeMasterPlanButton_MakeMasterPlanMutation$data;
  variables: MakeMasterPlanButton_MakeMasterPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MakeMasterPlanButton_MakeMasterPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChangeMasterPlanPayload",
            "kind": "LinkedField",
            "name": "changeMasterPlan",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MakeMasterPlanButton_ScenarioFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MakeMasterPlanButton_MakeMasterPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChangeMasterPlanPayload",
            "kind": "LinkedField",
            "name": "changeMasterPlan",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMasterPlan",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f88bcbf473cc3f0587250d6ba86e26cb",
    "id": null,
    "metadata": {},
    "name": "MakeMasterPlanButton_MakeMasterPlanMutation",
    "operationKind": "mutation",
    "text": "mutation MakeMasterPlanButton_MakeMasterPlanMutation(\n  $input: ChangeMasterPlanInput!\n) {\n  Scenario {\n    changeMasterPlan(input: $input) {\n      edge {\n        node {\n          ...MakeMasterPlanButton_ScenarioFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment MakeMasterPlanButton_ScenarioFragment on Scenario {\n  id\n  isMasterPlan\n}\n"
  }
};
})();

(node as any).hash = "d0c33c670c7b73630a73a2605a85fcd2";

export default node;
