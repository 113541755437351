import { type Permission } from "@relay/PermissionsField_Query.graphql";

export const PERMISSION_TRANSLATIONS: Record<Permission, string> = {
	AccountPermission_Auth_Field: "Field Integration",
	AccountPermission_Auth_Reports: "Reports Integration",
	UserInAccountPermission_Maps_Read: "Use Google Maps Integration",
	UserInAccountPermission_Utilization_Read: "Read Utilization",
	AccountPermission_Rand_DataWarehouseIntegration: "Rand Datamart Integration",
	UserInAccountPermission_PrivateData_Read: "Read private data (person's phone number, address)",
	AccountPermission_Auth_PreConIntegration: "Harkins PreCon Integration",
	UserInAccountPermission_Project_Edit: "Edit projects",
	UserInAccountPermission_Scenario_Edit: "Edit scenarios",
	UserInAccountPermission_Region_Edit: "Edit regions",
	UserInAccountPermission_Management_Management: "Manage Account",
	UserInAccountPermission_Division_Edit: "Edit division",
	UserInAccountPermission_Staff_Edit: "Edit staff",
	UserInAccountPermission_AssignmentRole_Edit: "Edit assignment roles",
	UserInAccountPermission_Scenario_Masterplan: "Can edit master plan",
	UserInAccountPermission_ProjectStages_Edit: "Edit project stages",
	UserInAccountPermission_Skills_Read: "Read skill categories & skills",
	UserInAccountPermission_Skills_Edit: "Edit skill categories & skills",
	UserInAccountPermission_Scenario_Budgets: "View budget and cost information",
	UserInAccountPermission_Templates_Edit: "Edit templates",
	AccountPermission_Auth_GapDaysEnabled: "Gap days enabled",
	AccountPermission_Auth_DriveTimesEnabled: "Access Drive Time Calculations",
	AccountPermission_System_Root: "Root",
	UserInAccountPermission_Assessment_Edit: "Edit assessments",
	UserInAccountPermission_Files_Delete: "Delete Files",
	UserInAccountPermission_Salary_Read: "Read salary information",
	UserInAccountPermission_System_Owner: "Owner",
	UserInAccountPermission_Tasks_Tasks: "View tasks",
	UserInAccountPermission_Precon_Edit: "Use Precon Integration",
};
