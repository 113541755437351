/**
 * @generated SignedSource<<545de2feb722317601580c26a7828a9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAccountButton_AccountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditAccountModal_AccountFragment">;
  readonly " $fragmentType": "EditAccountButton_AccountFragment";
};
export type EditAccountButton_AccountFragment$key = {
  readonly " $data"?: EditAccountButton_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAccountButton_AccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAccountButton_AccountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAccountModal_AccountFragment"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "8c66fbb7adf470688f28c60fcd10e370";

export default node;
