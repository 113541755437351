/**
 * @generated SignedSource<<b2aaa7799bdd55523b4da5abea728b38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type PeopleTable_PersonFragment$data = {
  readonly address: {
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"GoogleMapsClickout_AddressFragment">;
  } | null;
  readonly assignmentRole: {
    readonly name: string;
  } | null;
  readonly associatedWithDivisions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly associatedWithRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly avatar: {
    readonly url: string | null;
  } | null;
  readonly comment: string | null;
  readonly id: string;
  readonly isDeactivated: boolean;
  readonly name: string;
  readonly skills: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly data: {
          readonly value: {
            readonly hasSkill?: boolean;
            readonly kind?: SkillDimensionTypeEnum;
            readonly number?: number;
          };
        };
        readonly id: string;
      };
    } | null> | null;
  };
  readonly startDate: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"ChangePersonActivationButton_PersonFragment" | "EditPersonButton_PersonFragment" | "editPersonSkillAssociationsButton_PersonFragment">;
  readonly " $fragmentType": "PeopleTable_PersonFragment";
};
export type PeopleTable_PersonFragment$key = {
  readonly " $data"?: PeopleTable_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeopleTable_PersonFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "PeopleTable_PersonFragment"
};

(node as any).hash = "33e3962bf17a3426f6b70d38f824259f";

export default node;
