import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { ExportButton } from "@components/export-button";
import { type ExportPeopleButton_ExportMutation } from "../../__generated__/ExportPeopleButton_ExportMutation.graphql";

const MUTATION = graphql`
	mutation ExportPeopleButton_ExportMutation {
		Staff {
			exportPeople(input: {}) {
				file {
					url
				}
			}
		}
	}
`;

export const ExportPeopleButton = () => {
	const [doExport, isExporting] = useMutation<ExportPeopleButton_ExportMutation>(MUTATION);
	return (
		<ExportButton
			isExporting={isExporting}
			doExport={(success) => {
				doExport({
					variables: {},
					onCompleted: (response) => {
						success(response.Staff.exportPeople?.file?.url!);
					},
				});
			}}
		/>
	);
};
