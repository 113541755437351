/**
 * @generated SignedSource<<f7bca29784b1ba18414d3a414a3da188>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentRoleSelect_AssignmentRoleFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "AssignmentRoleSelect_AssignmentRoleFragment";
};
export type AssignmentRoleSelect_AssignmentRoleFragment$key = {
  readonly " $data"?: AssignmentRoleSelect_AssignmentRoleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentRoleSelect_AssignmentRoleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AssignmentRoleSelect_AssignmentRoleFragment"
};

(node as any).hash = "d626a8941d7d760fd9ecd7e3accbbaef";

export default node;
