/**
 * @generated SignedSource<<5fd25b137b96c7606eddd494717634a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usersInAccountAdminTable_UserInlineFragment$data = {
  readonly groups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly id: string;
  readonly user: {
    readonly activated: boolean;
    readonly email: string;
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ChangeUserGroupsAdminButton_UserInAccountFragment">;
  readonly " $fragmentType": "usersInAccountAdminTable_UserInlineFragment";
};
export type usersInAccountAdminTable_UserInlineFragment$key = {
  readonly " $data"?: usersInAccountAdminTable_UserInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usersInAccountAdminTable_UserInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usersInAccountAdminTable_UserInlineFragment"
};

(node as any).hash = "91a6ce0cfd0f88f20330727bfe0aaeaf";

export default node;
