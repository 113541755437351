/**
 * @generated SignedSource<<aff428276f57dfcec80f5ffef9f02975>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type updateAssignmentsFromDynamicsButton_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "updateAssignmentsFromDynamicsButton_ScenarioFragment";
};
export type updateAssignmentsFromDynamicsButton_ScenarioFragment$key = {
  readonly " $data"?: updateAssignmentsFromDynamicsButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"updateAssignmentsFromDynamicsButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "updateAssignmentsFromDynamicsButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "2f94d19d8759c9dc4c553ce5c0fef723";

export default node;
