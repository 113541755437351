/**
 * @generated SignedSource<<48593093fdc4130794b1b21f7c954157>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAssignmentRoleInput = {
  clientMutationId?: string | null;
  data: AssignmentRoleDataInput;
};
export type AssignmentRoleDataInput = {
  countAsFullyAllocatedAtPercentage?: number | null;
  countAsOverallocatedAtPercentage?: number | null;
  maxNumberOfProjects?: number | null;
  name: string;
  sortOrder: number;
  useEndDateOfLastAssignmentOverProjectionCap?: boolean | null;
  utilizationProjectionCapInMonths?: number | null;
};
export type createAssignmentRoleButton_CreateAssignmentRoleMutation$variables = {
  connectionId: string;
  input: CreateAssignmentRoleInput;
};
export type createAssignmentRoleButton_CreateAssignmentRoleMutation$data = {
  readonly Assignment: {
    readonly createAssignmentRole: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editAssignmentRoleButton_AssignmentRoleFragment">;
        };
      };
    } | null;
  };
};
export type createAssignmentRoleButton_CreateAssignmentRoleMutation = {
  response: createAssignmentRoleButton_CreateAssignmentRoleMutation$data;
  variables: createAssignmentRoleButton_CreateAssignmentRoleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createAssignmentRoleButton_CreateAssignmentRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateAssignmentRolePayload",
            "kind": "LinkedField",
            "name": "createAssignmentRole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editAssignmentRoleButton_AssignmentRoleFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createAssignmentRoleButton_CreateAssignmentRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentMutations",
        "kind": "LinkedField",
        "name": "Assignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateAssignmentRolePayload",
            "kind": "LinkedField",
            "name": "createAssignmentRole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumberOfProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utilizationProjectionCapInMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsFullyAllocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countAsOverallocatedAtPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useEndDateOfLastAssignmentOverProjectionCap",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "items": [
                      {
                        "kind": "Variable",
                        "name": "connections.0",
                        "variableName": "connectionId"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91a249def6f6540bbf33222eb2046af4",
    "id": null,
    "metadata": {},
    "name": "createAssignmentRoleButton_CreateAssignmentRoleMutation",
    "operationKind": "mutation",
    "text": "mutation createAssignmentRoleButton_CreateAssignmentRoleMutation(\n  $input: CreateAssignmentRoleInput!\n) {\n  Assignment {\n    createAssignmentRole(input: $input) {\n      edge {\n        node {\n          id\n          ...editAssignmentRoleButton_AssignmentRoleFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editAssignmentRoleButton_AssignmentRoleFragment on AssignmentRole {\n  id\n  name\n  sortOrder\n  maxNumberOfProjects\n  utilizationProjectionCapInMonths\n  countAsFullyAllocatedAtPercentage\n  countAsOverallocatedAtPercentage\n  useEndDateOfLastAssignmentOverProjectionCap\n}\n"
  }
};
})();

(node as any).hash = "9f1d4e92f02e97e65ffaa65dcfd9ea55";

export default node;
