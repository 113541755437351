/**
 * @generated SignedSource<<a33bf8e3f1a0d7ef4d7decd29667f6ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvitationsTable_InvitationsListFragment$data = {
  readonly Management: {
    readonly InvitationsToAccount: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string;
          readonly id: string;
          readonly invitingUserName: string | null;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "InvitationsTable_InvitationsListFragment";
};
export type InvitationsTable_InvitationsListFragment$key = {
  readonly " $data"?: InvitationsTable_InvitationsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationsTable_InvitationsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Management",
  "InvitationsToAccount"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InvitationsTable_Refetch.graphql')
    }
  },
  "name": "InvitationsTable_InvitationsListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ManagementQueries",
      "kind": "LinkedField",
      "name": "Management",
      "plural": false,
      "selections": [
        {
          "alias": "InvitationsToAccount",
          "args": null,
          "concreteType": "InvitationConnection",
          "kind": "LinkedField",
          "name": "__InvitationsTable_InvitationsToAccount_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvitationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Invitation",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invitingUserName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9ec7de7f98dabc2eae62085a3d7ab7ee";

export default node;
