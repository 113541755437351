/**
 * @generated SignedSource<<e6cf2226424f862527b211f2cb5dbfb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type categoryForm_AssessmentFragment$data = {
  readonly skillAssessments: ReadonlyArray<{
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  }>;
  readonly template: {
    readonly assessedSkills: ReadonlyArray<{
      readonly description: string | null;
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly dimensionExplanations?: ReadonlyArray<string>;
        readonly kind?: SkillDimensionTypeEnum;
      };
      readonly id: string;
      readonly name: string;
      readonly skillCategory: {
        readonly id: string;
        readonly name: string;
        readonly sortOrder: number;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"numericalSkillTooltipIcon_SkillFragment">;
    }>;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "categoryForm_AssessmentFragment";
};
export type categoryForm_AssessmentFragment$key = {
  readonly " $data"?: categoryForm_AssessmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryForm_AssessmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "categoryForm_AssessmentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "skillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                }
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "assessedSkills",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "BinaryDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionExplanations",
                      "storageKey": null
                    }
                  ],
                  "type": "NumericalDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategory",
              "kind": "LinkedField",
              "name": "skillCategory",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sortOrder",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "numericalSkillTooltipIcon_SkillFragment"
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Assessment",
  "abstractKey": null
};
})();

(node as any).hash = "98b81133c7a4fbd3c44cb938c4f3993a";

export default node;
