/**
 * @generated SignedSource<<f218f0556104f5c8229fd36d8e228a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStagesTable_ProjectStageFragment$data = {
  readonly color: string | null;
  readonly id: string;
  readonly name: string;
  readonly reverseProjectOrderInReports: boolean;
  readonly sortOrder: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStageSortOrderButtons_ProjectStageFragment" | "editProjectStageButton_ProjectStageFragment">;
  readonly " $fragmentType": "ProjectStagesTable_ProjectStageFragment";
};
export type ProjectStagesTable_ProjectStageFragment$key = {
  readonly " $data"?: ProjectStagesTable_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStagesTable_ProjectStageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ProjectStagesTable_ProjectStageFragment"
};

(node as any).hash = "2ca9b748729351f4f19ceae1cec44388";

export default node;
