/**
 * @generated SignedSource<<32a0683f1d85b5927e693b2a982d8cce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type addProjectToScenarioCard_ScenarioFragment$data = {
  readonly isMasterPlan: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioModal_ScenarioFragment">;
  readonly " $fragmentType": "addProjectToScenarioCard_ScenarioFragment";
};
export type addProjectToScenarioCard_ScenarioFragment$key = {
  readonly " $data"?: addProjectToScenarioCard_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioCard_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "addProjectToScenarioCard_ScenarioFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "addProjectToScenarioModal_ScenarioFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMasterPlan",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "f4e96443b7a7f512226711916717f440";

export default node;
