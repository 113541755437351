/**
 * @generated SignedSource<<d30b46450ad030b06f812e293035c8e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeAccountGroupsAdminModal_AccountFragment$data = {
  readonly groupAssociations: ReadonlyArray<{
    readonly group: {
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
  readonly id: string;
  readonly " $fragmentType": "ChangeAccountGroupsAdminModal_AccountFragment";
};
export type ChangeAccountGroupsAdminModal_AccountFragment$key = {
  readonly " $data"?: ChangeAccountGroupsAdminModal_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeAccountGroupsAdminModal_AccountFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeAccountGroupsAdminModal_AccountFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroupAssociation",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountGroup",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "7a5ead7fb30e0dcb68ef407838288846";

export default node;
