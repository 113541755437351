import { configureStore } from "@reduxjs/toolkit";
import { AuthSliceReducer } from "./redux/AuthSlice";
import { ProjectViewSliceReducer } from "./redux/ProjectViewSlice";
import { PeopleSliceReducer } from "./redux/PeopleSlice";
import { RegionSliceReducer } from "./redux/RegionSlice";
import { AssignmentRoleSliceReducer } from "./redux/AssignmentRoleSlice";
import { DivisionSliceReducer } from "./redux/DivisionSlice";
import { StaffViewSliceReducer } from "./redux/StaffViewSlice";
import { CurrentUserSliceReducer } from "./redux/CurrentUserSlice";
import { SkillCategorySliceReducer } from "./redux/SkillCategroySlice";
import { ProjectSliceReducer } from "./redux/ProjectSlice";
import { ScenarioSliceReducer } from "./redux/ScenarioSlice";
import { AvailabilityForecastSliceReducer } from "./redux/AvailabilityForecastSlice";
import { ProjectStageSliceReducer } from "./redux/ProjectStageSlice";
import { StaffingTemplateSliceReducer } from "./redux/StaffingTemplatesSlice";
import { MapReducer } from "./redux/MapSlice";
import { SkillAssessmentTemplatesSliceReducer } from "@redux/skill-assessment-templates-slice";
import { SkillAssessmentSliceReducer } from "@redux/skill-assessments.slice";

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		scenario: ProjectViewSliceReducer,
		people: PeopleSliceReducer,
		region: RegionSliceReducer,
		assignmentRoles: AssignmentRoleSliceReducer,
		division: DivisionSliceReducer,
		staffView: StaffViewSliceReducer,
		currentUser: CurrentUserSliceReducer,
		skills: SkillCategorySliceReducer,
		projects: ProjectSliceReducer,
		scenarios: ScenarioSliceReducer,
		availabilityForecast: AvailabilityForecastSliceReducer,
		projectStage: ProjectStageSliceReducer,
		staffingTemplate: StaffingTemplateSliceReducer,
		map: MapReducer,
		skillAssessments: SkillAssessmentSliceReducer,
		skillAssessmentTemplates: SkillAssessmentTemplatesSliceReducer,
	},
});
export type ReduxState = ReturnType<typeof ReduxStore.getState>;
