/**
 * @generated SignedSource<<fa9ce516440061b511d5448ff46525a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type syncWithRandPreconDwhButton_ScenarioFragment$data = {
  readonly id: string;
  readonly isMasterPlan: boolean;
  readonly " $fragmentType": "syncWithRandPreconDwhButton_ScenarioFragment";
};
export type syncWithRandPreconDwhButton_ScenarioFragment$key = {
  readonly " $data"?: syncWithRandPreconDwhButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"syncWithRandPreconDwhButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "syncWithRandPreconDwhButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMasterPlan",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "28e1ea12aebe77dac7c746ce78c459fa";

export default node;
