/**
 * @generated SignedSource<<cdee36d8cd4b03c54142548d6efbd960>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeShowBudgetForm_CurrentUser$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly showBudget?: boolean;
          };
        };
      } | null;
    };
  };
  readonly " $fragmentType": "ChangeShowBudgetForm_CurrentUser";
};
export type ChangeShowBudgetForm_CurrentUser$key = {
  readonly " $data"?: ChangeShowBudgetForm_CurrentUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeShowBudgetForm_CurrentUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeShowBudgetForm_CurrentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentUser",
              "kind": "LinkedField",
              "name": "currentUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showBudget",
                              "storageKey": null
                            }
                          ],
                          "type": "HarkinsUserExtensionAndId",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b6d5700243c3f7ab12079749da7e4334";

export default node;
