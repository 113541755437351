/**
 * @generated SignedSource<<4d9e99e30e092c07619c8e674734f220>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRegionModal_RegionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditRegionModal_RegionFragment";
};
export type EditRegionModal_RegionFragment$key = {
  readonly " $data"?: EditRegionModal_RegionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRegionModal_RegionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRegionModal_RegionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Region",
  "abstractKey": null
};

(node as any).hash = "10ea08d9653f5f585b1e0a6cd90b70c0";

export default node;
