/**
 * @generated SignedSource<<6aa525a2e5f5c99e16da409da901e161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StaffingTemplatesTable_Query$variables = {
  filterByName?: string | null;
  first?: number | null;
};
export type StaffingTemplatesTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StaffingTemplatesTable_StaffingTemplateListFragment">;
};
export type StaffingTemplatesTable_Query = {
  response: StaffingTemplatesTable_Query$data;
  variables: StaffingTemplatesTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffingTemplatesTable_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "StaffingTemplatesTable_StaffingTemplateListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StaffingTemplatesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateQueries",
        "kind": "LinkedField",
        "name": "Template",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "StaffingTemplateConnection",
            "kind": "LinkedField",
            "name": "StaffingTemplates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffingTemplateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StaffingTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRoleAssociation",
                        "kind": "LinkedField",
                        "name": "assignmentRoleAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentRole",
                            "kind": "LinkedField",
                            "name": "assignmentRole",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isExecutive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assignmentRoleRef",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "StaffingTemplatesTable_StaffingTemplates",
            "kind": "LinkedHandle",
            "name": "StaffingTemplates"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8decd2e9a7ea915999e3072a62824f94",
    "id": null,
    "metadata": {},
    "name": "StaffingTemplatesTable_Query",
    "operationKind": "query",
    "text": "query StaffingTemplatesTable_Query(\n  $first: Int\n  $filterByName: String\n) {\n  ...StaffingTemplatesTable_StaffingTemplateListFragment_2rD0Jv\n}\n\nfragment EditStaffingTemplateButton_StaffingTemplateFragment on StaffingTemplate {\n  ...EditStaffingTemplateModal_StaffingTemplateFragment\n}\n\nfragment EditStaffingTemplateModal_StaffingTemplateFragment on StaffingTemplate {\n  id\n  name\n  assignmentRoleAssociations {\n    assignmentRoleRef\n    isExecutive\n  }\n}\n\nfragment StaffingTemplatesTable_StaffingTemplateListFragment_2rD0Jv on Query {\n  Template {\n    StaffingTemplates(first: $first, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          assignmentRoleAssociations {\n            assignmentRole {\n              id\n              name\n            }\n            isExecutive\n          }\n          ...EditStaffingTemplateButton_StaffingTemplateFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbcd55a5c0adf804d55de0c510c5c683";

export default node;
