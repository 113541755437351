/**
 * @generated SignedSource<<cb31d12872777aa8d57a05a4d43adb90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editProjectStageButton_ProjectStageFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editProjectStageModal_ProjectStageFragment">;
  readonly " $fragmentType": "editProjectStageButton_ProjectStageFragment";
};
export type editProjectStageButton_ProjectStageFragment$key = {
  readonly " $data"?: editProjectStageButton_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editProjectStageButton_ProjectStageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editProjectStageButton_ProjectStageFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editProjectStageModal_ProjectStageFragment"
    }
  ],
  "type": "ProjectStage",
  "abstractKey": null
};

(node as any).hash = "6be61c1874ac10f97a541500de999afb";

export default node;
