import styled from "styled-components";

export const Header = styled.div`
	font-size: 1rem;
`;

export const PersonImage = styled.img`
	height: 100px;
	width: auto;
`;
