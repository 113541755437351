/**
 * @generated SignedSource<<943b73326d09d9fb49bd105862f94162>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usersInAccountTable_Refetch$variables = {
  after?: string | null;
  first?: number | null;
};
export type usersInAccountTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usersInAccountTable_UsersInAccountListFragment">;
};
export type usersInAccountTable_Refetch = {
  response: usersInAccountTable_Refetch$data;
  variables: usersInAccountTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usersInAccountTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "usersInAccountTable_UsersInAccountListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usersInAccountTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementQueries",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserInAccountConnection",
            "kind": "LinkedField",
            "name": "UsersInAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInAccountEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccount",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroup",
                        "kind": "LinkedField",
                        "name": "groups",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "usersInAccountTable_UsersInAccount",
            "kind": "LinkedHandle",
            "name": "UsersInAccount"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abad583f8e1779c3e9e3ea9f0ef30180",
    "id": null,
    "metadata": {},
    "name": "usersInAccountTable_Refetch",
    "operationKind": "query",
    "text": "query usersInAccountTable_Refetch(\n  $after: String\n  $first: Int = 20\n) {\n  ...usersInAccountTable_UsersInAccountListFragment_2HEEH6\n}\n\nfragment changeUserInAccountGroupsButton_UserInAccountFragment on UserInAccount {\n  ...changeUserInAccountGroupsModal_UserInAccountFragment\n}\n\nfragment changeUserInAccountGroupsModal_UserInAccountFragment on UserInAccount {\n  id\n  user {\n    id\n  }\n  groups {\n    id\n  }\n}\n\nfragment usersInAccountTable_UsersInAccountListFragment_2HEEH6 on Query {\n  Management {\n    UsersInAccount(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          user {\n            id\n            email\n            name\n          }\n          groups {\n            id\n            name\n          }\n          ...changeUserInAccountGroupsButton_UserInAccountFragment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b69ef136f2f77f96eb0ffb9e8259cccf";

export default node;
