/**
 * @generated SignedSource<<97514295de132fa16095e04a82a03054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegionSelect_RegionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "RegionSelect_RegionFragment";
};
export type RegionSelect_RegionFragment$key = {
  readonly " $data"?: RegionSelect_RegionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegionSelect_RegionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RegionSelect_RegionFragment"
};

(node as any).hash = "802cee9a142b2ca442a43c14737cdd41";

export default node;
