/**
 * @generated SignedSource<<a95aabd9174a1ad0913b8a7f3f8c1a6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type exportDivisionsButton_ExportDivisionsMutation$variables = {};
export type exportDivisionsButton_ExportDivisionsMutation$data = {
  readonly Division: {
    readonly exportDivisions: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type exportDivisionsButton_ExportDivisionsMutation = {
  response: exportDivisionsButton_ExportDivisionsMutation$data;
  variables: exportDivisionsButton_ExportDivisionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "exportDivisionsButton_ExportDivisionsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DivisionMutations",
        "kind": "LinkedField",
        "name": "Division",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportDivisionsPayload",
            "kind": "LinkedField",
            "name": "exportDivisions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportDivisions(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "exportDivisionsButton_ExportDivisionsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DivisionMutations",
        "kind": "LinkedField",
        "name": "Division",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportDivisionsPayload",
            "kind": "LinkedField",
            "name": "exportDivisions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportDivisions(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "901bce2812ebf5f6bfa2f776088e1ce9",
    "id": null,
    "metadata": {},
    "name": "exportDivisionsButton_ExportDivisionsMutation",
    "operationKind": "mutation",
    "text": "mutation exportDivisionsButton_ExportDivisionsMutation {\n  Division {\n    exportDivisions(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b518e0994e609242a235c8ef113169a";

export default node;
