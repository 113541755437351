/**
 * @generated SignedSource<<5abe08b6bce4f5b1fa360e6ebabdc1df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenarioStaffViewScreen_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_ScenarioFragment" | "loadPursuitProjectsFromRandDwhButton_ScenarioFragment" | "staffViewFiltersPart_ScenarioFragment" | "syncWithRandPreconDwhButton_ScenarioFragment" | "updateAssignmentsFromDynamicsButton_ScenarioFragment">;
  readonly " $fragmentType": "ScenarioStaffViewScreen_ScenarioFragment";
};
export type ScenarioStaffViewScreen_ScenarioFragment$key = {
  readonly " $data"?: ScenarioStaffViewScreen_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScenarioStaffViewScreen_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioStaffViewScreen_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardHeader_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "staffViewFiltersPart_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "updateAssignmentsFromDynamicsButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "syncWithRandPreconDwhButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "loadPursuitProjectsFromRandDwhButton_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "0b636051eca320538cbc01aeff31b845";

export default node;
