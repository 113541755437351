import { Color } from "@thekeytechnology/epic-ui";

const dark = new Color("#26262b");
const dark2 = new Color("#7D85A7");
const primary = new Color("#214CE2");
const primaryDarker = new Color("#1b3fbb");
const background = new Color("#EAEFFA");
const darkGreen = new Color("#07767d");
const success = new Color("#00CC25");
const warning = new Color("#F59E0B");
const danger = new Color("#FF1500");
const text = new Color("#36416E");
const textLight = new Color("#7D85A7");
export const colors = {
	dark,
	dark2,
	primary,
	primaryDarker,
	background,
	darkGreen,
	success,
	warning,
	danger,
	text,
	textLight,
};
