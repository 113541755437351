/**
 * @generated SignedSource<<c26eb24c97a736c2f52effa7210b06db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Role = "Admin" | "AssessedPerson" | "Supervisor";
import { FragmentRefs } from "relay-runtime";
export type skillAssessmentTemplatesTable_AssessmentTemplateFragment$data = {
  readonly assessedSkills: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly associatedRoles: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly distributionList: {
    readonly emails: ReadonlyArray<string>;
    readonly role: ReadonlyArray<Role>;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"editTemplateButton_AssessmentTemplateFragment">;
  readonly " $fragmentType": "skillAssessmentTemplatesTable_AssessmentTemplateFragment";
};
export type skillAssessmentTemplatesTable_AssessmentTemplateFragment$key = {
  readonly " $data"?: skillAssessmentTemplatesTable_AssessmentTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentTemplatesTable_AssessmentTemplateFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "skillAssessmentTemplatesTable_AssessmentTemplateFragment"
};

(node as any).hash = "3ce0fd081642cb16dc6015b74312ae37";

export default node;
