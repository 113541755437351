/**
 * @generated SignedSource<<46874f07ec2b6b12e64048e8380039de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPersonButton_PersonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonModal_PersonFragment">;
  readonly " $fragmentType": "EditPersonButton_PersonFragment";
};
export type EditPersonButton_PersonFragment$key = {
  readonly " $data"?: EditPersonButton_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonButton_PersonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPersonButton_PersonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditPersonModal_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "1dd9bd525c00d0c30a3fbd66b68f66f2";

export default node;
