/**
 * @generated SignedSource<<1e7e7ff1fc25f7c32002c7e969473d31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditScenarioInput = {
  clientMutationId?: string | null;
  name: string;
  scenarioId: string;
};
export type EditScenarioButton_EditMutation$variables = {
  input: EditScenarioInput;
};
export type EditScenarioButton_EditMutation$data = {
  readonly Scenario: {
    readonly editScenario: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly isMasterPlan: boolean;
          readonly name: string;
        };
      };
    } | null;
  };
};
export type EditScenarioButton_EditMutation = {
  response: EditScenarioButton_EditMutation$data;
  variables: EditScenarioButton_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ScenarioMutations",
    "kind": "LinkedField",
    "name": "Scenario",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "EditScenarioPayload",
        "kind": "LinkedField",
        "name": "editScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScenariosEdge",
            "kind": "LinkedField",
            "name": "edge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMasterPlan",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditScenarioButton_EditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditScenarioButton_EditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7bc0aab704dcaead463304a7e20fd5e1",
    "id": null,
    "metadata": {},
    "name": "EditScenarioButton_EditMutation",
    "operationKind": "mutation",
    "text": "mutation EditScenarioButton_EditMutation(\n  $input: EditScenarioInput!\n) {\n  Scenario {\n    editScenario(input: $input) {\n      edge {\n        node {\n          id\n          name\n          isMasterPlan\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a75ce83f48e4a5482701c0b77690c77f";

export default node;
