/**
 * @generated SignedSource<<cb3a16c80616f66abba7e2c74d9c1d67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImportRegionsInput = {
  clientMutationId?: string | null;
  fileId: string;
};
export type ImportRegionsButton_ImportMutation$variables = {
  input: ImportRegionsInput;
};
export type ImportRegionsButton_ImportMutation$data = {
  readonly Region: {
    readonly importRegions: {
      readonly result: {
        readonly editedEntities: number;
        readonly issues: ReadonlyArray<{
          readonly issue: string;
          readonly row: number;
        }>;
        readonly newEntities: number;
      };
    } | null;
  };
};
export type ImportRegionsButton_ImportMutation = {
  response: ImportRegionsButton_ImportMutation$data;
  variables: ImportRegionsButton_ImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionMutations",
    "kind": "LinkedField",
    "name": "Region",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ImportRegionsPayload",
        "kind": "LinkedField",
        "name": "importRegions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportResult",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editedEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newEntities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportIssue",
                "kind": "LinkedField",
                "name": "issues",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "row",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportRegionsButton_ImportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportRegionsButton_ImportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67142c8c9ca1212587b16976efbab5ff",
    "id": null,
    "metadata": {},
    "name": "ImportRegionsButton_ImportMutation",
    "operationKind": "mutation",
    "text": "mutation ImportRegionsButton_ImportMutation(\n  $input: ImportRegionsInput!\n) {\n  Region {\n    importRegions(input: $input) {\n      result {\n        editedEntities\n        newEntities\n        issues {\n          row\n          issue\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91a184a294557150f6c49c3571eabebc";

export default node;
