/**
 * @generated SignedSource<<85acda547bfccc8965e2400dd3cd8601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userInAccountGroupsTable_Query$variables = {
  first?: number | null;
};
export type userInAccountGroupsTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"userInAccountGroupsTable_GroupListFragment">;
};
export type userInAccountGroupsTable_Query = {
  response: userInAccountGroupsTable_Query$data;
  variables: userInAccountGroupsTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userInAccountGroupsTable_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "userInAccountGroupsTable_GroupListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userInAccountGroupsTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementQueries",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserInAccountGroupsConnection",
            "kind": "LinkedField",
            "name": "Groups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInAccountGroupsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountGroup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "userInAccountGroupsTable_Groups",
            "kind": "LinkedHandle",
            "name": "Groups"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01082eb3c203fd6e0fc616059511f82b",
    "id": null,
    "metadata": {},
    "name": "userInAccountGroupsTable_Query",
    "operationKind": "query",
    "text": "query userInAccountGroupsTable_Query(\n  $first: Int\n) {\n  ...userInAccountGroupsTable_GroupListFragment_3ASum4\n}\n\nfragment editUserInAccountGroupButton_GroupFragment on UserInAccountGroup {\n  ...editUserInAccountGroupModal_GroupFragment\n}\n\nfragment editUserInAccountGroupModal_GroupFragment on UserInAccountGroup {\n  id\n  name\n  permissions\n}\n\nfragment userInAccountGroupsTable_GroupListFragment_3ASum4 on Query {\n  Management {\n    Groups(first: $first) {\n      edges {\n        node {\n          ...userInAccountGroupsTable_userInAccountGroupInlineFragment\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment userInAccountGroupsTable_userInAccountGroupInlineFragment on UserInAccountGroup {\n  id\n  name\n  permissions\n  ...editUserInAccountGroupButton_GroupFragment\n}\n"
  }
};
})();

(node as any).hash = "5a2db8af3aac3a042adbbed324984eb3";

export default node;
