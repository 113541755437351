/**
 * @generated SignedSource<<4b4eb27b08bc52fa5d2e82c909efc1f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ProjectSource = "fromDynamics" | "fromDynamicsActivity" | "fromRand" | "manuallyCreated";
import { FragmentRefs } from "relay-runtime";
export type ProjectsTable_ProjectFragment$data = {
  readonly address: {
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"GoogleMapsClickout_AddressFragment">;
  } | null;
  readonly avatar: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly division: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly endDate: any | null;
  readonly id: string;
  readonly isDeactivated: boolean;
  readonly name: string;
  readonly region: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly source: ProjectSource;
  readonly stage: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly startDate: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectActivationButton_ProjectFragment" | "editProjectButton_ProjectFragment" | "syncProjectFromDynamicsButton_ProjectFragment" | "syncProjectFromRandButton_ProjectFragment">;
  readonly " $fragmentType": "ProjectsTable_ProjectFragment";
};
export type ProjectsTable_ProjectFragment$key = {
  readonly " $data"?: ProjectsTable_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsTable_ProjectFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ProjectsTable_ProjectFragment"
};

(node as any).hash = "c0778b7c474ed0be913bc946366d5acf";

export default node;
