/**
 * @generated SignedSource<<7af74e7f92d7334b00f8834c17434348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectUserField_Query$variables = {
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  excludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
};
export type SelectUserField_Query$data = {
  readonly Admin: {
    readonly Management: {
      readonly UsersAdmin: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"SelectUserField_UserFragment">;
          };
        } | null> | null;
      };
    };
  };
};
export type SelectUserField_Query = {
  response: SelectUserField_Query$data;
  variables: SelectUserField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v3 = [
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "excludeIds",
    "variableName": "excludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectUserField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "UsersAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "SelectUserField_UserFragment",
                            "selections": (v5/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectUserField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "UsersAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecf6adc6ba803bb3df7e47f000d203d1",
    "id": null,
    "metadata": {},
    "name": "SelectUserField_Query",
    "operationKind": "query",
    "text": "query SelectUserField_Query(\n  $filterByName: String\n  $alwaysIncludeIds: [ID!]\n  $excludeIds: [ID!]\n) {\n  Admin {\n    Management {\n      UsersAdmin(first: 20, filterByName: $filterByName, alwaysIncludeIds: $alwaysIncludeIds, excludeIds: $excludeIds) {\n        edges {\n          node {\n            id\n            ...SelectUserField_UserFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SelectUserField_UserFragment on User {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "f5f0e0d575ce0da7e7342295bf38b18b";

export default node;
