/**
 * @generated SignedSource<<043a8ac5d6dcb9816cb69b27df2ff7a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDivisionButton_DivisionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditDivisionModal_DivisionFragment">;
  readonly " $fragmentType": "EditDivisionButton_DivisionFragment";
};
export type EditDivisionButton_DivisionFragment$key = {
  readonly " $data"?: EditDivisionButton_DivisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDivisionButton_DivisionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDivisionButton_DivisionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditDivisionModal_DivisionFragment"
    }
  ],
  "type": "Division",
  "abstractKey": null
};

(node as any).hash = "17983a91092e8ffb881844db00a23aa6";

export default node;
