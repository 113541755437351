/**
 * @generated SignedSource<<0e2f107aa1e600db27d1ef47ef9e82dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsGridPart_QueryFragment$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentAccount: {
        readonly " $fragmentSpreads": FragmentRefs<"updateAssignmentsFromDynamicsButton_AccountFragment">;
      } | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"FilterViewSelector_QueryFragment" | "projectStagesTabs_ProjectStages" | "projectViewFiltersPart_QueryFragment">;
  readonly " $fragmentType": "ProjectsGridPart_QueryFragment";
};
export type ProjectsGridPart_QueryFragment$key = {
  readonly " $data"?: ProjectsGridPart_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsGridPart_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsGridPart_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectStagesTabs_ProjectStages"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "filterByViewType",
          "value": "ProjectView"
        }
      ],
      "kind": "FragmentSpread",
      "name": "FilterViewSelector_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectViewFiltersPart_QueryFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "currentAccount",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "updateAssignmentsFromDynamicsButton_AccountFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8832e569033a0d087497587f7710f44b";

export default node;
