/**
 * @generated SignedSource<<3fbb2633b666cdc1cae9a263d503c574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneScenarioInput = {
  clientMutationId?: string | null;
  newName: string;
  scenarioId: string;
};
export type CloneScenarioButton_CloneMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CloneScenarioInput;
};
export type CloneScenarioButton_CloneMutation$data = {
  readonly Scenario: {
    readonly cloneScenario: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ScenariosTable_ScenarioFragment" | "UserScenariosTable_ScenarioFragment">;
        };
      };
    } | null;
  };
};
export type CloneScenarioButton_CloneMutation = {
  response: CloneScenarioButton_CloneMutation$data;
  variables: CloneScenarioButton_CloneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMasterPlan",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ScenarioGapDays",
  "kind": "LinkedField",
  "name": "gapDays",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gapDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gapSalary",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ScenarioUtilization",
  "kind": "LinkedField",
  "name": "utilization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unusedSalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageUtilizationPercentage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CheckScenarioPermissions_ScenarioFragment"
},
v10 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "MakeMasterPlanButton_ScenarioFragment"
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CloneScenarioButton_ScenarioFragment"
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ToggleVisibilityButton_ScenarioFragment"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CloneScenarioButton_CloneMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CloneScenarioPayload",
            "kind": "LinkedField",
            "name": "cloneScenario",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "UserScenariosTable_ScenarioFragment",
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      },
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "ScenariosTable_ScenarioFragment",
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditScenarioButton_ScenarioFragment"
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CloneScenarioButton_CloneMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioMutations",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CloneScenarioPayload",
            "kind": "LinkedField",
            "name": "cloneScenario",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab6a020b588c4547818211a0cac0771b",
    "id": null,
    "metadata": {},
    "name": "CloneScenarioButton_CloneMutation",
    "operationKind": "mutation",
    "text": "mutation CloneScenarioButton_CloneMutation(\n  $input: CloneScenarioInput!\n) {\n  Scenario {\n    cloneScenario(input: $input) {\n      edge {\n        node {\n          id\n          ...UserScenariosTable_ScenarioFragment\n          ...ScenariosTable_ScenarioFragment\n        }\n      }\n    }\n  }\n}\n\nfragment CheckScenarioPermissions_ScenarioFragment on Scenario {\n  isMasterPlan\n}\n\nfragment CloneScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment EditScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment MakeMasterPlanButton_ScenarioFragment on Scenario {\n  id\n  isMasterPlan\n}\n\nfragment ScenariosTable_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  lastUpdatedAt\n  ...EditScenarioButton_ScenarioFragment\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...MakeMasterPlanButton_ScenarioFragment\n  ...CloneScenarioButton_ScenarioFragment\n  ...ToggleVisibilityButton_ScenarioFragment\n}\n\nfragment ToggleVisibilityButton_ScenarioFragment on Scenario {\n  id\n  isPublic\n  isMasterPlan\n}\n\nfragment UserScenariosTable_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  lastUpdatedAt\n  gapDays {\n    gapDays\n    gapSalary\n  }\n  utilization {\n    unusedSalary\n    averageUtilizationPercentage\n  }\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...MakeMasterPlanButton_ScenarioFragment\n  ...CloneScenarioButton_ScenarioFragment\n  ...ToggleVisibilityButton_ScenarioFragment\n}\n"
  }
};
})();

(node as any).hash = "cb2c8f23378e6715521b0d83ceb7ab32";

export default node;
