/**
 * @generated SignedSource<<a9fafdd7422a48812fcc5be75919a5a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type exportSkillCategoriesButton_ExportSkillCategoriesMutation$variables = {};
export type exportSkillCategoriesButton_ExportSkillCategoriesMutation$data = {
  readonly Skills: {
    readonly exportSkillCategories: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type exportSkillCategoriesButton_ExportSkillCategoriesMutation = {
  response: exportSkillCategoriesButton_ExportSkillCategoriesMutation$data;
  variables: exportSkillCategoriesButton_ExportSkillCategoriesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "exportSkillCategoriesButton_ExportSkillCategoriesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportSkillCategoriesPayload",
            "kind": "LinkedField",
            "name": "exportSkillCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportSkillCategories(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "exportSkillCategoriesButton_ExportSkillCategoriesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportSkillCategoriesPayload",
            "kind": "LinkedField",
            "name": "exportSkillCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportSkillCategories(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c95722097d220a3f88c41fc406a0d3af",
    "id": null,
    "metadata": {},
    "name": "exportSkillCategoriesButton_ExportSkillCategoriesMutation",
    "operationKind": "mutation",
    "text": "mutation exportSkillCategoriesButton_ExportSkillCategoriesMutation {\n  Skills {\n    exportSkillCategories(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6bce256624928d2f31fc53be2df625b";

export default node;
