/**
 * @generated SignedSource<<c05c73ec770776bd0a1b277ca11df6e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetPersonActivationInput = {
  clientMutationId?: string | null;
  id: string;
  isDeactivated: boolean;
};
export type ChangePersonActivationButton_Mutation$variables = {
  input: SetPersonActivationInput;
};
export type ChangePersonActivationButton_Mutation$data = {
  readonly Staff: {
    readonly setPersonActivation: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly isDeactivated: boolean;
        };
      };
    } | null;
  };
};
export type ChangePersonActivationButton_Mutation = {
  response: ChangePersonActivationButton_Mutation$data;
  variables: ChangePersonActivationButton_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StaffMutations",
    "kind": "LinkedField",
    "name": "Staff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SetPersonActivationPayload",
        "kind": "LinkedField",
        "name": "setPersonActivation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PeopleEdge",
            "kind": "LinkedField",
            "name": "edge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeactivated",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePersonActivationButton_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePersonActivationButton_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "480660427bff658df09300083a0b17a3",
    "id": null,
    "metadata": {},
    "name": "ChangePersonActivationButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation ChangePersonActivationButton_Mutation(\n  $input: SetPersonActivationInput!\n) {\n  Staff {\n    setPersonActivation(input: $input) {\n      edge {\n        node {\n          id\n          isDeactivated\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69072994290c335683b271866087e3cc";

export default node;
