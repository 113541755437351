/**
 * @generated SignedSource<<de2291994281ca368c4d4367260ae8f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportRegionsButton_ExportMutation$variables = {};
export type ExportRegionsButton_ExportMutation$data = {
  readonly Region: {
    readonly exportRegions: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportRegionsButton_ExportMutation = {
  response: ExportRegionsButton_ExportMutation$data;
  variables: ExportRegionsButton_ExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportRegionsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegionMutations",
        "kind": "LinkedField",
        "name": "Region",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportRegionsPayload",
            "kind": "LinkedField",
            "name": "exportRegions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportRegions(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExportRegionsButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegionMutations",
        "kind": "LinkedField",
        "name": "Region",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "ExportRegionsPayload",
            "kind": "LinkedField",
            "name": "exportRegions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "exportRegions(input:{})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40ba59f1a24638632d4c2289b1ab5ef5",
    "id": null,
    "metadata": {},
    "name": "ExportRegionsButton_ExportMutation",
    "operationKind": "mutation",
    "text": "mutation ExportRegionsButton_ExportMutation {\n  Region {\n    exportRegions(input: {}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3dd1a01c9781515c5370d481709e8015";

export default node;
