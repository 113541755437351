/**
 * @generated SignedSource<<80d901c92944d25c1186e416197742b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStageSelect_ProjectStageFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ProjectStageSelect_ProjectStageFragment";
};
export type ProjectStageSelect_ProjectStageFragment$key = {
  readonly " $data"?: ProjectStageSelect_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStageSelect_ProjectStageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ProjectStageSelect_ProjectStageFragment"
};

(node as any).hash = "866d56d42a0ad03c7f184759bd8e2d9c";

export default node;
