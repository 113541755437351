/**
 * @generated SignedSource<<c36dd1ef3d07ffc72435ce4a1c73c684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssessmentStatusKind = "Finished" | "InProgress" | "PdfGenerated";
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type navigation_AssessmentFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly person: {
    readonly name: string;
  } | null;
  readonly skillAssessments: ReadonlyArray<{
    readonly skill: {
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly kind?: SkillDimensionTypeEnum;
      };
      readonly id: string;
      readonly name: string;
      readonly skillCategory: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind?: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  }>;
  readonly status: {
    readonly file?: {
      readonly name: string;
      readonly url: string | null;
    } | null;
    readonly kind: AssessmentStatusKind;
  };
  readonly supervisor: {
    readonly name: string;
  } | null;
  readonly template: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "navigation_AssessmentFragment";
};
export type navigation_AssessmentFragment$key = {
  readonly " $data"?: navigation_AssessmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"navigation_AssessmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "navigation_AssessmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "supervisor",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "file",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "PdfGenerated",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "skillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionCount",
                      "storageKey": null
                    }
                  ],
                  "type": "NumericalDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "type": "BinaryDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategory",
              "kind": "LinkedField",
              "name": "skillCategory",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Assessment",
  "abstractKey": null
};
})();

(node as any).hash = "840c10d45d7da6f79c637f62b84b169a";

export default node;
