import { TkCard } from "@components/ui/TkCard";
import React, { Suspense } from "react";
import { TkTableSkeleton } from "@components/ui/TkTableSkeleton";
import { BaseSettingsScreen } from "@components/ui/BaseSettingsScreen";
import { SettingsScreenTemplateProps } from "@components/settings-screen-template/settings-screen-template.types";
import {
	FiltersWrapper,
	HeaderSpan,
	HeaderWrapper,
} from "@components/settings-screen-template/settings-screen-template.styles";

export const SettingsScreenTemplate = ({
	title,
	Filters,
	Table,
	ContextProvider,
	tableSkeletonColumnNames = ["Name", "Actions"],
}: SettingsScreenTemplateProps) => {
	const base = (
		<BaseSettingsScreen>
			<TkCard
				header={
					<HeaderWrapper className="flex p-3 align-items-center card-flat">
						<HeaderSpan className="mt-0 mr-3 mb-0 ml-0">{title}</HeaderSpan>
					</HeaderWrapper>
				}
			>
				<FiltersWrapper className="mb-3">
					<Filters />
				</FiltersWrapper>

				<Suspense fallback={<TkTableSkeleton columnNames={tableSkeletonColumnNames} />}>
					<Table />
				</Suspense>
			</TkCard>
		</BaseSettingsScreen>
	);
	if (ContextProvider) return <ContextProvider>{base}</ContextProvider>;
	return base;
};
