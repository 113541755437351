import { ValidatedFieldConfig } from "./ValidatedField";
import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { UtilizationStatus } from "../../__generated__/StaffViewPart_Query.graphql";

export const UtilizationStatusSelect = (fieldConfig: ValidatedFieldConfig<UtilizationStatus[]>) => {
	return (
		<MultiSelect
			name={fieldConfig.fieldName}
			value={fieldConfig.fieldValue}
			options={
				[
					{ label: "Not allocated", value: "NotAllocated" },
					{ label: "Underallocated", value: "Underallocated" },
					{ label: "Fully allocated", value: "FullyAllocated" },
					{ label: "Overallocated", value: "Overallocated" },
				] as { label: string; value: UtilizationStatus | null }[]
			}
			placeholder={fieldConfig.placeholder}
			onChange={(e) => fieldConfig.updateField(e.value)}
		/>
	);
};
