/**
 * @generated SignedSource<<eff84bd7b9996b5f1da8207775784cf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Role = "Admin" | "AssessedPerson" | "Supervisor";
export type CreateAssessmentTemplateInput = {
  clientMutationId?: string | null;
  data: AssessmentTemplateDataInput;
};
export type AssessmentTemplateDataInput = {
  assessedSkillIds: ReadonlyArray<string>;
  associatedRoleIds: ReadonlyArray<string>;
  distributionList: DistributionListInput;
  name: string;
};
export type DistributionListInput = {
  emails: ReadonlyArray<string | null>;
  role: ReadonlyArray<Role>;
};
export type createTemplateButton_CreateAssessmentTemplateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateAssessmentTemplateInput;
};
export type createTemplateButton_CreateAssessmentTemplateMutation$data = {
  readonly Assessment: {
    readonly createAssessmentTemplate: {
      readonly edge: {
        readonly node: {
          readonly assessedSkills: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly associatedRoles: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly name: string;
          readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentTemplatesTable_AssessmentTemplateFragment">;
        };
      };
    } | null;
  };
};
export type createTemplateButton_CreateAssessmentTemplateMutation = {
  response: createTemplateButton_CreateAssessmentTemplateMutation$data;
  variables: createTemplateButton_CreateAssessmentTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DistributionList",
  "kind": "LinkedField",
  "name": "distributionList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createTemplateButton_CreateAssessmentTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateAssessmentTemplatePayload",
            "kind": "LinkedField",
            "name": "createAssessmentTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssessmentTemplateEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "associatedRoles",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "assessedSkills",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "skillAssessmentTemplatesTable_AssessmentTemplateFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentRole",
                            "kind": "LinkedField",
                            "name": "associatedRoles",
                            "plural": true,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "assessedSkills",
                            "plural": true,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "editTemplateButton_AssessmentTemplateFragment"
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createTemplateButton_CreateAssessmentTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateAssessmentTemplatePayload",
            "kind": "LinkedField",
            "name": "createAssessmentTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssessmentTemplateEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "associatedRoles",
                        "plural": true,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "assessedSkills",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillCategory",
                            "kind": "LinkedField",
                            "name": "skillCategory",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6606c32590109e380b45be559c1603d",
    "id": null,
    "metadata": {},
    "name": "createTemplateButton_CreateAssessmentTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation createTemplateButton_CreateAssessmentTemplateMutation(\n  $input: CreateAssessmentTemplateInput!\n) {\n  Assessment {\n    createAssessmentTemplate(input: $input) {\n      edge {\n        node {\n          name\n          associatedRoles {\n            id\n          }\n          assessedSkills {\n            id\n          }\n          ...skillAssessmentTemplatesTable_AssessmentTemplateFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editTemplateButton_AssessmentTemplateFragment on AssessmentTemplate {\n  name\n  id\n  assessedSkills {\n    id\n    name\n    skillCategory {\n      id\n      name\n    }\n  }\n  associatedRoles {\n    id\n    name\n  }\n  distributionList {\n    role\n    emails\n  }\n}\n\nfragment skillAssessmentTemplatesTable_AssessmentTemplateFragment on AssessmentTemplate {\n  id\n  name\n  associatedRoles {\n    name\n    id\n  }\n  assessedSkills {\n    name\n    id\n  }\n  distributionList {\n    emails\n    role\n  }\n  ...editTemplateButton_AssessmentTemplateFragment\n}\n"
  }
};
})();

(node as any).hash = "aa7324b40fba265ba96aae9d9b27ddc0";

export default node;
