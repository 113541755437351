import { graphql } from "babel-plugin-relay/macro";

export const SCENARIO_FRAGMENT = graphql`
	fragment addProjectToScenarioModal_ScenarioFragment on Scenario {
		id
		projects {
			edges {
				node {
					id
					project {
						name
						source
					}
				}
			}
		}
	}
`;

export const ADD_NEW_MUTATION = graphql`
	mutation addProjectToScenarioModal_AddNewMutation(
		$input: AddNewProjectToScenarioInput!
		$connectionIds: [ID!]!
	) {
		Scenario {
			addNewProjectToScenario(input: $input) {
				edge @appendEdge(connections: $connectionIds) {
					node {
						id
						...ProjectsGridPart_ScenarioFragment
					}
				}
			}
		}
	}
`;

export const ADD_EXISTING_MUTATION = graphql`
	mutation addProjectToScenarioModal_AddExistingMutation(
		$input: AddExistingProjectsToScenarioInput!
	) {
		Scenario {
			addExistingProjectsToScenario(input: $input) {
				edge {
					node {
						id
						...ProjectsGridPart_ScenarioFragment
					}
				}
			}
		}
	}
`;
