/**
 * @generated SignedSource<<ee5901a4c2f82dbc902b7a356bedf742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnonymizeAccountButton_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "AnonymizeAccountButton_AccountFragment";
};
export type AnonymizeAccountButton_AccountFragment$key = {
  readonly " $data"?: AnonymizeAccountButton_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnonymizeAccountButton_AccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnonymizeAccountButton_AccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "320bd355cf7da828a75e80173f8ce2fc";

export default node;
