/**
 * @generated SignedSource<<c56f51710541e14296e129adc8eece91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectUserField_UserFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "SelectUserField_UserFragment";
};
export type SelectUserField_UserFragment$key = {
  readonly " $data"?: SelectUserField_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectUserField_UserFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SelectUserField_UserFragment"
};

(node as any).hash = "ef10732de65477411d45d1c3f7645782";

export default node;
