/**
 * @generated SignedSource<<529a2992a45f33b20981738057c88bdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeProjectActivationButton_ProjectFragment$data = {
  readonly id: string;
  readonly isDeactivated: boolean;
  readonly " $fragmentType": "ChangeProjectActivationButton_ProjectFragment";
};
export type ChangeProjectActivationButton_ProjectFragment$key = {
  readonly " $data"?: ChangeProjectActivationButton_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectActivationButton_ProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeProjectActivationButton_ProjectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeactivated",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "2f0b9089b5f479924e4b9370b8f20b70";

export default node;
