/**
 * @generated SignedSource<<fe3a3f21560f3eb2b85ddcf42bd75962>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editSkillAssociationModal_SkillAssociationFragment$data = {
  readonly data: {
    readonly skill: {
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly kind?: SkillDimensionTypeEnum;
      };
      readonly name: string;
      readonly skillCategory: {
        readonly name: string;
      } | null;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind?: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editBinarySkillAssociationModal_SkillAssociationFragment" | "editNumericalSkillAssociationModal_SkillAssociationFragment">;
  readonly " $fragmentType": "editSkillAssociationModal_SkillAssociationFragment";
};
export type editSkillAssociationModal_SkillAssociationFragment$key = {
  readonly " $data"?: editSkillAssociationModal_SkillAssociationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editSkillAssociationModal_SkillAssociationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editSkillAssociationModal_SkillAssociationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssociationData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                }
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategory",
              "kind": "LinkedField",
              "name": "skillCategory",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "BinaryDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionCount",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "type": "NumericalDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editNumericalSkillAssociationModal_SkillAssociationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editBinarySkillAssociationModal_SkillAssociationFragment"
    }
  ],
  "type": "SkillAssociation",
  "abstractKey": null
};
})();

(node as any).hash = "5476442004d89c36aeca2f5bb3652188";

export default node;
