/**
 * @generated SignedSource<<f52a759b2ce3e1b4cd7b1f5587f705ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssessmentStatusKind = "Finished" | "InProgress" | "PdfGenerated";
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type skillAssessmentExecution_AssessmentFragment$data = {
  readonly id: string;
  readonly skillAssessments: ReadonlyArray<{
    readonly skill: {
      readonly id: string;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  }>;
  readonly status: {
    readonly kind: AssessmentStatusKind;
  };
  readonly template: {
    readonly assessedSkills: ReadonlyArray<{
      readonly dimension: {
        readonly dimensionCount?: number;
        readonly kind?: SkillDimensionTypeEnum;
      };
      readonly id: string;
    }>;
  } | null;
  readonly " $fragmentType": "skillAssessmentExecution_AssessmentFragment";
};
export type skillAssessmentExecution_AssessmentFragment$key = {
  readonly " $data"?: skillAssessmentExecution_AssessmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentExecution_AssessmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "skillAssessmentExecution_AssessmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "assessedSkills",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "BinaryDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dimensionCount",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "type": "NumericalDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "skillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                }
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Assessment",
  "abstractKey": null
};
})();

(node as any).hash = "1de4376d322bdaf84f4c4850ca22b24e";

export default node;
