/**
 * @generated SignedSource<<832ee779cda826a7d16e9afcd58b690e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type executivesSelect_PersonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "executivesSelect_PersonFragment";
};
export type executivesSelect_PersonFragment$key = {
  readonly " $data"?: executivesSelect_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"executivesSelect_PersonFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "executivesSelect_PersonFragment"
};

(node as any).hash = "2483d68c39aa4341c76bc2b3339cf2b2";

export default node;
