/**
 * @generated SignedSource<<3fa842aac3352608ba65ade1e80ba554>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAccountModal_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly registeredAt: string;
  readonly " $fragmentType": "EditAccountModal_AccountFragment";
};
export type EditAccountModal_AccountFragment$key = {
  readonly " $data"?: EditAccountModal_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAccountModal_AccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAccountModal_AccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAt",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f279d51938f847211811013d5886c88d";

export default node;
