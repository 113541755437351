/**
 * @generated SignedSource<<c6f7aff7356a82db94dc6fcc5d42b202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenariosTable_Query$variables = {
  filterByName?: string | null;
  first?: number | null;
};
export type ScenariosTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ScenariosTable_ScenariosListFragment">;
};
export type ScenariosTable_Query = {
  response: ScenariosTable_Query$data;
  variables: ScenariosTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScenariosTable_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "ScenariosTable_ScenariosListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScenariosTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioQueries",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ScenariosConnection",
            "kind": "LinkedField",
            "name": "Scenarios",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMasterPlan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastUpdatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "ScenariosTable_Scenarios",
            "kind": "LinkedHandle",
            "name": "Scenarios"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fe8dfbbe95931fac33548f6eb30538b",
    "id": null,
    "metadata": {},
    "name": "ScenariosTable_Query",
    "operationKind": "query",
    "text": "query ScenariosTable_Query(\n  $first: Int\n  $filterByName: String\n) {\n  ...ScenariosTable_ScenariosListFragment_2rD0Jv\n}\n\nfragment CheckScenarioPermissions_ScenarioFragment on Scenario {\n  isMasterPlan\n}\n\nfragment CloneScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment EditScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment MakeMasterPlanButton_ScenarioFragment on Scenario {\n  id\n  isMasterPlan\n}\n\nfragment ScenariosTable_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  lastUpdatedAt\n  ...EditScenarioButton_ScenarioFragment\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...MakeMasterPlanButton_ScenarioFragment\n  ...CloneScenarioButton_ScenarioFragment\n  ...ToggleVisibilityButton_ScenarioFragment\n}\n\nfragment ScenariosTable_ScenariosListFragment_2rD0Jv on Query {\n  Scenario {\n    Scenarios(first: $first, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...ScenariosTable_ScenarioFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment ToggleVisibilityButton_ScenarioFragment on Scenario {\n  id\n  isPublic\n  isMasterPlan\n}\n"
  }
};
})();

(node as any).hash = "408c7ecc7b96100afec45146603d873a";

export default node;
