import graphql from "babel-plugin-relay/macro";
import debounce from "lodash.debounce";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { CreateRegionButton } from "./CreateRegionButton";
import { DeleteRegionsButton } from "./DeleteRegionsButton";
import { EditRegionButton } from "./EditRegionButton";
import { ExportRegionsButton } from "./ExportRegionsButton";
import { ImportRegionsButton } from "./ImportRegionsButton";
import {type RegionsTable_Query} from "../../__generated__/RegionsTable_Query.graphql";
import {type RegionsTable_Refetch} from "../../__generated__/RegionsTable_Refetch.graphql";
import {type RegionsTable_RegionListFragment$key} from "../../__generated__/RegionsTable_RegionListFragment.graphql";
import { type RegionFilters, selectRegionFilters } from "../../redux/RegionSlice";
import { TkDataTable } from "../ui/TkDataTable";

const QUERY = graphql`
	query RegionsTable_Query($first: Int, $filterByName: String) {
		...RegionsTable_RegionListFragment @arguments(first: $first, filterByName: $filterByName)
	}
`;

const PROJECTS_FRAGMENT = graphql`
	fragment RegionsTable_RegionListFragment on Query
	@refetchable(queryName: "RegionsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		filterByName: { type: "String" }
	) {
		Region {
			Regions(first: $first, after: $after, filterByName: $filterByName)
				@connection(key: "RegionsTable_Regions") {
				__id
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						name
						...EditRegionButton_RegionFragment
					}
				}
			}
		}
	}
`;

export const RegionsTable = () => {
	const filters = useSelector(selectRegionFilters);

	const [initialLoad, setInitialLoadComplete] = useState(true);
	const data = useLazyLoadQuery<RegionsTable_Query>(QUERY, { first: 20, ...filters });

	const {
		data: {
			Region: {
				Regions: { __id, edges: regions },
			},
		},
		hasNext,
		refetch,
		loadNext,
	} = usePaginationFragment<RegionsTable_Refetch, RegionsTable_RegionListFragment$key>(
		PROJECTS_FRAGMENT,
		data,
	);

	const debouncedRefetch = (filters: RegionFilters) => {
		refetch({ ...filters, first: 20 }, { fetchPolicy: "network-only" });
	};

	const debouncedEventHandler = useMemo(
		() => debounce(debouncedRefetch, 1000),
		// eslint-disable-next-line
		[],
	);

	useEffect(() => {
		if (initialLoad) {
			setInitialLoadComplete(false);
		} else {
			debouncedEventHandler(filters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);
	const [selection, setSelection] = useState<Array<{ id: string }>>([]);

	return (
		<>
			<div className="flex justify-content-end gap-2">
				<ImportRegionsButton />
				<ExportRegionsButton />
				<CreateRegionButton connectionId={__id} />
				<DeleteRegionsButton
					regionIds={selection.map((s) => s.id)}
					connectionIds={[__id]}
				/>
			</div>
			<TkDataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">There are not regions yet.</div>
					</div>
				}
				className="mb-3"
				value={regions?.map((b) => b!.node!) as any[]}
				selectionMode="multiple"
				onSelectionChange={(e) => {
					setSelection(e.value);
				}}
				selection={selection}
			>
				<Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
				<Column
					header="Name"
					sortable
					sortField={"name"}
					body={(row) => {
						return row.name;
					}}
				/>
				<Column
					header="Actions"
					body={(row) => {
						return (
							<div>
								<EditRegionButton className="mr-2" regionFragmentRef={row} />
							</div>
						);
					}}
				/>
			</TkDataTable>

			{hasNext && (
				<div className="flex justify-content-center align-items-center">
					<Button
						type="button"
						className="p-button-secondary"
						disabled={!hasNext}
						onClick={() => loadNext(20)}
					>
						Load more
					</Button>
				</div>
			)}
		</>
	);
};
