/**
 * @generated SignedSource<<139641a674dac31ea01b8539ddd7bee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ProjectSource = "fromDynamics" | "fromDynamicsActivity" | "fromRand" | "manuallyCreated";
import { FragmentRefs } from "relay-runtime";
export type syncProjectFromRandButton_SyncProjectFragment$data = {
  readonly address: {
    readonly city: string;
    readonly country: string;
    readonly latitude: number | null;
    readonly lineOne: string;
    readonly longitude: number | null;
    readonly postalCode: string;
    readonly state: string;
  } | null;
  readonly architectName: string | null;
  readonly avatar: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly budgetedLaborCosts: number | null;
  readonly clientName: string | null;
  readonly comments: string | null;
  readonly division: {
    readonly id: string;
  } | null;
  readonly endDate: any | null;
  readonly generalConditionsPercentage: number | null;
  readonly id: string;
  readonly milestones: ReadonlyArray<{
    readonly date: any;
    readonly name: string;
  }>;
  readonly name: string;
  readonly projectIdentifier: string | null;
  readonly region: {
    readonly id: string;
  } | null;
  readonly skills: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly source: ProjectSource;
  readonly stage: {
    readonly id: string;
  } | null;
  readonly startDate: any | null;
  readonly volume: number | null;
  readonly " $fragmentType": "syncProjectFromRandButton_SyncProjectFragment";
};
export type syncProjectFromRandButton_SyncProjectFragment$key = {
  readonly " $data"?: syncProjectFromRandButton_SyncProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"syncProjectFromRandButton_SyncProjectFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "syncProjectFromRandButton_SyncProjectFragment"
};

(node as any).hash = "43e1917d4015d461bfdaad00599ed444";

export default node;
