/**
 * @generated SignedSource<<b4d4eaaa17f3c15b8f3cf52faabbef63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personDetailsButton_PersonFragment$data = {
  readonly assignmentRole: {
    readonly name: string;
  } | null;
  readonly comment: string | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "personDetailsButton_PersonFragment";
};
export type personDetailsButton_PersonFragment$key = {
  readonly " $data"?: personDetailsButton_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personDetailsButton_PersonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "assignmentRole",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "5839c98b7f833aba79453c7fff0701da";

export default node;
