/**
 * @generated SignedSource<<1c28944140a7af13a285859e57f86c5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectAccountGroupsField_Query$variables = {};
export type selectAccountGroupsField_Query$data = {
  readonly Admin: {
    readonly Management: {
      readonly AccountGroups: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
          };
        } | null> | null;
      };
    };
  };
};
export type selectAccountGroupsField_Query = {
  response: selectAccountGroupsField_Query$data;
  variables: selectAccountGroupsField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementAdminSchema",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 50
              }
            ],
            "concreteType": "AccountGroupsConnection",
            "kind": "LinkedField",
            "name": "AccountGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroupsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountGroup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "AccountGroups(first:50)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectAccountGroupsField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectAccountGroupsField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "22ad194180ca30c57cddce17f93cf353",
    "id": null,
    "metadata": {},
    "name": "selectAccountGroupsField_Query",
    "operationKind": "query",
    "text": "query selectAccountGroupsField_Query {\n  Admin {\n    Management {\n      AccountGroups(first: 50) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01efaa961e30c352627869937b705886";

export default node;
