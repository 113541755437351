/**
 * @generated SignedSource<<f498c3150ef9973a5f54f28f85014520>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillAssessmentTemplatesTable_Refetch$variables = {
  after?: string | null;
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  before?: string | null;
  excludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
  first?: number | null;
  last?: number | null;
};
export type skillAssessmentTemplatesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentTemplatesTable_AssessmentTemplatesFragment">;
};
export type skillAssessmentTemplatesTable_Refetch = {
  response: skillAssessmentTemplatesTable_Refetch$data;
  variables: skillAssessmentTemplatesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "alwaysIncludeIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "excludeIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 250,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "excludeIds",
    "variableName": "excludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessmentTemplatesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "skillAssessmentTemplatesTable_AssessmentTemplatesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessmentTemplatesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssessmentAdminSchema",
            "kind": "LinkedField",
            "name": "Assessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AssessmentTemplateConnection",
                "kind": "LinkedField",
                "name": "AssessmentTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssessmentTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentRole",
                            "kind": "LinkedField",
                            "name": "associatedRoles",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "assessedSkills",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SkillCategory",
                                "kind": "LinkedField",
                                "name": "skillCategory",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DistributionList",
                            "kind": "LinkedField",
                            "name": "distributionList",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emails",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "role",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "alwaysIncludeIds",
                  "filterByName",
                  "excludeIds"
                ],
                "handle": "connection",
                "key": "skillAssessmentTemplatesTable_AssessmentTemplates",
                "kind": "LinkedHandle",
                "name": "AssessmentTemplates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e58e75a4dc70236001f945c47ee9c38e",
    "id": null,
    "metadata": {},
    "name": "skillAssessmentTemplatesTable_Refetch",
    "operationKind": "query",
    "text": "query skillAssessmentTemplatesTable_Refetch(\n  $after: String\n  $alwaysIncludeIds: [ID!]\n  $before: String\n  $excludeIds: [ID!]\n  $filterByName: String\n  $first: Int = 250\n  $last: Int\n) {\n  ...skillAssessmentTemplatesTable_AssessmentTemplatesFragment_1ZJQyk\n}\n\nfragment editTemplateButton_AssessmentTemplateFragment on AssessmentTemplate {\n  name\n  id\n  assessedSkills {\n    id\n    name\n    skillCategory {\n      id\n      name\n    }\n  }\n  associatedRoles {\n    id\n    name\n  }\n  distributionList {\n    role\n    emails\n  }\n}\n\nfragment skillAssessmentTemplatesTable_AssessmentTemplateFragment on AssessmentTemplate {\n  id\n  name\n  associatedRoles {\n    name\n    id\n  }\n  assessedSkills {\n    name\n    id\n  }\n  distributionList {\n    emails\n    role\n  }\n  ...editTemplateButton_AssessmentTemplateFragment\n}\n\nfragment skillAssessmentTemplatesTable_AssessmentTemplatesFragment_1ZJQyk on Query {\n  Admin {\n    Assessment {\n      AssessmentTemplates(alwaysIncludeIds: $alwaysIncludeIds, filterByName: $filterByName, excludeIds: $excludeIds, first: $first, after: $after, before: $before, last: $last) {\n        edges {\n          node {\n            id\n            name\n            ...skillAssessmentTemplatesTable_AssessmentTemplateFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2119692f20960d061b23bfc48c39530";

export default node;
