/**
 * @generated SignedSource<<be3882e78d05311a7a2f07b2ab628fa0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAccountLogoInput = {
  clientMutationId?: string | null;
  logoRef?: string | null;
};
export type ChangeLogoForm_ChangeLogoMutation$variables = {
  input: SetAccountLogoInput;
};
export type ChangeLogoForm_ChangeLogoMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setAccountLogo: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type ChangeLogoForm_ChangeLogoMutation = {
  response: ChangeLogoForm_ChangeLogoMutation$data;
  variables: ChangeLogoForm_ChangeLogoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "SetAccountLogoPayload",
            "kind": "LinkedField",
            "name": "setAccountLogo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeLogoForm_ChangeLogoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeLogoForm_ChangeLogoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9b0f666b677e76a69ed5bf157702a028",
    "id": null,
    "metadata": {},
    "name": "ChangeLogoForm_ChangeLogoMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeLogoForm_ChangeLogoMutation(\n  $input: SetAccountLogoInput!\n) {\n  Admin {\n    Auth {\n      setAccountLogo(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97bd3a29662dea0f713a9013f9ab7895";

export default node;
