/**
 * @generated SignedSource<<c43c484f9f404f463fe3970804c15dba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsSelectField_Query$variables = {
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  excludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
};
export type ProjectsSelectField_Query$data = {
  readonly Project: {
    readonly Projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"ProjectsSelectField_ProjectFragment">;
        };
      } | null> | null;
    };
  };
};
export type ProjectsSelectField_Query = {
  response: ProjectsSelectField_Query$data;
  variables: ProjectsSelectField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v3 = [
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "excludeIds",
    "variableName": "excludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 250
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsSelectField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectQueries",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectsConnection",
            "kind": "LinkedField",
            "name": "Projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "ProjectsSelectField_ProjectFragment",
                        "selections": (v4/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsSelectField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectQueries",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectsConnection",
            "kind": "LinkedField",
            "name": "Projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8739d8eb81651ad8f9fa9c871625a1ca",
    "id": null,
    "metadata": {},
    "name": "ProjectsSelectField_Query",
    "operationKind": "query",
    "text": "query ProjectsSelectField_Query(\n  $filterByName: String\n  $excludeIds: [ID!]\n  $alwaysIncludeIds: [ID!]\n) {\n  Project {\n    Projects(first: 250, excludeIds: $excludeIds, filterByName: $filterByName, alwaysIncludeIds: $alwaysIncludeIds) {\n      edges {\n        node {\n          ...ProjectsSelectField_ProjectFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ProjectsSelectField_ProjectFragment on Project {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "91f5ac2fdc0712db27fe226722934e07";

export default node;
