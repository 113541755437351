/**
 * @generated SignedSource<<c80140ed790eb72f01956dd006d368ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usersInAccountAdminTable_Query$variables = {
  accountId: string;
  first?: number | null;
};
export type usersInAccountAdminTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usersInAccountAdminTable_UsersInAccountListFragment">;
};
export type usersInAccountAdminTable_Query = {
  response: usersInAccountAdminTable_Query$data;
  variables: usersInAccountAdminTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usersInAccountAdminTable_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "usersInAccountAdminTable_UsersInAccountListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "usersInAccountAdminTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UserInAccountConnection",
                "kind": "LinkedField",
                "name": "UsersInAccountAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "activated",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountGroup",
                            "kind": "LinkedField",
                            "name": "groups",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "accountId"
                ],
                "handle": "connection",
                "key": "usersInAccountAdminTable_UsersInAccountAdmin",
                "kind": "LinkedHandle",
                "name": "UsersInAccountAdmin"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abdc0a5bba39d1f551ed62761008be56",
    "id": null,
    "metadata": {},
    "name": "usersInAccountAdminTable_Query",
    "operationKind": "query",
    "text": "query usersInAccountAdminTable_Query(\n  $first: Int\n  $accountId: ID!\n) {\n  ...usersInAccountAdminTable_UsersInAccountListFragment_16yfkd\n}\n\nfragment ChangeUserGroupsAdminButton_UserInAccountFragment on UserInAccount {\n  ...ChangeUserGroupsAdminModal_UserInAccountFragment\n}\n\nfragment ChangeUserGroupsAdminModal_UserInAccountFragment on UserInAccount {\n  id\n  user {\n    id\n  }\n  groups {\n    id\n  }\n}\n\nfragment usersInAccountAdminTable_UserInlineFragment on UserInAccount {\n  id\n  user {\n    id\n    email\n    name\n    activated\n  }\n  groups {\n    id\n    name\n  }\n  ...ChangeUserGroupsAdminButton_UserInAccountFragment\n}\n\nfragment usersInAccountAdminTable_UsersInAccountListFragment_16yfkd on Query {\n  Admin {\n    Management {\n      UsersInAccountAdmin(accountId: $accountId, first: $first) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            ...usersInAccountAdminTable_UserInlineFragment\n            id\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cdf63d592dc18dc1dd676e38e47174f";

export default node;
