/**
 * @generated SignedSource<<689ed760c31d3ddc55023e1bf5045363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personCard_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_ScenarioFragment">;
  readonly " $fragmentType": "personCard_ScenarioFragment";
};
export type personCard_ScenarioFragment$key = {
  readonly " $data"?: personCard_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personCard_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personDetailsButton_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "fe9852fe5609b5971b853656c11d1010";

export default node;
