/**
 * @generated SignedSource<<1cebfd19408b28db186a232b1dac2893>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DecreaseSkillCategorySortOrderInput = {
  clientMutationId?: string | null;
  id: string;
};
export type SkillCategorySortOrderButtons_DecreaseMutation$variables = {
  input: DecreaseSkillCategorySortOrderInput;
};
export type SkillCategorySortOrderButtons_DecreaseMutation$data = {
  readonly Skills: {
    readonly decreaseSkillCategorySortOrder: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly sortOrder: number;
        };
      };
    } | null;
  };
};
export type SkillCategorySortOrderButtons_DecreaseMutation = {
  response: SkillCategorySortOrderButtons_DecreaseMutation$data;
  variables: SkillCategorySortOrderButtons_DecreaseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SkillsMutations",
    "kind": "LinkedField",
    "name": "Skills",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "DecreaseSkillCategorySortOrderPayload",
        "kind": "LinkedField",
        "name": "decreaseSkillCategorySortOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillCategoriesEdge",
            "kind": "LinkedField",
            "name": "edge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sortOrder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillCategorySortOrderButtons_DecreaseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillCategorySortOrderButtons_DecreaseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2b1609f7577d8894f80015c25addf66b",
    "id": null,
    "metadata": {},
    "name": "SkillCategorySortOrderButtons_DecreaseMutation",
    "operationKind": "mutation",
    "text": "mutation SkillCategorySortOrderButtons_DecreaseMutation(\n  $input: DecreaseSkillCategorySortOrderInput!\n) {\n  Skills {\n    decreaseSkillCategorySortOrder(input: $input) {\n      edge {\n        node {\n          id\n          sortOrder\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26431d71d3e027ec5dc4b8d176f20efa";

export default node;
