/**
 * @generated SignedSource<<91cdff6af6478953b3b9f5c7f8db5d8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rosterPart_StaffFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RosterList_StaffFragment">;
  readonly " $fragmentType": "rosterPart_StaffFragment";
};
export type rosterPart_StaffFragment$key = {
  readonly " $data"?: rosterPart_StaffFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rosterPart_StaffFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAllocatedDateMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAllocatedDateMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAssignmentRoles"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDistanceMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDistanceMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDivisions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByFreeDateMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByFreeDateMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGapDaysMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGapDaysMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByRegions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySalaryMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySalaryMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySkills"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUtilizationStatus"
    },
    {
      "defaultValue": 250,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioRef"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortByClosestToProject"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "utilizationWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "rosterPart_StaffFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "filterByAllocatedDateMaximum",
          "variableName": "filterByAllocatedDateMaximum"
        },
        {
          "kind": "Variable",
          "name": "filterByAllocatedDateMinimum",
          "variableName": "filterByAllocatedDateMinimum"
        },
        {
          "kind": "Variable",
          "name": "filterByAssignmentRoles",
          "variableName": "filterByAssignmentRoles"
        },
        {
          "kind": "Variable",
          "name": "filterByDistanceMaximum",
          "variableName": "filterByDistanceMaximum"
        },
        {
          "kind": "Variable",
          "name": "filterByDistanceMinimum",
          "variableName": "filterByDistanceMinimum"
        },
        {
          "kind": "Variable",
          "name": "filterByDivisions",
          "variableName": "filterByDivisions"
        },
        {
          "kind": "Variable",
          "name": "filterByFreeDateMaximum",
          "variableName": "filterByFreeDateMaximum"
        },
        {
          "kind": "Variable",
          "name": "filterByFreeDateMinimum",
          "variableName": "filterByFreeDateMinimum"
        },
        {
          "kind": "Variable",
          "name": "filterByGapDaysMaximum",
          "variableName": "filterByGapDaysMaximum"
        },
        {
          "kind": "Variable",
          "name": "filterByGapDaysMinimum",
          "variableName": "filterByGapDaysMinimum"
        },
        {
          "kind": "Variable",
          "name": "filterByName",
          "variableName": "filterByName"
        },
        {
          "kind": "Variable",
          "name": "filterByRegions",
          "variableName": "filterByRegions"
        },
        {
          "kind": "Variable",
          "name": "filterBySalaryMaximum",
          "variableName": "filterBySalaryMaximum"
        },
        {
          "kind": "Variable",
          "name": "filterBySalaryMinimum",
          "variableName": "filterBySalaryMinimum"
        },
        {
          "kind": "Variable",
          "name": "filterBySkills",
          "variableName": "filterBySkills"
        },
        {
          "kind": "Variable",
          "name": "filterByUtilizationStatus",
          "variableName": "filterByUtilizationStatus"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "scenarioRef",
          "variableName": "scenarioRef"
        },
        {
          "kind": "Variable",
          "name": "sortByClosestToProject",
          "variableName": "sortByClosestToProject"
        },
        {
          "kind": "Variable",
          "name": "utilizationWindow",
          "variableName": "utilizationWindow"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RosterList_StaffFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "81986334979a52bee7ad27288c6e00d6";

export default node;
