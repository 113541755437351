/**
 * @generated SignedSource<<3e692f1c9addab6e8efd527102f5f041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectUserInAccountField_PeopleQuery$variables = {
  accountId: string;
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  excludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
  first?: number | null;
};
export type selectUserInAccountField_PeopleQuery$data = {
  readonly Assessment: {
    readonly PeopleInAccount: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"selectUserInAccountField_PersonFragment">;
        };
      } | null> | null;
    };
  };
};
export type selectUserInAccountField_PeopleQuery = {
  response: selectUserInAccountField_PeopleQuery$data;
  variables: selectUserInAccountField_PeopleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "excludeIds",
    "variableName": "excludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserInAccountField_PeopleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentQueries",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "PeopleConnection",
            "kind": "LinkedField",
            "name": "PeopleInAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PeopleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "selectUserInAccountField_PersonFragment",
                        "selections": (v6/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectUserInAccountField_PeopleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentQueries",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "PeopleConnection",
            "kind": "LinkedField",
            "name": "PeopleInAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PeopleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "238fffecb6817a04c9a8139d0ac7abba",
    "id": null,
    "metadata": {},
    "name": "selectUserInAccountField_PeopleQuery",
    "operationKind": "query",
    "text": "query selectUserInAccountField_PeopleQuery(\n  $accountId: ID!\n  $filterByName: String\n  $alwaysIncludeIds: [ID!]\n  $excludeIds: [ID!]\n  $first: Int\n) {\n  Assessment {\n    PeopleInAccount(accountId: $accountId, first: $first, filterByName: $filterByName, alwaysIncludeIds: $alwaysIncludeIds, excludeIds: $excludeIds) {\n      edges {\n        node {\n          ...selectUserInAccountField_PersonFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment selectUserInAccountField_PersonFragment on Person {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "82c144dc73d65830fe3beab9c8a8f20f";

export default node;
