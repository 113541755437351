import styled from "styled-components";
import {Tag} from "primereact/tag";


export const TkTag = styled(Tag)`
  color: #fff !important;
  padding: 2px 8px !important;
  background: #959DBF !important;
  font-size: 1rem;
  font-weight: normal;

  font-family: 'Noto Sans', sans-serif;

`
