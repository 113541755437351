import { Color } from "@thekeytechnology/framework-react-components";

export const textSubtle = new Color("#667691");
export const textExtraSubtle = new Color("#A1ABBB");
export const primary = new Color("#0169F8");
export const textContrast = new Color("#132C56");
export const borderColor = new Color("#DCDFE6");
export const white = new Color("#FFFFFF");
export const textDefault = new Color("#425678");
export const negative = new Color("#EA3924");
