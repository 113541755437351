/**
 * @generated SignedSource<<d9119a17acf6e0824039422354e4090a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type recoveryCodesScreen_Query$variables = {};
export type recoveryCodesScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly recoveryCodeCredentials: {
        readonly " $fragmentSpreads": FragmentRefs<"recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment">;
      } | null;
      readonly twoFactorAuthToken: {
        readonly data: {
          readonly isActivated: boolean;
        };
      } | null;
    };
  };
};
export type recoveryCodesScreen_Query = {
  response: recoveryCodesScreen_Query$data;
  variables: recoveryCodesScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "RecoveryCodeCredentialsData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "credentials",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TwoFactorAuthTokenData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActivated",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoveryCodesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment",
                    "selections": (v1/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorAuthToken",
                "kind": "LinkedField",
                "name": "twoFactorAuthToken",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "recoveryCodesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorAuthToken",
                "kind": "LinkedField",
                "name": "twoFactorAuthToken",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "614e31ec88c3b8959f9fa5b7d51f069a",
    "id": null,
    "metadata": {},
    "name": "recoveryCodesScreen_Query",
    "operationKind": "query",
    "text": "query recoveryCodesScreen_Query {\n  Viewer {\n    Auth {\n      recoveryCodeCredentials {\n        ...recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment\n        id\n      }\n      twoFactorAuthToken {\n        data {\n          isActivated\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment on RecoveryCodeCredentials {\n  id\n  data {\n    credentials\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4d991b63980816d4f2a26579aa47c36";

export default node;
