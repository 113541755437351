/**
 * @generated SignedSource<<09c8dc09821ced99f0521f30b60c257b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenerateRecoveryCodeCredentialsInput = {
  clientMutationId?: string | null;
};
export type recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation$variables = {
  input: GenerateRecoveryCodeCredentialsInput;
};
export type recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation$data = {
  readonly Auth: {
    readonly generateRecoveryCodeCredentials: {
      readonly clientMutationId: string | null;
      readonly recoveryCodeCredentials: {
        readonly " $fragmentSpreads": FragmentRefs<"recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment">;
      };
    } | null;
  };
};
export type recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation = {
  response: recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation$data;
  variables: recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RecoveryCodeCredentialsData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "credentials",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateRecoveryCodeCredentialsPayload",
            "kind": "LinkedField",
            "name": "generateRecoveryCodeCredentials",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment",
                    "selections": (v3/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateRecoveryCodeCredentialsPayload",
            "kind": "LinkedField",
            "name": "generateRecoveryCodeCredentials",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeCredentials",
                "kind": "LinkedField",
                "name": "recoveryCodeCredentials",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2e7151f46d50a590c1d5da3350a1356",
    "id": null,
    "metadata": {},
    "name": "recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation",
    "operationKind": "mutation",
    "text": "mutation recoveryCodesScreen_GenerateRecoveryCodeCredentialsMutation(\n  $input: GenerateRecoveryCodeCredentialsInput!\n) {\n  Auth {\n    generateRecoveryCodeCredentials(input: $input) {\n      clientMutationId\n      recoveryCodeCredentials {\n        ...recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment\n        id\n      }\n    }\n  }\n}\n\nfragment recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment on RecoveryCodeCredentials {\n  id\n  data {\n    credentials\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfaf78a1870d3031c1554494c0eb443e";

export default node;
