/**
 * @generated SignedSource<<94909160d2673c2bf1fa51f10ade9712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectStagesTab_ProjectStageFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly sortOrder: number | null;
  readonly " $fragmentType": "projectStagesTab_ProjectStageFragment";
};
export type projectStagesTab_ProjectStageFragment$key = {
  readonly " $data"?: projectStagesTab_ProjectStageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectStagesTab_ProjectStageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectStagesTab_ProjectStageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    }
  ],
  "type": "ProjectStage",
  "abstractKey": null
};

(node as any).hash = "c4be79437b61317ff8d9c86112d4fdfb";

export default node;
