/**
 * @generated SignedSource<<1ac1bc1165acb64d1183637a23c28830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSkillCategoryInput = {
  clientMutationId?: string | null;
  data: SkillCategoryDataInput;
};
export type SkillCategoryDataInput = {
  name: string;
};
export type createSkillCategoryButton_CreateSkillCategoryMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateSkillCategoryInput;
};
export type createSkillCategoryButton_CreateSkillCategoryMutation$data = {
  readonly Skills: {
    readonly createSkillCategory: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editSkillCategoryButton_SkillCategoryFragment">;
        };
      };
    } | null;
  };
};
export type createSkillCategoryButton_CreateSkillCategoryMutation = {
  response: createSkillCategoryButton_CreateSkillCategoryMutation$data;
  variables: createSkillCategoryButton_CreateSkillCategoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createSkillCategoryButton_CreateSkillCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateSkillCategoryPayload",
            "kind": "LinkedField",
            "name": "createSkillCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillCategoriesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillCategory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editSkillCategoryButton_SkillCategoryFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createSkillCategoryButton_CreateSkillCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateSkillCategoryPayload",
            "kind": "LinkedField",
            "name": "createSkillCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillCategoriesEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillCategory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f75b6d1f2a383110dea88671344bf51",
    "id": null,
    "metadata": {},
    "name": "createSkillCategoryButton_CreateSkillCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation createSkillCategoryButton_CreateSkillCategoryMutation(\n  $input: CreateSkillCategoryInput!\n) {\n  Skills {\n    createSkillCategory(input: $input) {\n      edge {\n        node {\n          id\n          ...editSkillCategoryButton_SkillCategoryFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editSkillCategoryButton_SkillCategoryFragment on SkillCategory {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "7b591dc96098ef3a35e1b1eebef10631";

export default node;
