/**
 * @generated SignedSource<<98bc265660b3d12693487a51fe13c2f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillMultiSelect_SkillFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "skillMultiSelect_SkillFragment";
};
export type skillMultiSelect_SkillFragment$key = {
  readonly " $data"?: skillMultiSelect_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillMultiSelect_SkillFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "skillMultiSelect_SkillFragment"
};

(node as any).hash = "6d89db75c371f1e373a4ada19c16740c";

export default node;
