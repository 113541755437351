/**
 * @generated SignedSource<<dd0a2646a6cd32d6fa888e8ab6a013f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeAccountGroupsAdminButton_AccountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChangeAccountGroupsAdminModal_AccountFragment">;
  readonly " $fragmentType": "ChangeAccountGroupsAdminButton_AccountFragment";
};
export type ChangeAccountGroupsAdminButton_AccountFragment$key = {
  readonly " $data"?: ChangeAccountGroupsAdminButton_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeAccountGroupsAdminButton_AccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeAccountGroupsAdminButton_AccountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeAccountGroupsAdminModal_AccountFragment"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "829a57745c7a35882410dca5ca509217";

export default node;
