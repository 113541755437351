/**
 * @generated SignedSource<<67cafe1b4088f59416a5ada8c7b9baa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ViewType = "ProjectView" | "StaffView";
export type UpsertViewOptionInput = {
  clientMutationId?: string | null;
  isDefault?: boolean | null;
  name: string;
  url: string;
  viewType: string;
};
export type FilterViewSelector_UpsertMutation$variables = {
  connections: ReadonlyArray<string>;
  input: UpsertViewOptionInput;
};
export type FilterViewSelector_UpsertMutation$data = {
  readonly Views: {
    readonly upsertViewOptions: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly isDefault: boolean;
          readonly name: string;
          readonly url: string;
          readonly viewType: ViewType;
        };
      };
    } | null;
  };
};
export type FilterViewSelector_UpsertMutation = {
  response: FilterViewSelector_UpsertMutation$data;
  variables: FilterViewSelector_UpsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewOptionsEdge",
  "kind": "LinkedField",
  "name": "edge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewOptions",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDefault",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterViewSelector_UpsertMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewsMutations",
        "kind": "LinkedField",
        "name": "Views",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UpsertViewOptionPayload",
            "kind": "LinkedField",
            "name": "upsertViewOptions",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FilterViewSelector_UpsertMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewsMutations",
        "kind": "LinkedField",
        "name": "Views",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UpsertViewOptionPayload",
            "kind": "LinkedField",
            "name": "upsertViewOptions",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9269fd7d6f02c90a748fbf406830b31",
    "id": null,
    "metadata": {},
    "name": "FilterViewSelector_UpsertMutation",
    "operationKind": "mutation",
    "text": "mutation FilterViewSelector_UpsertMutation(\n  $input: UpsertViewOptionInput!\n) {\n  Views {\n    upsertViewOptions(input: $input) {\n      edge {\n        node {\n          id\n          name\n          viewType\n          url\n          isDefault\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a7e0dd397119e9df5f1a615424ee64b";

export default node;
