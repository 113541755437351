/**
 * @generated SignedSource<<14922e33acc9b1b7a640636fd8d870e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardHeader_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentScenarioControl_ScenarioFragment" | "ScenarioStatistics_ScenarioFragment">;
  readonly " $fragmentType": "DashboardHeader_ScenarioFragment";
};
export type DashboardHeader_ScenarioFragment$key = {
  readonly " $data"?: DashboardHeader_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardHeader_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentScenarioControl_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScenarioStatistics_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "8db517f34ab7779fe6daa3d7e463e5e4";

export default node;
