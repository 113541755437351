/**
 * @generated SignedSource<<12a54d7d0c7f654c3b2d683c222aa223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type revertToAssessmentForm_AssessmentFragment$data = {
  readonly id: string;
  readonly person: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly skillAssessments: ReadonlyArray<{
    readonly skill: {
      readonly name: string;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  }>;
  readonly status: {
    readonly finishedAt?: string;
  };
  readonly template: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "revertToAssessmentForm_AssessmentFragment";
};
export type revertToAssessmentForm_AssessmentFragment$key = {
  readonly " $data"?: revertToAssessmentForm_AssessmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"revertToAssessmentForm_AssessmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "revertToAssessmentForm_AssessmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finishedAt",
              "storageKey": null
            }
          ],
          "type": "PdfGenerated",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "skillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "type": "NumericalAssessmentValue",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSkill",
                  "storageKey": null
                }
              ],
              "type": "BinaryAssessmentValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Assessment",
  "abstractKey": null
};
})();

(node as any).hash = "2295fe6f544dd1ad877c72fff483f915";

export default node;
