/**
 * @generated SignedSource<<ff531c954a3b4361c6ab07e96904089f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type categoryForm_AssessmentTemplateFragment$data = {
  readonly assessedSkills: ReadonlyArray<{
    readonly skillCategory: {
      readonly id: string;
      readonly name: string;
      readonly sortOrder: number;
    } | null;
  }>;
  readonly " $fragmentType": "categoryForm_AssessmentTemplateFragment";
};
export type categoryForm_AssessmentTemplateFragment$key = {
  readonly " $data"?: categoryForm_AssessmentTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryForm_AssessmentTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "categoryForm_AssessmentTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "assessedSkills",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillCategory",
          "kind": "LinkedField",
          "name": "skillCategory",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sortOrder",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssessmentTemplate",
  "abstractKey": null
};

(node as any).hash = "52d6adb2c04e9ae264689e2ce03f0bc8";

export default node;
