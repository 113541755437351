/**
 * @generated SignedSource<<a2ff334481c3d307bbfc2588f9c71b89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RosterList_StaffFragment$data = {
  readonly Staff: {
    readonly People: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly address: {
            readonly city: string;
            readonly latitude: number | null;
            readonly lineOne: string;
            readonly longitude: number | null;
            readonly postalCode: string;
            readonly state: string;
          } | null;
          readonly distance: number;
          readonly id: string;
          readonly name: string;
          readonly " $fragmentSpreads": FragmentRefs<"personCardDraggable_PersonFragment" | "personCard_PersonFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly node: {
    readonly utilizationWithStandAndEndDate?: {
      readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioUtilizationFragment">;
    };
  } | null;
  readonly " $fragmentType": "RosterList_StaffFragment";
};
export type RosterList_StaffFragment$key = {
  readonly " $data"?: RosterList_StaffFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RosterList_StaffFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Staff",
  "People"
],
v1 = {
  "kind": "Variable",
  "name": "utilizationWindow",
  "variableName": "utilizationWindow"
},
v2 = [
  {
    "kind": "Variable",
    "name": "scenarioId",
    "variableName": "scenarioRef"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAllocatedDateMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAllocatedDateMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAssignmentRoles"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDistanceMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDistanceMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByDivisions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByFreeDateMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByFreeDateMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGapDaysMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGapDaysMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByRegions"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySalaryMaximum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySalaryMinimum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBySkills"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByStaff"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUtilizationStatus"
    },
    {
      "defaultValue": 250,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioRef"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortByClosestToProject"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "utilizationWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./RosterList_StaffRefetch.graphql')
    }
  },
  "name": "RosterList_StaffFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "scenarioRef"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                (v1/*: any*/)
              ],
              "concreteType": "ScenarioUtilization",
              "kind": "LinkedField",
              "name": "utilizationWithStandAndEndDate",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "personCard_ScenarioUtilizationFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Scenario",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffQueries",
      "kind": "LinkedField",
      "name": "Staff",
      "plural": false,
      "selections": [
        {
          "alias": "People",
          "args": [
            {
              "kind": "Variable",
              "name": "alwaysIncludeIds",
              "variableName": "filterByStaff"
            },
            {
              "kind": "Variable",
              "name": "filterByAllocatedDateMaximum",
              "variableName": "filterByAllocatedDateMaximum"
            },
            {
              "kind": "Variable",
              "name": "filterByAllocatedDateMinimum",
              "variableName": "filterByAllocatedDateMinimum"
            },
            {
              "kind": "Variable",
              "name": "filterByAssignmentRoles",
              "variableName": "filterByAssignmentRoles"
            },
            {
              "kind": "Variable",
              "name": "filterByDistanceMaximum",
              "variableName": "filterByDistanceMaximum"
            },
            {
              "kind": "Variable",
              "name": "filterByDistanceMinimum",
              "variableName": "filterByDistanceMinimum"
            },
            {
              "kind": "Variable",
              "name": "filterByDivisions",
              "variableName": "filterByDivisions"
            },
            {
              "kind": "Variable",
              "name": "filterByFreeDateMaximum",
              "variableName": "filterByFreeDateMaximum"
            },
            {
              "kind": "Variable",
              "name": "filterByFreeDateMinimum",
              "variableName": "filterByFreeDateMinimum"
            },
            {
              "kind": "Variable",
              "name": "filterByGapDaysMaximum",
              "variableName": "filterByGapDaysMaximum"
            },
            {
              "kind": "Variable",
              "name": "filterByGapDaysMinimum",
              "variableName": "filterByGapDaysMinimum"
            },
            {
              "kind": "Variable",
              "name": "filterByName",
              "variableName": "filterByName"
            },
            {
              "kind": "Variable",
              "name": "filterByRegions",
              "variableName": "filterByRegions"
            },
            {
              "kind": "Variable",
              "name": "filterBySalaryMaximum",
              "variableName": "filterBySalaryMaximum"
            },
            {
              "kind": "Variable",
              "name": "filterBySalaryMinimum",
              "variableName": "filterBySalaryMinimum"
            },
            {
              "kind": "Variable",
              "name": "filterBySkills",
              "variableName": "filterBySkills"
            },
            {
              "kind": "Variable",
              "name": "filterByUtilizationStatus",
              "variableName": "filterByUtilizationStatus"
            },
            {
              "kind": "Variable",
              "name": "scenarioRef",
              "variableName": "scenarioRef"
            },
            {
              "kind": "Variable",
              "name": "sortByClosestToProject",
              "variableName": "sortByClosestToProject"
            },
            (v1/*: any*/)
          ],
          "concreteType": "PeopleConnection",
          "kind": "LinkedField",
          "name": "__RosterList_People_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PeopleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": (v2/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "personCardDraggable_PersonFragment"
                    },
                    {
                      "args": (v2/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "personCard_PersonFragment"
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "projectRef",
                          "variableName": "sortByClosestToProject"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "distance",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Address",
                      "kind": "LinkedField",
                      "name": "address",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "latitude",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "longitude",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lineOne",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "postalCode",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "city",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "state",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1da469a0c1703d5a77f4e20fd917784d";

export default node;
