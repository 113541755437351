/**
 * @generated SignedSource<<07fabaead937385a4911b8dbc7d47d0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenariosTable_Refetch$variables = {
  after?: string | null;
  filterByName?: string | null;
  first?: number | null;
};
export type ScenariosTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ScenariosTable_ScenariosListFragment">;
};
export type ScenariosTable_Refetch = {
  response: ScenariosTable_Refetch$data;
  variables: ScenariosTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScenariosTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ScenariosTable_ScenariosListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScenariosTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioQueries",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ScenariosConnection",
            "kind": "LinkedField",
            "name": "Scenarios",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMasterPlan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastUpdatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "filterByName"
            ],
            "handle": "connection",
            "key": "ScenariosTable_Scenarios",
            "kind": "LinkedHandle",
            "name": "Scenarios"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c9ead138de17629bfde346a0dda0363",
    "id": null,
    "metadata": {},
    "name": "ScenariosTable_Refetch",
    "operationKind": "query",
    "text": "query ScenariosTable_Refetch(\n  $after: String\n  $filterByName: String\n  $first: Int = 20\n) {\n  ...ScenariosTable_ScenariosListFragment_3KLhx3\n}\n\nfragment CheckScenarioPermissions_ScenarioFragment on Scenario {\n  isMasterPlan\n}\n\nfragment CloneScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment EditScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment MakeMasterPlanButton_ScenarioFragment on Scenario {\n  id\n  isMasterPlan\n}\n\nfragment ScenariosTable_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  lastUpdatedAt\n  ...EditScenarioButton_ScenarioFragment\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...MakeMasterPlanButton_ScenarioFragment\n  ...CloneScenarioButton_ScenarioFragment\n  ...ToggleVisibilityButton_ScenarioFragment\n}\n\nfragment ScenariosTable_ScenariosListFragment_3KLhx3 on Query {\n  Scenario {\n    Scenarios(first: $first, after: $after, filterByName: $filterByName) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...ScenariosTable_ScenarioFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment ToggleVisibilityButton_ScenarioFragment on Scenario {\n  id\n  isPublic\n  isMasterPlan\n}\n"
  }
};
})();

(node as any).hash = "d39e4806d112797c64880225d693d353";

export default node;
