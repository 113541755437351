/**
 * @generated SignedSource<<e21a00e9962f26c72c34af0c6f2d51f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type availabilityForecastScreen_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_ScenarioFragment">;
  readonly " $fragmentType": "availabilityForecastScreen_ScenarioFragment";
};
export type availabilityForecastScreen_ScenarioFragment$key = {
  readonly " $data"?: availabilityForecastScreen_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"availabilityForecastScreen_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "availabilityForecastScreen_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardHeader_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "f3a212c5f7c4058ff87cc43c59cc752c";

export default node;
