/**
 * @generated SignedSource<<fe4fe05892711a34b0df4689e33a6f98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAssignmentButton_ProjectFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentForm_ProjectFragment">;
  readonly " $fragmentType": "CreateAssignmentButton_ProjectFragment";
};
export type CreateAssignmentButton_ProjectFragment$key = {
  readonly " $data"?: CreateAssignmentButton_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateAssignmentButton_ProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAssignmentButton_ProjectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAssignmentForm_ProjectFragment"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "ec36bee25bb248751f383ce6e18ed22c";

export default node;
