/**
 * @generated SignedSource<<9c74a0f989b62e92d6454d95891f9430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillCategorySortOrderButtons_AssignmentRoleFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly sortOrder: number;
  readonly " $fragmentType": "SkillCategorySortOrderButtons_AssignmentRoleFragment";
};
export type SkillCategorySortOrderButtons_AssignmentRoleFragment$key = {
  readonly " $data"?: SkillCategorySortOrderButtons_AssignmentRoleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCategorySortOrderButtons_AssignmentRoleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillCategorySortOrderButtons_AssignmentRoleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    }
  ],
  "type": "SkillCategory",
  "abstractKey": null
};

(node as any).hash = "e34b5b3691bbf3645c8d5529e33ddd2c";

export default node;
