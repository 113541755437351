/**
 * @generated SignedSource<<2acfa60660c0044c935ad55151ad1c1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillAssessmentTemplatesTable_Query$variables = {
  filterByName?: string | null;
  first?: number | null;
};
export type skillAssessmentTemplatesTable_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentAccount: {
        readonly id: string;
      } | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentTemplatesTable_AssessmentTemplatesFragment">;
};
export type skillAssessmentTemplatesTable_Query = {
  response: skillAssessmentTemplatesTable_Query$data;
  variables: skillAssessmentTemplatesTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerQueryType",
  "kind": "LinkedField",
  "name": "Viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthViewerSchema",
      "kind": "LinkedField",
      "name": "Auth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "currentAccount",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessmentTemplatesTable_Query",
    "selections": [
      (v3/*: any*/),
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "skillAssessmentTemplatesTable_AssessmentTemplatesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "skillAssessmentTemplatesTable_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssessmentAdminSchema",
            "kind": "LinkedField",
            "name": "Assessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AssessmentTemplateConnection",
                "kind": "LinkedField",
                "name": "AssessmentTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssessmentTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentRole",
                            "kind": "LinkedField",
                            "name": "associatedRoles",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "assessedSkills",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SkillCategory",
                                "kind": "LinkedField",
                                "name": "skillCategory",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DistributionList",
                            "kind": "LinkedField",
                            "name": "distributionList",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emails",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "role",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "alwaysIncludeIds",
                  "filterByName",
                  "excludeIds"
                ],
                "handle": "connection",
                "key": "skillAssessmentTemplatesTable_AssessmentTemplates",
                "kind": "LinkedHandle",
                "name": "AssessmentTemplates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6416c832a9b6eba63b2697594ccb88d",
    "id": null,
    "metadata": {},
    "name": "skillAssessmentTemplatesTable_Query",
    "operationKind": "query",
    "text": "query skillAssessmentTemplatesTable_Query(\n  $first: Int\n  $filterByName: String\n) {\n  Viewer {\n    Auth {\n      currentAccount {\n        id\n      }\n    }\n  }\n  ...skillAssessmentTemplatesTable_AssessmentTemplatesFragment_2rD0Jv\n}\n\nfragment editTemplateButton_AssessmentTemplateFragment on AssessmentTemplate {\n  name\n  id\n  assessedSkills {\n    id\n    name\n    skillCategory {\n      id\n      name\n    }\n  }\n  associatedRoles {\n    id\n    name\n  }\n  distributionList {\n    role\n    emails\n  }\n}\n\nfragment skillAssessmentTemplatesTable_AssessmentTemplateFragment on AssessmentTemplate {\n  id\n  name\n  associatedRoles {\n    name\n    id\n  }\n  assessedSkills {\n    name\n    id\n  }\n  distributionList {\n    emails\n    role\n  }\n  ...editTemplateButton_AssessmentTemplateFragment\n}\n\nfragment skillAssessmentTemplatesTable_AssessmentTemplatesFragment_2rD0Jv on Query {\n  Admin {\n    Assessment {\n      AssessmentTemplates(filterByName: $filterByName, first: $first) {\n        edges {\n          node {\n            id\n            name\n            ...skillAssessmentTemplatesTable_AssessmentTemplateFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fdb27bdd5511c66ddec4b90c46aa7eee";

export default node;
