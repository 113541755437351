import tw from "twin.macro";

export const Wrapper = tw.div``;
export const CategoriesWrapper = tw.div`columns-2 mt-6`;
export const HeaderWrapper = tw.div`flex gap-6 items-center`;
export const SkillWrapper = tw.div`flex gap-2 items-center`;
export const SkillValueWrapper = tw.div`w-[5rem] flex items-end justify-center`;
export const YesOrNoWrapper = tw.div`w-[5rem] flex items-center justify-center pr-[1rem]`;
export const SkillsWrapper = tw.div`flex flex-col gap-4`;
export const CategoryWrapper = tw.div`mb-6`;
