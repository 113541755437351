/**
 * @generated SignedSource<<59799e614b91e45c74fd1e7f54b5c446>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeAccountGroupsInput = {
  accountId: string;
  clientMutationId?: string | null;
  groupRefs: ReadonlyArray<string>;
};
export type ChangeAccountGroupsAdminModal_ChangeMutation$variables = {
  input: ChangeAccountGroupsInput;
};
export type ChangeAccountGroupsAdminModal_ChangeMutation$data = {
  readonly Admin: {
    readonly Management: {
      readonly changeAccountGroups: {
        readonly account: {
          readonly groupAssociations: ReadonlyArray<{
            readonly group: {
              readonly id: string;
              readonly name: string;
            } | null;
          }>;
        };
      } | null;
    };
  };
};
export type ChangeAccountGroupsAdminModal_ChangeMutation = {
  response: ChangeAccountGroupsAdminModal_ChangeMutation$data;
  variables: ChangeAccountGroupsAdminModal_ChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountGroupAssociation",
  "kind": "LinkedField",
  "name": "groupAssociations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeAccountGroupsAdminModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "changeAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeAccountGroupsAdminModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "changeAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b111af8518bb4db1175cdef8d2a4dcb2",
    "id": null,
    "metadata": {},
    "name": "ChangeAccountGroupsAdminModal_ChangeMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeAccountGroupsAdminModal_ChangeMutation(\n  $input: ChangeAccountGroupsInput!\n) {\n  Admin {\n    Management {\n      changeAccountGroups(input: $input) {\n        account {\n          groupAssociations {\n            group {\n              id\n              name\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d29409eb4251ffaf9e41164da54a156e";

export default node;
