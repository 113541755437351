/**
 * @generated SignedSource<<881b1574f485cdc76b424c9ddf4c5000>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DownloadPeopleDataFromS3Input = {
  clientMutationId?: string | null;
  limitToPeople?: ReadonlyArray<string> | null;
};
export type importPeoplesoftButton_DownloadPeopleDataFromS3Mutation$variables = {
  input: DownloadPeopleDataFromS3Input;
};
export type importPeoplesoftButton_DownloadPeopleDataFromS3Mutation$data = {
  readonly Peoplesoft: {
    readonly downloadPeopleDataFromS3: {
      readonly clientMutationId: string | null;
      readonly edited: number;
      readonly imported: number;
    } | null;
  };
};
export type importPeoplesoftButton_DownloadPeopleDataFromS3Mutation = {
  response: importPeoplesoftButton_DownloadPeopleDataFromS3Mutation$data;
  variables: importPeoplesoftButton_DownloadPeopleDataFromS3Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PeoplesoftMutations",
    "kind": "LinkedField",
    "name": "Peoplesoft",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "DownloadPeopleDataFromS3Payload",
        "kind": "LinkedField",
        "name": "downloadPeopleDataFromS3",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "edited",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imported",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "importPeoplesoftButton_DownloadPeopleDataFromS3Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "importPeoplesoftButton_DownloadPeopleDataFromS3Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8afa455be7a80f173c16515468053e32",
    "id": null,
    "metadata": {},
    "name": "importPeoplesoftButton_DownloadPeopleDataFromS3Mutation",
    "operationKind": "mutation",
    "text": "mutation importPeoplesoftButton_DownloadPeopleDataFromS3Mutation(\n  $input: DownloadPeopleDataFromS3Input!\n) {\n  Peoplesoft {\n    downloadPeopleDataFromS3(input: $input) {\n      edited\n      imported\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86ad2ea8004922e1c670e88760a9b875";

export default node;
