/**
 * @generated SignedSource<<6c22e3979cf1dc711e0ad29c838f3140>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentRolesTable_AssignmentRolesQueryFragment$data = {
  readonly Assignments: {
    readonly AssignmentRoles: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly countAsFullyAllocatedAtPercentage: number | null;
          readonly countAsOverallocatedAtPercentage: number | null;
          readonly id: string;
          readonly maxNumberOfProjects: number | null;
          readonly name: string;
          readonly sortOrder: number;
          readonly utilizationProjectionCapInMonths: number | null;
          readonly " $fragmentSpreads": FragmentRefs<"AssignmentRoleSortOrderButtons_AssignmentRoleFragment" | "editAssignmentRoleButton_AssignmentRoleFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly " $fragmentType": "assignmentRolesTable_AssignmentRolesQueryFragment";
};
export type assignmentRolesTable_AssignmentRolesQueryFragment$key = {
  readonly " $data"?: assignmentRolesTable_AssignmentRolesQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignmentRolesTable_AssignmentRolesQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Assignments",
  "AssignmentRoles"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./assignmentRolesTable_Refetch.graphql')
    }
  },
  "name": "assignmentRolesTable_AssignmentRolesQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentsQueries",
      "kind": "LinkedField",
      "name": "Assignments",
      "plural": false,
      "selections": [
        {
          "alias": "AssignmentRoles",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByName",
              "variableName": "filterByName"
            }
          ],
          "concreteType": "AssignmentRolesConnection",
          "kind": "LinkedField",
          "name": "__assignmentRolesTable_AssignmentRoles_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentRolesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sortOrder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "maxNumberOfProjects",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "utilizationProjectionCapInMonths",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "countAsFullyAllocatedAtPercentage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "countAsOverallocatedAtPercentage",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "editAssignmentRoleButton_AssignmentRoleFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AssignmentRoleSortOrderButtons_AssignmentRoleFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c6bc28037c2272e72cc7a91f9705e22e";

export default node;
