/**
 * @generated SignedSource<<34195ba4fefb4fb1a8efe0b7326d16cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateRegionInput = {
  clientMutationId?: string | null;
  name: string;
};
export type EditRegionModal_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateRegionInput;
};
export type EditRegionModal_CreateMutation$data = {
  readonly Region: {
    readonly createRegion: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditRegionButton_RegionFragment">;
        };
      };
    } | null;
  };
};
export type EditRegionModal_CreateMutation = {
  response: EditRegionModal_CreateMutation$data;
  variables: EditRegionModal_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRegionModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegionMutations",
        "kind": "LinkedField",
        "name": "Region",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateRegionPayload",
            "kind": "LinkedField",
            "name": "createRegion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegionsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditRegionButton_RegionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditRegionModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegionMutations",
        "kind": "LinkedField",
        "name": "Region",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateRegionPayload",
            "kind": "LinkedField",
            "name": "createRegion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegionsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abf7b07f22c6581040e3d68d3a1895cc",
    "id": null,
    "metadata": {},
    "name": "EditRegionModal_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditRegionModal_CreateMutation(\n  $input: CreateRegionInput!\n) {\n  Region {\n    createRegion(input: $input) {\n      edge {\n        node {\n          id\n          ...EditRegionButton_RegionFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditRegionButton_RegionFragment on Region {\n  ...EditRegionModal_RegionFragment\n}\n\nfragment EditRegionModal_RegionFragment on Region {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "9599e3d45cbaea35fb61941b83d5224f";

export default node;
