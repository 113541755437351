/**
 * @generated SignedSource<<b53fa983c13c09703ed2aa7089603b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteUserToAccountInput = {
  clientMutationId?: string | null;
  email: string;
  groupId?: string | null;
};
export type InviteUserToAccountModal_InviteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: InviteUserToAccountInput;
};
export type InviteUserToAccountModal_InviteMutation$data = {
  readonly Management: {
    readonly inviteUserToAccount: {
      readonly edge: {
        readonly cursor: string;
        readonly node: {
          readonly email: string;
          readonly id: string;
          readonly invitingUserName: string | null;
        };
      };
    } | null;
  };
};
export type InviteUserToAccountModal_InviteMutation = {
  response: InviteUserToAccountModal_InviteMutation$data;
  variables: InviteUserToAccountModal_InviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "InvitationEdge",
  "kind": "LinkedField",
  "name": "edge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invitation",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitingUserName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteUserToAccountModal_InviteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementMutations",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteUserToAccountPayload",
            "kind": "LinkedField",
            "name": "inviteUserToAccount",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteUserToAccountModal_InviteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementMutations",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteUserToAccountPayload",
            "kind": "LinkedField",
            "name": "inviteUserToAccount",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d94da7af8e58d7a6f719cdb2d5736ef",
    "id": null,
    "metadata": {},
    "name": "InviteUserToAccountModal_InviteMutation",
    "operationKind": "mutation",
    "text": "mutation InviteUserToAccountModal_InviteMutation(\n  $input: InviteUserToAccountInput!\n) {\n  Management {\n    inviteUserToAccount(input: $input) {\n      edge {\n        cursor\n        node {\n          id\n          email\n          invitingUserName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e88be3d9142f84e349c977435cc991f";

export default node;
