/**
 * @generated SignedSource<<916bc7aab7b9cc16ec2f8b55eb95962d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AssessmentStatusKind = "Finished" | "InProgress" | "PdfGenerated";
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type skillAssessmentsTable_AssessmentInlineFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly person: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly skillAssessments: ReadonlyArray<{
    readonly skill: {
      readonly dimension: {
        readonly kind: SkillDimensionTypeEnum;
      };
      readonly id: string;
      readonly name: string;
      readonly skillCategory: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null;
    readonly value: {
      readonly hasSkill?: boolean;
      readonly kind: SkillDimensionTypeEnum;
      readonly number?: number;
    };
  }>;
  readonly status: {
    readonly file?: {
      readonly id: string;
      readonly name: string;
      readonly url: string | null;
    } | null;
    readonly finishedAt?: string;
    readonly kind: AssessmentStatusKind;
    readonly lastUpdate?: string;
  };
  readonly supervisor: {
    readonly name: string;
  } | null;
  readonly template: {
    readonly assessedSkills: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly associatedRoles: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly id: string;
    readonly name: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"revertToAssessmentButton_AssessmentFragment">;
  readonly " $fragmentType": "skillAssessmentsTable_AssessmentInlineFragment";
};
export type skillAssessmentsTable_AssessmentInlineFragment$key = {
  readonly " $data"?: skillAssessmentsTable_AssessmentInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessmentsTable_AssessmentInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "skillAssessmentsTable_AssessmentInlineFragment"
};

(node as any).hash = "e9a636cbc9512d39fc48915c37e03280";

export default node;
