/**
 * @generated SignedSource<<33dd510317b8a4ac482536ba06bd6b9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSkillInput = {
  clientMutationId?: string | null;
  data: SkillDataInput;
  skillId: string;
};
export type SkillDataInput = {
  description?: string | null;
  dimension: SkillDimension;
  name: string;
  skillCategoryId: string;
};
export type SkillDimension = {
  binary?: BinaryDimensionInput | null;
  numerical?: NumericalDimensionInput | null;
};
export type NumericalDimensionInput = {
  dimensionCount: number;
  dimensionExplanations: ReadonlyArray<string>;
  kind: string;
};
export type BinaryDimensionInput = {
  kind: string;
};
export type editSkillButton_EditMutation$variables = {
  input: EditSkillInput;
};
export type editSkillButton_EditMutation$data = {
  readonly Skills: {
    readonly editSkill: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editSkillButton_SkillFragment">;
        };
      };
    } | null;
  };
};
export type editSkillButton_EditMutation = {
  response: editSkillButton_EditMutation$data;
  variables: editSkillButton_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editSkillButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditSkillPayload",
            "kind": "LinkedField",
            "name": "editSkill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editSkillButton_SkillFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editSkillButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillsMutations",
        "kind": "LinkedField",
        "name": "Skills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditSkillPayload",
            "kind": "LinkedField",
            "name": "editSkill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillCategory",
                        "kind": "LinkedField",
                        "name": "skillCategory",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "dimension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dimensionCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dimensionExplanations",
                                "storageKey": null
                              }
                            ],
                            "type": "NumericalDimension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5efcc5414da5433d4b18d0f665b8ac37",
    "id": null,
    "metadata": {},
    "name": "editSkillButton_EditMutation",
    "operationKind": "mutation",
    "text": "mutation editSkillButton_EditMutation(\n  $input: EditSkillInput!\n) {\n  Skills {\n    editSkill(input: $input) {\n      edge {\n        node {\n          id\n          ...editSkillButton_SkillFragment\n        }\n      }\n    }\n  }\n}\n\nfragment editSkillButton_SkillFragment on Skill {\n  id\n  name\n  skillCategory {\n    id\n  }\n  description\n  dimension {\n    __typename\n    kind\n    ... on NumericalDimension {\n      kind\n      dimensionCount\n      dimensionExplanations\n    }\n    ... on BinaryDimension {\n      kind\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ad875a54a3bb0216e4c33e764675503";

export default node;
