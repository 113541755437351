/**
 * @generated SignedSource<<332a1a9ecb7af8ae08236485a048f58e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rosterListActiveFilters_RegionFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "rosterListActiveFilters_RegionFragment";
};
export type rosterListActiveFilters_RegionFragment$key = {
  readonly " $data"?: rosterListActiveFilters_RegionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rosterListActiveFilters_RegionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rosterListActiveFilters_RegionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Region",
  "abstractKey": null
};

(node as any).hash = "1369e0e927672689d7cbbbffe6ed8c83";

export default node;
