/**
 * @generated SignedSource<<726f87cfefb0beebf011784864352a5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAssessmentTemplateInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteAssessmentTemplateInput;
};
export type skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation$data = {
  readonly Assessment: {
    readonly deleteAssessmentTemplate: {
      readonly clientMutationId: string | null;
      readonly deletedIds: ReadonlyArray<string>;
    } | null;
  };
};
export type skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation = {
  response: skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation$data;
  variables: skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteAssessmentTemplatePayload",
            "kind": "LinkedField",
            "name": "deleteAssessmentTemplate",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentMutations",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteAssessmentTemplatePayload",
            "kind": "LinkedField",
            "name": "deleteAssessmentTemplate",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "deletedIds",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b007aec14a9f7621af9c5398df4f400b",
    "id": null,
    "metadata": {},
    "name": "skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation skillAssessmentTemplatesTable_DeleteAssessmentTemplateMutation(\n  $input: DeleteAssessmentTemplateInput!\n) {\n  Assessment {\n    deleteAssessmentTemplate(input: $input) {\n      clientMutationId\n      deletedIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a67bc824253700c3e848f5fa169b76c";

export default node;
