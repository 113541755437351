/**
 * @generated SignedSource<<7260f63b11f87d5fb570b85d3e88b9b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rosterPart_ScenarioFragment$data = {
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"LoadDriveTimesButton_ProjectFragment">;
        };
      };
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RosterList_ScenarioFragment" | "rosterListActiveFilters_ScenarioFragment">;
  readonly " $fragmentType": "rosterPart_ScenarioFragment";
};
export type rosterPart_ScenarioFragment$key = {
  readonly " $data"?: rosterPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rosterPart_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rosterPart_ScenarioFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RosterList_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "rosterListActiveFilters_ScenarioFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectsInScenarioConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectsInScenarioEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectInScenario",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LoadDriveTimesButton_ProjectFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "79a442ef817060fe2544a3d457b53da5";

export default node;
