/**
 * @generated SignedSource<<2cdcd904d9a3736df0caf94e9d50dcdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment$data = {
  readonly data: {
    readonly credentials: ReadonlyArray<string>;
  };
  readonly id: string;
  readonly " $fragmentType": "recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment";
};
export type recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment$key = {
  readonly " $data"?: recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "recoveryCodesScreen_RecoveryCodeCredentialsInlineFragment"
};

(node as any).hash = "081b84472dd8ca0ee32544fdc19e40d2";

export default node;
