/**
 * @generated SignedSource<<391d6ae6fa05f4b3cd4cff86cc1ea112>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillsDisplay_PersonFragment$data = {
  readonly id: string;
  readonly skills: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"skillsDisplay_SkillAssociationInlineFragment">;
      };
    } | null> | null;
  };
  readonly " $fragmentType": "skillsDisplay_PersonFragment";
};
export type skillsDisplay_PersonFragment$key = {
  readonly " $data"?: skillsDisplay_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"skillsDisplay_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "skillsDisplay_PersonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "SkillAssociationsConnection",
      "kind": "LinkedField",
      "name": "skills",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillAssociationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillAssociation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "skillsDisplay_SkillAssociationInlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillAssociationData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "number",
                                  "storageKey": null
                                }
                              ],
                              "type": "NumericalAssessmentValue",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "hasSkill",
                                  "storageKey": null
                                },
                                (v1/*: any*/)
                              ],
                              "type": "BinaryAssessmentValue",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Skill",
                          "kind": "LinkedField",
                          "name": "skill",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "dimension",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "dimensionCount",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "NumericalDimension",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SkillCategory",
                              "kind": "LinkedField",
                              "name": "skillCategory",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "skills(first:100)"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "2dcc65cf8dfc45c9113113fce86e51ee";

export default node;
