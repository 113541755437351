/**
 * @generated SignedSource<<9154b85f771080656108884cce4329f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editPersonSkillAssociationsModalContent_PersonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly skills: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly data: {
          readonly skill: {
            readonly dimension: {
              readonly dimensionCount?: number;
              readonly dimensionExplanations?: ReadonlyArray<string>;
              readonly kind?: SkillDimensionTypeEnum;
            };
            readonly id: string;
            readonly name: string;
          } | null;
          readonly value: {
            readonly hasSkill?: boolean;
            readonly kind?: SkillDimensionTypeEnum;
            readonly number?: number;
          };
        };
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"editSkillAssociationModal_SkillAssociationFragment">;
      };
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"editSkillAssociationModal_PersonFragment">;
  readonly " $fragmentType": "editPersonSkillAssociationsModalContent_PersonFragment";
};
export type editPersonSkillAssociationsModalContent_PersonFragment$key = {
  readonly " $data"?: editPersonSkillAssociationsModalContent_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editPersonSkillAssociationsModalContent_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "skills"
        ]
      }
    ]
  },
  "name": "editPersonSkillAssociationsModalContent_PersonFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": "skills",
      "args": null,
      "concreteType": "SkillAssociationsConnection",
      "kind": "LinkedField",
      "name": "__Person_skills_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillAssociationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillAssociation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillAssociationData",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "number",
                              "storageKey": null
                            }
                          ],
                          "type": "NumericalAssessmentValue",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hasSkill",
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "type": "BinaryAssessmentValue",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "skill",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "dimension",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "dimensionCount",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "dimensionExplanations",
                                  "storageKey": null
                                }
                              ],
                              "type": "NumericalDimension",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/)
                              ],
                              "type": "BinaryDimension",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "editSkillAssociationModal_SkillAssociationFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editSkillAssociationModal_PersonFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "4b4a157c67a0a35288b7822221cff163";

export default node;
