/**
 * @generated SignedSource<<726b1e0ca93b4238ee24892a10c3767d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectSource = "fromDynamics" | "fromDynamicsActivity" | "fromRand" | "manuallyCreated";
import { FragmentRefs } from "relay-runtime";
export type addProjectToScenarioModal_ScenarioFragment$data = {
  readonly id: string;
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly project: {
          readonly name: string;
          readonly source: ProjectSource;
        };
      };
    } | null> | null;
  };
  readonly " $fragmentType": "addProjectToScenarioModal_ScenarioFragment";
};
export type addProjectToScenarioModal_ScenarioFragment$key = {
  readonly " $data"?: addProjectToScenarioModal_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioModal_ScenarioFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "addProjectToScenarioModal_ScenarioFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectsInScenarioConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectsInScenarioEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectInScenario",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "source",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();

(node as any).hash = "89719faf909d608b7d1729906f80347d";

export default node;
