/**
 * @generated SignedSource<<c31c01286e71301c0a81f25747002fe2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsGridPart_ScenarioFragment$data = {
  readonly id: string;
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignments: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly isExecutive: boolean;
              readonly person: {
                readonly assignmentRole: {
                  readonly id: string;
                  readonly name: string;
                } | null;
                readonly id: string;
              } | null;
              readonly project: {
                readonly id: string;
              };
              readonly validAssignmentRoles: ReadonlyArray<{
                readonly name: string;
              }>;
            };
          } | null> | null;
        };
        readonly id: string;
        readonly project: {
          readonly division: {
            readonly id: string;
          } | null;
          readonly endDate: any | null;
          readonly name: string;
          readonly region: {
            readonly id: string;
          } | null;
          readonly stage: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly startDate: any | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"projectCard_ProjectFragment">;
      };
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioCard_ScenarioFragment" | "loadPursuitProjectsFromRandDwhButton_ScenarioFragment" | "projectCard_ScenarioFragment" | "projectViewFiltersPart_ScenarioFragment" | "syncWithRandPreconDwhButton_ScenarioFragment" | "updateAssignmentsFromDynamicsButton_ScenarioFragment">;
  readonly " $fragmentType": "ProjectsGridPart_ScenarioFragment";
};
export type ProjectsGridPart_ScenarioFragment$key = {
  readonly " $data"?: ProjectsGridPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsGridPart_ScenarioFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsGridPart_ScenarioFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectsInScenarioConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectsInScenarioEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectInScenario",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStage",
                      "kind": "LinkedField",
                      "name": "stage",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Division",
                      "kind": "LinkedField",
                      "name": "division",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Region",
                      "kind": "LinkedField",
                      "name": "region",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentTypesConnection",
                  "kind": "LinkedField",
                  "name": "assignments",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssignmentTypesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Assignment",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isExecutive",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AssignmentRole",
                              "kind": "LinkedField",
                              "name": "validAssignmentRoles",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Project",
                              "kind": "LinkedField",
                              "name": "project",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "person",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AssignmentRole",
                                  "kind": "LinkedField",
                                  "name": "assignmentRole",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "projectCard_ProjectFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "addProjectToScenarioCard_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectCard_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "updateAssignmentsFromDynamicsButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectViewFiltersPart_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "syncWithRandPreconDwhButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "loadPursuitProjectsFromRandDwhButton_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();

(node as any).hash = "9c6942c2655e59e2bde7175464675f99";

export default node;
