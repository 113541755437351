/**
 * @generated SignedSource<<8ee4267a13b512b91aee8a0e9353f9de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type skillAssessment_Query$variables = {
  accountId: string;
  password?: string | null;
  personId?: string | null;
  templateId?: string | null;
};
export type skillAssessment_Query$data = {
  readonly Assessment: {
    readonly AccountLogo: {
      readonly url: string | null;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"skillAssessment_GetLastUpdatedDateQuery">;
};
export type skillAssessment_Query = {
  response: skillAssessment_Query$data;
  variables: skillAssessment_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "personId",
    "variableName": "personId"
  },
  {
    "kind": "Variable",
    "name": "templateId",
    "variableName": "templateId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "skillAssessment_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentQueries",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "AccountLogo",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "skillAssessment_GetLastUpdatedDateQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "skillAssessment_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssessmentQueries",
        "kind": "LinkedField",
        "name": "Assessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "AccountLogo",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "GetContinueFromDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5328dd176e79b3d0c1afbe3992e4463f",
    "id": null,
    "metadata": {},
    "name": "skillAssessment_Query",
    "operationKind": "query",
    "text": "query skillAssessment_Query(\n  $accountId: ID!\n  $password: String\n  $personId: ID\n  $templateId: ID\n) {\n  Assessment {\n    AccountLogo(accountId: $accountId) {\n      url\n      id\n    }\n  }\n  ...skillAssessment_GetLastUpdatedDateQuery_4HKWD\n}\n\nfragment skillAssessment_GetLastUpdatedDateQuery_4HKWD on Query {\n  Assessment {\n    GetContinueFromDate(accountId: $accountId, password: $password, personId: $personId, templateId: $templateId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf4515a98b834a0ae60167af61a0193c";

export default node;
