/**
 * @generated SignedSource<<d08f0731ea93518ff90edb0a21b645d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type categoryForm_SkillCategoryFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly sortOrder: number;
  readonly " $fragmentType": "categoryForm_SkillCategoryFragment";
};
export type categoryForm_SkillCategoryFragment$key = {
  readonly " $data"?: categoryForm_SkillCategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryForm_SkillCategoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "categoryForm_SkillCategoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    }
  ],
  "type": "SkillCategory",
  "abstractKey": null
};

(node as any).hash = "8fdab07cf3b39ce4425df82db86f8dcc";

export default node;
