/**
 * @generated SignedSource<<221a54b6a6c8619bcc8b31fd88ddb94d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SwitchAccountInput = {
  clientMutationId?: string | null;
  refreshToken: string;
  targetAccount: string;
};
export type AccountSwitcher_SwitchAccountMutation$variables = {
  input: SwitchAccountInput;
};
export type AccountSwitcher_SwitchAccountMutation$data = {
  readonly Auth: {
    readonly switchAccount: {
      readonly jwtTokens: {
        readonly accessToken: string;
        readonly refreshToken: string;
      };
    } | null;
  };
};
export type AccountSwitcher_SwitchAccountMutation = {
  response: AccountSwitcher_SwitchAccountMutation$data;
  variables: AccountSwitcher_SwitchAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SwitchAccountPayload",
        "kind": "LinkedField",
        "name": "switchAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtLoginInformation",
            "kind": "LinkedField",
            "name": "jwtTokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refreshToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSwitcher_SwitchAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountSwitcher_SwitchAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9dabd7a7805bbb53d074df523ee268f",
    "id": null,
    "metadata": {},
    "name": "AccountSwitcher_SwitchAccountMutation",
    "operationKind": "mutation",
    "text": "mutation AccountSwitcher_SwitchAccountMutation(\n  $input: SwitchAccountInput!\n) {\n  Auth {\n    switchAccount(input: $input) {\n      jwtTokens {\n        refreshToken\n        accessToken\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8167b8a017daa3387739ba0a110e7d9d";

export default node;
