/**
 * @generated SignedSource<<e98b3ce046c92a0d8daa807fbb2651c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditUsersInAccountAdminModal_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly registeredAt: string;
  readonly " $fragmentType": "EditUsersInAccountAdminModal_AccountFragment";
};
export type EditUsersInAccountAdminModal_AccountFragment$key = {
  readonly " $data"?: EditUsersInAccountAdminModal_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditUsersInAccountAdminModal_AccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditUsersInAccountAdminModal_AccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAt",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "c548a81bdad61920b9625b3519a0460f";

export default node;
