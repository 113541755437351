/**
 * @generated SignedSource<<de692049069ce61f16934e0d159b5c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPersonModal_PersonFragment$data = {
  readonly address: {
    readonly city: string;
    readonly country: string;
    readonly latitude: number | null;
    readonly lineOne: string;
    readonly longitude: number | null;
    readonly postalCode: string;
    readonly state: string;
  } | null;
  readonly assignmentRole: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly associatedWithDivisions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly associatedWithRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly avatar: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly comment: string | null;
  readonly email: string | null;
  readonly id: string;
  readonly laborBurdenMultiplier: number | null;
  readonly name: string;
  readonly phone: string | null;
  readonly salary: number | null;
  readonly startDate: any | null;
  readonly " $fragmentType": "EditPersonModal_PersonFragment";
};
export type EditPersonModal_PersonFragment$key = {
  readonly " $data"?: EditPersonModal_PersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPersonModal_PersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPersonModal_PersonFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "laborBurdenMultiplier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "assignmentRole",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Division",
      "kind": "LinkedField",
      "name": "associatedWithDivisions",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "associatedWithRegions",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lineOne",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "fc38d56634b597f3dd7a2cb8130d1ca4";

export default node;
