import { PeopleFiltersComponent } from "./parts/people-filters.component";
import { PeopleTable } from "../../components/relay/PeopleTable";
import { SettingsScreenTemplate } from "@components/settings-screen-template/settings-screen-template.component";

export const PeopleScreen = () => {
	return (
		<SettingsScreenTemplate
			title={"People"}
			Filters={PeopleFiltersComponent}
			Table={PeopleTable}
		/>
	);
};
