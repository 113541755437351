import { FormikHelpers, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { FormikProps } from "formik/dist/types";
import * as Yup from "yup";
import { ValidatedField } from "./ValidatedField";
import { AssignmentRolesSelect } from "../relay/AssignmentRolesSelect";
import { PersonSelect } from "../relay/PersonSelect";
import {
	DefaultCalendarComponent,
	DefaultPercentageFieldComponent,
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "./DefaultTextInput";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { EditAssignmentForm_ProjectFragment$key } from "../../__generated__/EditAssignmentForm_ProjectFragment.graphql";
import { TkButtonLink } from "./TkButtonLink";
import { Form } from "@thekeytechnology/framework-react-components";

const PROJECT_FRAGMENT = graphql`
	fragment EditAssignmentForm_ProjectFragment on Project {
		startDate
		endDate
	}
`;

export interface EditAssignmentFormState {
	validAssignmentRolesRef?: string[];
	personRef?: string;
	startDate?: string;
	endDate?: string;
	importId?: string;

	isExecutive?: boolean;
	comment?: string;
	weight?: number;
}

interface OwnProps {
	projectFragmentRef: EditAssignmentForm_ProjectFragment$key;
	initialState?: EditAssignmentFormState;
	onSubmit: (
		values: EditAssignmentFormState,
		formikHelpers: FormikHelpers<EditAssignmentFormState>,
	) => void;
}

export const EditAssignmentForm = React.forwardRef<FormikProps<EditAssignmentFormState>, OwnProps>(
	({ initialState, onSubmit, projectFragmentRef }, ref) => {
		const project = useFragment<EditAssignmentForm_ProjectFragment$key>(
			PROJECT_FRAGMENT,
			projectFragmentRef,
		);

		const formik = useFormik<EditAssignmentFormState>({
			initialValues: {
				validAssignmentRolesRef: initialState?.validAssignmentRolesRef || undefined,
				personRef: initialState?.personRef || undefined,
				startDate: initialState?.startDate || undefined,
				endDate: initialState?.endDate || undefined,
				importId: initialState?.importId,
				isExecutive: initialState?.isExecutive,
				comment: initialState?.comment,
				weight: initialState?.weight,
			},
			enableReinitialize: true,
			validationSchema: Yup.object().shape({
				validAssignmentRolesRef: Yup.array()
					.min(1, "At least one role is required.")
					.required("Valid roles is a required field."),
				startDate: Yup.string().required("Start date is a required field."),
				endDate: Yup.string()
					.required("Start date is a required field.")
					.test("yourTestCondition", function (value) {
						if (!value) {
							return this.createError({ path: "endDate", message: "" });
						}
						const end = new Date(value);
						const startDateString = this.parent.startDate;

						if (startDateString) {
							const startDate = new Date(startDateString);

							if (startDate > end) {
								return this.createError({
									path: "endDate",
									message: "End date needs to be on or after start date.",
								});
							}
						}
						return true;
					}),
			}),
			onSubmit: onSubmit,
		});

		useImperativeHandle(ref, () => ({
			...formik,
		}));

		return (
			<Form onSubmit={formik.handleSubmit}>
				<ValidatedField<EditAssignmentFormState, string[]>
					className="mb-4"
					name={"validAssignmentRolesRef"}
					label={"Valid Roles"}
					required={true}
					placeholder={"Choose roles"}
					formikConfig={formik}
					component={AssignmentRolesSelect}
				/>
				<ValidatedField<EditAssignmentFormState, boolean>
					className="mb-4"
					name={"isExecutive"}
					label={"Is Executive"}
					required={true}
					formikConfig={formik}
					component={DefaultSwitchComponent}
				/>
				<ValidatedField<EditAssignmentFormState, string>
					className="mb-4"
					name={"personRef"}
					label={"Assigned person"}
					formikConfig={formik}
					component={PersonSelect}
				/>

				<TkButtonLink
					className="mb-1"
					type="button"
					label={"Use project's start and end dates"}
					onClick={() => {
						formik.setFieldValue("startDate", project.startDate);
						formik.setFieldValue("endDate", project.endDate);
					}}
				/>
				<ValidatedField<EditAssignmentFormState, string>
					className="mb-4"
					name={"startDate"}
					label={"Start date"}
					required={true}
					formikConfig={formik}
					component={DefaultCalendarComponent}
				/>
				<ValidatedField<EditAssignmentFormState, string>
					className="mb-4"
					name={"endDate"}
					label={"End date"}
					required={true}
					formikConfig={formik}
					component={DefaultCalendarComponent}
				/>
				<ValidatedField<EditAssignmentFormState, string>
					className="mb-4"
					name={"importId"}
					label={"Import ID"}
					helpText={
						"If you're using an external data source, you can specify an external ID here which is used to associate imported data with already imported data."
					}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<EditAssignmentFormState, string>
					className="mb-4"
					name={"comment"}
					label={"Comment"}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>

				<ValidatedField<EditAssignmentFormState, number>
					className="mb-4"
					name={"weight"}
					label={"Weight"}
					iconClass="pi pi-percentage"
					helpText={
						"The weight of the assignment determines to what percentage it's counted in the utilization algorithm. "
					}
					formikConfig={formik}
					placeholder={"100%"}
					component={DefaultPercentageFieldComponent}
				/>
			</Form>
		);
	},
);
