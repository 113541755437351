/**
 * @generated SignedSource<<57d9698afa08de4f6fcd1f495e4a168e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentCard_AssignmentFragment$data = {
  readonly comment: string | null;
  readonly endDate: any;
  readonly id: string;
  readonly isExecutive: boolean;
  readonly person: {
    readonly assignmentRole: {
      readonly id: string;
    } | null;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"personDetailsButton_PersonFragment">;
  } | null;
  readonly project: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentButton_ProjectFragment">;
  };
  readonly startDate: any;
  readonly validAssignmentRoles: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteAssignmentButton_AssignmentFragment" | "EditAssignmentButton_AssignmentFragment" | "EmptyAssignmentButton_AssignmentFragment">;
  readonly " $fragmentType": "AssignmentCard_AssignmentFragment";
};
export type AssignmentCard_AssignmentFragment$key = {
  readonly " $data"?: AssignmentCard_AssignmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_AssignmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentCard_AssignmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentRole",
          "kind": "LinkedField",
          "name": "assignmentRole",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "personDetailsButton_PersonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditAssignmentButton_ProjectFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "validAssignmentRoles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExecutive",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAssignmentButton_AssignmentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmptyAssignmentButton_AssignmentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteAssignmentButton_AssignmentFragment"
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "c4a748027bcb78b6c46462b208b08637";

export default node;
