/**
 * @generated SignedSource<<16f4aed50d5a8988ebd2fe9abd837b7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type changeUserInAccountGroupsButton_UserInAccountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"changeUserInAccountGroupsModal_UserInAccountFragment">;
  readonly " $fragmentType": "changeUserInAccountGroupsButton_UserInAccountFragment";
};
export type changeUserInAccountGroupsButton_UserInAccountFragment$key = {
  readonly " $data"?: changeUserInAccountGroupsButton_UserInAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"changeUserInAccountGroupsButton_UserInAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "changeUserInAccountGroupsButton_UserInAccountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "changeUserInAccountGroupsModal_UserInAccountFragment"
    }
  ],
  "type": "UserInAccount",
  "abstractKey": null
};

(node as any).hash = "062b81dccd7f6173faedef563f940cb9";

export default node;
