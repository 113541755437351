/**
 * @generated SignedSource<<bb237ea3d4a1d8553064de40bc36d6dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProjectFromDynamicsSelect_Query$variables = {};
export type ProjectFromDynamicsSelect_Query$data = {
  readonly Dynamics: {
    readonly NotYetImportedProjectsFromDynamics: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly projectIdentifier: string | null;
    }>;
  };
};
export type ProjectFromDynamicsSelect_Query = {
  response: ProjectFromDynamicsSelect_Query$data;
  variables: ProjectFromDynamicsSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DynamicsQueries",
    "kind": "LinkedField",
    "name": "Dynamics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "NotYetImportedProjectsFromDynamics",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectIdentifier",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectFromDynamicsSelect_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectFromDynamicsSelect_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "224cb622d6191cc8b9ae10792cff2f05",
    "id": null,
    "metadata": {},
    "name": "ProjectFromDynamicsSelect_Query",
    "operationKind": "query",
    "text": "query ProjectFromDynamicsSelect_Query {\n  Dynamics {\n    NotYetImportedProjectsFromDynamics {\n      id\n      name\n      projectIdentifier\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2e79daf210a042d20faee24b69d996d";

export default node;
