/**
 * @generated SignedSource<<f5b824f73277b04699e6d0c28040701b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenarioProjectViewScreen_ScenarioFragment$data = {
  readonly id: string;
  readonly utilizationWithStandAndEndDate: {
    readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioUtilizationFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_ScenarioFragment" | "ProjectsGridPart_ScenarioFragment" | "rosterPart_ScenarioFragment">;
  readonly " $fragmentType": "ScenarioProjectViewScreen_ScenarioFragment";
};
export type ScenarioProjectViewScreen_ScenarioFragment$key = {
  readonly " $data"?: ScenarioProjectViewScreen_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScenarioProjectViewScreen_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "utilizationWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioProjectViewScreen_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectsGridPart_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "rosterPart_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardHeader_ScenarioFragment"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "utilizationWindow",
          "variableName": "utilizationWindow"
        }
      ],
      "concreteType": "ScenarioUtilization",
      "kind": "LinkedField",
      "name": "utilizationWithStandAndEndDate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "personCard_ScenarioUtilizationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "297cac32d948df19ec8cb76dc47eef8a";

export default node;
