/**
 * @generated SignedSource<<f235edcfff7d22d4b8cfe845794bde61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserScenariosTable_Refetch$variables = {
  after?: string | null;
  first?: number | null;
  onlyShowMine?: boolean | null;
};
export type UserScenariosTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserScenariosTable_ScenariosListFragment">;
};
export type UserScenariosTable_Refetch = {
  response: UserScenariosTable_Refetch$data;
  variables: UserScenariosTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlyShowMine"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "onlyShowMine",
    "variableName": "onlyShowMine"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserScenariosTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserScenariosTable_ScenariosListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserScenariosTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScenarioQueries",
        "kind": "LinkedField",
        "name": "Scenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ScenariosConnection",
            "kind": "LinkedField",
            "name": "Scenarios",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenariosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scenario",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMasterPlan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastUpdatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScenarioGapDays",
                        "kind": "LinkedField",
                        "name": "gapDays",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "gapDays",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "gapSalary",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScenarioUtilization",
                        "kind": "LinkedField",
                        "name": "utilization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unusedSalary",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "averageUtilizationPercentage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "onlyShowMine"
            ],
            "handle": "connection",
            "key": "UserScenariosTable_Scenarios",
            "kind": "LinkedHandle",
            "name": "Scenarios"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4188ca38ada5c141d39eace9f668c9a6",
    "id": null,
    "metadata": {},
    "name": "UserScenariosTable_Refetch",
    "operationKind": "query",
    "text": "query UserScenariosTable_Refetch(\n  $after: String\n  $first: Int = 20\n  $onlyShowMine: Boolean\n) {\n  ...UserScenariosTable_ScenariosListFragment_2RU15A\n}\n\nfragment CheckScenarioPermissions_ScenarioFragment on Scenario {\n  isMasterPlan\n}\n\nfragment CloneScenarioButton_ScenarioFragment on Scenario {\n  id\n  name\n}\n\nfragment MakeMasterPlanButton_ScenarioFragment on Scenario {\n  id\n  isMasterPlan\n}\n\nfragment ToggleVisibilityButton_ScenarioFragment on Scenario {\n  id\n  isPublic\n  isMasterPlan\n}\n\nfragment UserScenariosTable_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n  lastUpdatedAt\n  gapDays {\n    gapDays\n    gapSalary\n  }\n  utilization {\n    unusedSalary\n    averageUtilizationPercentage\n  }\n  ...CheckScenarioPermissions_ScenarioFragment\n  ...MakeMasterPlanButton_ScenarioFragment\n  ...CloneScenarioButton_ScenarioFragment\n  ...ToggleVisibilityButton_ScenarioFragment\n}\n\nfragment UserScenariosTable_ScenariosListFragment_2RU15A on Query {\n  Scenario {\n    Scenarios(first: $first, after: $after, onlyShowMine: $onlyShowMine) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...UserScenariosTable_ScenarioFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb78fd13574f00b5b58dc5f5814f5d2f";

export default node;
