/**
 * @generated SignedSource<<0608356017070aca4fb55b78244264fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginResponseStatusKind = "AuthCredentialsCorrect" | "TwoFactorAuthCredentialsIncorrect" | "TwoFactorAuthRequired";
export type Login2FARecoveryCodeInput = {
  clientMutationId?: string | null;
  email: string;
  recoverCode: string;
};
export type twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation$variables = {
  input: Login2FARecoveryCodeInput;
};
export type twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation$data = {
  readonly Auth: {
    readonly login2FARecoveryCode: {
      readonly status: {
        readonly jwtTokens?: {
          readonly accessToken: string;
          readonly refreshToken: string;
        };
        readonly kind: LoginResponseStatusKind;
      };
    } | null;
  };
};
export type twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation = {
  response: twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation$data;
  variables: twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JwtLoginInformation",
      "kind": "LinkedField",
      "name": "jwtTokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refreshToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "authCredentialsCorrect",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Login2FARecoveryCodePayload",
            "kind": "LinkedField",
            "name": "login2FARecoveryCode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Login2FARecoveryCodePayload",
            "kind": "LinkedField",
            "name": "login2FARecoveryCode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63f822f5e65a9e2d94e28b032e084b74",
    "id": null,
    "metadata": {},
    "name": "twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation",
    "operationKind": "mutation",
    "text": "mutation twoFactorAuthRecovery_TwoFactorAuthRecoveryMutation(\n  $input: Login2FARecoveryCodeInput!\n) {\n  Auth {\n    login2FARecoveryCode(input: $input) {\n      status {\n        __typename\n        kind\n        ... on authCredentialsCorrect {\n          kind\n          jwtTokens {\n            refreshToken\n            accessToken\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b30fd316bdedfbbbdd461ff3eb8f42bd";

export default node;
