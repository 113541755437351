/**
 * @generated SignedSource<<1e86552c4ed319e8c31770997decd8ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillCategorySelect_SkillCategoryFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "SkillCategorySelect_SkillCategoryFragment";
};
export type SkillCategorySelect_SkillCategoryFragment$key = {
  readonly " $data"?: SkillCategorySelect_SkillCategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCategorySelect_SkillCategoryFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SkillCategorySelect_SkillCategoryFragment"
};

(node as any).hash = "3c18d638827ffd21f0b3c72c5c5992ef";

export default node;
