/**
 * @generated SignedSource<<43c25f7942f3233222c48e6636a011d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type loadPursuitProjectsFromRandDwhButton_ScenarioFragment$data = {
  readonly id: string;
  readonly isMasterPlan: boolean;
  readonly " $fragmentType": "loadPursuitProjectsFromRandDwhButton_ScenarioFragment";
};
export type loadPursuitProjectsFromRandDwhButton_ScenarioFragment$key = {
  readonly " $data"?: loadPursuitProjectsFromRandDwhButton_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"loadPursuitProjectsFromRandDwhButton_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "loadPursuitProjectsFromRandDwhButton_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMasterPlan",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "9a0feb9362d5a5c894742c793b3c45b1";

export default node;
