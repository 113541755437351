/**
 * @generated SignedSource<<3a8a89e44d7200e66991e0a760949133>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type availabilityForecastScreen_Query$variables = {
  id: string;
};
export type availabilityForecastScreen_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"availabilityForecastScreen_ScenarioFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"baseScreen_QueryFragment">;
};
export type availabilityForecastScreen_Query = {
  response: availabilityForecastScreen_Query$data;
  variables: availabilityForecastScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "availabilityForecastScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "availabilityForecastScreen_ScenarioFragment"
              }
            ],
            "type": "Scenario",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "baseScreen_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "availabilityForecastScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMasterPlan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenarioGapDays",
                "kind": "LinkedField",
                "name": "gapDays",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gapDays",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gapSalary",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScenarioUtilization",
                "kind": "LinkedField",
                "name": "utilization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "averageUtilizationPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unusedSalary",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Scenario",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "currentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AccountSettingsAccountExtension",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a4b2c9fa5ae3a69bb6d30e78f7a1023",
    "id": null,
    "metadata": {},
    "name": "availabilityForecastScreen_Query",
    "operationKind": "query",
    "text": "query availabilityForecastScreen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Scenario {\n      ...availabilityForecastScreen_ScenarioFragment\n    }\n    id\n  }\n  ...baseScreen_QueryFragment\n}\n\nfragment BaseHeader_LogoFragment on File {\n  url\n}\n\nfragment CurrentScenarioControl_ScenarioFragment on Scenario {\n  id\n  name\n  isMasterPlan\n}\n\nfragment DashboardHeader_ScenarioFragment on Scenario {\n  id\n  ...CurrentScenarioControl_ScenarioFragment\n  ...ScenarioStatistics_ScenarioFragment\n}\n\nfragment GapDaysDisplay_GapDaysFragment on ScenarioGapDays {\n  gapDays\n  gapSalary\n}\n\nfragment ScenarioStatistics_ScenarioFragment on Scenario {\n  gapDays {\n    ...GapDaysDisplay_GapDaysFragment\n  }\n  utilization {\n    ...UtilizationDisplay_UtilizationFragment\n  }\n}\n\nfragment UtilizationDisplay_UtilizationFragment on ScenarioUtilization {\n  averageUtilizationPercentage\n  unusedSalary\n}\n\nfragment availabilityForecastScreen_ScenarioFragment on Scenario {\n  id\n  ...DashboardHeader_ScenarioFragment\n}\n\nfragment baseScreen_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extensions {\n          __typename\n          kind\n          ... on AccountSettingsAccountExtension {\n            kind\n            logo {\n              ...BaseHeader_LogoFragment\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aadb693bdde315d2b9a3a86da35c5666";

export default node;
