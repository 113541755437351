/**
 * @generated SignedSource<<65cff6e33372c9af2fa3b7ed65079c2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntervalHeaderComponent_IntervalFragment$data = {
  readonly endDate: any | null;
  readonly fallsIntoCustomUtilizationWindow: boolean | null;
  readonly startDate: any | null;
  readonly " $fragmentType": "IntervalHeaderComponent_IntervalFragment";
};
export type IntervalHeaderComponent_IntervalFragment$key = {
  readonly " $data"?: IntervalHeaderComponent_IntervalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntervalHeaderComponent_IntervalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntervalHeaderComponent_IntervalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fallsIntoCustomUtilizationWindow",
      "storageKey": null
    }
  ],
  "type": "IntervalDescription",
  "abstractKey": null
};

(node as any).hash = "5bde3e497b075c98d7bc7178998af2d8";

export default node;
