/**
 * @generated SignedSource<<576861fca9ef0aa85f325e861f878fd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentsInProjectList_ProjectFragment$data = {
  readonly assignments: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly endDate: any;
        readonly id: string;
        readonly validAssignmentRoles: ReadonlyArray<{
          readonly sortOrder: number;
        }>;
        readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_AssignmentFragment">;
      };
    } | null> | null;
  };
  readonly id: string;
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateAssignmentButton_ProjectFragment" | "CreateAssignmentsFromTemplateButton_ProjectFragment">;
  };
  readonly " $fragmentType": "AssignmentsInProjectList_ProjectFragment";
};
export type AssignmentsInProjectList_ProjectFragment$key = {
  readonly " $data"?: AssignmentsInProjectList_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentsInProjectList_ProjectFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentsInProjectList_ProjectFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAssignmentButton_ProjectFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAssignmentsFromTemplateButton_ProjectFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentTypesConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentTypesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Assignment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endDate",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AssignmentCard_AssignmentFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentRole",
                  "kind": "LinkedField",
                  "name": "validAssignmentRoles",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sortOrder",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};
})();

(node as any).hash = "670643eacf7ac84b88a6c20383e760ca";

export default node;
