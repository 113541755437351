/**
 * @generated SignedSource<<45b41882509d1080e134d10aac8db3b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillDimensionTypeEnum = "binary" | "numerical";
import { FragmentRefs } from "relay-runtime";
export type editSkillAssociationModal_SkillFragment$data = {
  readonly dimension: {
    readonly kind: SkillDimensionTypeEnum;
  };
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"editBinarySkillAssociationModal_SkillFragment" | "editNumericalSkillAssociationModal_SkillFragment">;
  readonly " $fragmentType": "editSkillAssociationModal_SkillFragment";
};
export type editSkillAssociationModal_SkillFragment$key = {
  readonly " $data"?: editSkillAssociationModal_SkillFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editSkillAssociationModal_SkillFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editSkillAssociationModal_SkillFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dimension",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editBinarySkillAssociationModal_SkillFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editNumericalSkillAssociationModal_SkillFragment"
    }
  ],
  "type": "Skill",
  "abstractKey": null
};

(node as any).hash = "b7655df1f7d8c3721912f9c2cb81a000";

export default node;
