/**
 * @generated SignedSource<<e79c7253f9dde9dfd07e70aaf3a4b32e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenariosTable_ScenarioFragment$data = {
  readonly id: string;
  readonly isMasterPlan: boolean;
  readonly lastUpdatedAt: string | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CheckScenarioPermissions_ScenarioFragment" | "CloneScenarioButton_ScenarioFragment" | "EditScenarioButton_ScenarioFragment" | "MakeMasterPlanButton_ScenarioFragment" | "ToggleVisibilityButton_ScenarioFragment">;
  readonly " $fragmentType": "ScenariosTable_ScenarioFragment";
};
export type ScenariosTable_ScenarioFragment$key = {
  readonly " $data"?: ScenariosTable_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScenariosTable_ScenarioFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ScenariosTable_ScenarioFragment"
};

(node as any).hash = "39ebfb5394fe85abea13dc8d02cfc656";

export default node;
