/**
 * @generated SignedSource<<905c9b2a25f4aca7cab6bdba71d649c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssignmentTime = "Future" | "Past" | "Present";
import { FragmentRefs } from "relay-runtime";
export type personDetailsControl_AssignmentListFragment$data = {
  readonly Assignments: {
    readonly Assignments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly time: AssignmentTime;
          readonly " $fragmentSpreads": FragmentRefs<"AssignmentProjectCard_AssignmentFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly " $fragmentType": "personDetailsControl_AssignmentListFragment";
};
export type personDetailsControl_AssignmentListFragment$key = {
  readonly " $data"?: personDetailsControl_AssignmentListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsControl_AssignmentListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Assignments",
  "Assignments"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByPerson"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByScenario"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PersonDetailsControl_Refetch.graphql')
    }
  },
  "name": "personDetailsControl_AssignmentListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentsQueries",
      "kind": "LinkedField",
      "name": "Assignments",
      "plural": false,
      "selections": [
        {
          "alias": "Assignments",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByPerson",
              "variableName": "filterByPerson"
            },
            {
              "kind": "Variable",
              "name": "filterByScenario",
              "variableName": "filterByScenario"
            }
          ],
          "concreteType": "AssignmentTypesConnection",
          "kind": "LinkedField",
          "name": "__PersonDetailsControl_Assignments_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentTypesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Assignment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AssignmentProjectCard_AssignmentFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9fd6990f0ddfa148ce20795fbaa16973";

export default node;
