/**
 * @generated SignedSource<<298ae90da247eed33349d85c65931fe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAssignmentsFromTemplateButton_ProjectFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentForm_ProjectFragment">;
  readonly " $fragmentType": "CreateAssignmentsFromTemplateButton_ProjectFragment";
};
export type CreateAssignmentsFromTemplateButton_ProjectFragment$key = {
  readonly " $data"?: CreateAssignmentsFromTemplateButton_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateAssignmentsFromTemplateButton_ProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAssignmentsFromTemplateButton_ProjectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAssignmentForm_ProjectFragment"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "53cea49afa3f14277f0d5ce2db0124c3";

export default node;
