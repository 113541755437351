/**
 * @generated SignedSource<<4a9bcb3f023dfacc37892f27fc74d008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type syncProjectFromDynamicsButton_SyncProjectFragment$data = {
  readonly address: {
    readonly city: string;
    readonly country: string;
    readonly latitude: number | null;
    readonly lineOne: string;
    readonly longitude: number | null;
    readonly postalCode: string;
    readonly state: string;
  } | null;
  readonly architectName: string | null;
  readonly avatar: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly budgetedLaborCosts: number | null;
  readonly clientName: string | null;
  readonly comments: string | null;
  readonly division: {
    readonly id: string;
  } | null;
  readonly endDate: any | null;
  readonly generalConditionsPercentage: number | null;
  readonly id: string;
  readonly milestones: ReadonlyArray<{
    readonly date: any;
    readonly name: string;
  }>;
  readonly name: string;
  readonly projectIdentifier: string | null;
  readonly region: {
    readonly id: string;
  } | null;
  readonly skills: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly stage: {
    readonly id: string;
  } | null;
  readonly startDate: any | null;
  readonly volume: number | null;
  readonly " $fragmentType": "syncProjectFromDynamicsButton_SyncProjectFragment";
};
export type syncProjectFromDynamicsButton_SyncProjectFragment$key = {
  readonly " $data"?: syncProjectFromDynamicsButton_SyncProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"syncProjectFromDynamicsButton_SyncProjectFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "syncProjectFromDynamicsButton_SyncProjectFragment"
};

(node as any).hash = "80a1c5f9194c6ac4d59235bb62432552";

export default node;
