/**
 * @generated SignedSource<<22a2e41743c7d69814e2ec6e2d343bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserScenariosTable_ScenarioFragment$data = {
  readonly gapDays: {
    readonly gapDays: number;
    readonly gapSalary: number;
  };
  readonly id: string;
  readonly isMasterPlan: boolean;
  readonly lastUpdatedAt: string | null;
  readonly name: string;
  readonly utilization: {
    readonly averageUtilizationPercentage: number;
    readonly unusedSalary: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CheckScenarioPermissions_ScenarioFragment" | "CloneScenarioButton_ScenarioFragment" | "MakeMasterPlanButton_ScenarioFragment" | "ToggleVisibilityButton_ScenarioFragment">;
  readonly " $fragmentType": "UserScenariosTable_ScenarioFragment";
};
export type UserScenariosTable_ScenarioFragment$key = {
  readonly " $data"?: UserScenariosTable_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserScenariosTable_ScenarioFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "UserScenariosTable_ScenarioFragment"
};

(node as any).hash = "7d4aff4e04e734853ca1ec192ac31c95";

export default node;
